import { useEffect, useState } from 'react';
import { selectCartReducer } from '../selectors';
import { useAppSelector } from '../store';
import { DHLPaymentRequestType, PaymentRequestType } from '../types/sprintpay';

export interface UseCreditNotesTotalAmountType {
    creditNotesTotalAmount: number;
}

export const useCreditNotesTotalAmount = (): UseCreditNotesTotalAmountType => {
    const [creditNotesTotalAmount, setcreditNotesTotal] = useState(0);
    const cart = useAppSelector(selectCartReducer);

    useEffect(() => {
        const sumTotalInvoices = cart.dhlPaymentRequests.reduce((sum: number, awb: DHLPaymentRequestType) => {
            if (awb.is_credit_note) {
                awb.fee_content.forEach((element: PaymentRequestType) => {
                    sum = sum + Number(element.amount);
                });
            }

            return sum;
        }, 0);

        setcreditNotesTotal(sumTotalInvoices);
    }, [cart.dhlPaymentRequests]);

    return { creditNotesTotalAmount };
};
