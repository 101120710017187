import { useEffect, useState } from 'react';
import { mapCreditNoteToPayments } from '../helpers';
import { selectCartReducer, selectCustomer } from '../selectors';
import { useAppDispatch, useAppSelector } from '../store';
import { addPaymentNote, customerActions, searchCreditNote } from '../store/actions';
import { ShipmentDetailsType } from '../store/customer/types';
import { useFindDuplicates } from './is-awb-duplicated';

interface useAddCreditNoteType {
    resetCreditNoteError: () => void;
    addCreditNote: (notes: string) => void;
    setIsCreditNoteDuplicated: (isDuplicated: boolean) => void;
    creditNoteError?: string | null;
    isCreditNoteDuplicated: boolean;
    isCreditNoteUsed: boolean;
    isCreditNoteInvalid: boolean;
}

export const useAddCreditNote = (): useAddCreditNoteType => {
    const { findCreditNoteInCart } = useFindDuplicates();
    const dispatch = useAppDispatch();
    const [creditNoteError, setCreditNoteError] = useState<string | null>();
    const [isCreditNoteDuplicated, setIsCreditNoteDuplicated] = useState(false);
    const [isCreditNoteUsed, setCreditNoteUsed] = useState(false);
    const [isCreditNoteInvalid, setCreditNoteInvalid] = useState(false);
    const customer = useAppSelector(selectCustomer);
    const cart = useAppSelector(selectCartReducer);
    const [addingCreditNote, setAddingCreditNote] = useState(false);

    const addCreditNote = async (notes: string) => {
        try {
            setAddingCreditNote(true);
            setCreditNoteUsed(false);
            setIsCreditNoteDuplicated(false);
            setCreditNoteInvalid(false);
            const isCreditNoteDuplicated = await findCreditNoteInCart(notes);

            if (isCreditNoteDuplicated) {
                setIsCreditNoteDuplicated(true);
                setAddingCreditNote(false);
            } else {
                customer.customerNumber &&
                    dispatch(searchCreditNote({ customerNumber: customer.customerNumber, creditNote: notes }));
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (customer.creditNotes) {
            const addNote = async () => {
                const inv: ShipmentDetailsType | null = customer.creditNotes;

                setAddingCreditNote(false);

                if (inv && inv.is_valid_credit_note) {
                    resetCreditNoteError();

                    const creditAmount = (inv.credit_note_amount && parseFloat(inv.credit_note_amount)) || 0;

                    if (cart.preTotal < creditAmount || !inv.is_valid_credit_note) {
                        setCreditNoteInvalid(true);
                    } else if (creditAmount === 0) {
                        // setCreditNoteValue(inv?.IATA_code);
                        setCreditNoteUsed(true);
                    } else {
                        const creditNote = mapCreditNoteToPayments(inv);

                        creditNote && (await dispatch(addPaymentNote(creditNote)));
                    }
                } else {
                    setCreditNoteError(`Oops.. The credit note you entered is not valid. Please try again.`);
                }

                dispatch(customerActions.resetPaymentNote());
            };

            addNote();
        }

        if (customer.creditNotesErrors && addingCreditNote) {
            setCreditNoteError(`Oops.. The credit note you entered is not valid. Please try again.`);
            setAddingCreditNote(false);
        }
    }, [customer.creditNotes, customer.creditNotesErrors]);

    const resetCreditNoteError = () => setCreditNoteError(null);

    return {
        resetCreditNoteError,
        addCreditNote,
        setIsCreditNoteDuplicated,
        creditNoteError,
        isCreditNoteDuplicated,
        isCreditNoteUsed,
        isCreditNoteInvalid,
    };
};
