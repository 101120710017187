import { IconCancelCircle, IconCheckmarkCircle, IconException } from '@dhl-official/react-ui-library';
import { IconModalProps, IconTypes } from './types';

export const IconModal = ({ className, iconType = IconTypes.IconCancelCircle }: IconModalProps) => {
    const getIcon = (iconType: IconTypes) => {
        switch (iconType) {
            case IconTypes.IconCancelCircle:
                return (
                    <IconCancelCircle name='error' title='error' className={`global-modal__icon-error ${className}`} />
                );
            case IconTypes.IconCheckmarkCircle:
                return (
                    <IconCheckmarkCircle
                        name='success'
                        title='success'
                        className={`global-modal__icon-success ${className}`}
                    />
                );
            case IconTypes.IconException:
                return (
                    <IconException
                        name='exception'
                        title='exception'
                        className={`global-modal__icon-exception ${className}`}
                    />
                );
        }
    };

    return <>{getIcon(iconType)}</>;
};
