import { FieldProps } from 'formik';
import React from 'react';
import { AutoCompletePropsDHL } from '../../../types';

export interface AutoCompleteProps extends FieldProps, AutoCompletePropsDHL {}

export type AddressComponentType = {
    long_name: string;
    short_name: string;
    types: string[];
};

export enum AddressTypes {
    street_number = 'street_number',
    route = 'route',
    neighborhood = 'neighborhood',
    country = 'country',
    state = 'administrative_area_level_1',
    city = 'administrative_area_level_2',
    locality = 'locality',
    zipCode = 'postal_code',
}

export interface PredictionDHL {
    description: string;
    value: string;
    id: string;
    label: string;
    matched_substrings?: MatchedSubstring[];
    place_id?: string;
    reference?: string;
    structured_formatting?: StructuredFormatting;
    terms?: Term[];
    types?: string[];
}

export interface PredictionGoogle {
    description: string;
    matched_substrings?: MatchedSubstring[];
    place_id?: string;
    reference?: string;
    structured_formatting?: StructuredFormatting;
    terms?: Term[];
    types?: string[];
}

export interface MatchedSubstring {
    length: number;
    offset: number;
}

export interface StructuredFormatting {
    main_text: string;
    main_text_matched_substrings: MatchedSubstring[];
    secondary_text: string;
    secondary_text_matched_substrings?: MatchedSubstring[];
}

export interface Term {
    offset: number;
    value: string;
}

export type AddressFormatTypes = keyof typeof AddressTypes;

export interface AddressComplete {
    street: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
}
