import { ButtonGroup, Modal } from '@dhl-official/react-ui-library';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useAuth from '../../context/authContext/auth-context';
import { selectGlobalAlertReducer } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { globalAlertActions } from '../../store/actions';
import { COLORS } from '../../styles/abstracts/colors';
import { BUTTONGROUP_ORIENTATION, BUTTON_SIZES, BUTTON_VARIANTS, TEXT_SIZES } from '../../types';
import { ButtonDHL } from '../common/buttonDHL/button';
import DescriptionModal from '../common/modal/description';
import { IconModal } from '../common/modal/icon';
import TitleModal from '../common/modal/title';
import { IconTypes } from '../common/modal/types';
import Countdown from '../common/timer/timer';

export const AlertApp = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const alertReducer = useAppSelector(selectGlobalAlertReducer);
    const auth = useAuth();
    const [autoLogout, setAutoLogout] = useState(false);

    const _onCloseModal = () => {
        dispatch(globalAlertActions.hideGlobalModal());
        setAutoLogout(false);
    };

    const _onLogout = () => {
        auth.logout();

        navigate('./');
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let timer: any;

        if (alertReducer.active) {
            timer = setTimeout(() => {
                autoLogout && auth.logout();
                navigate('./');
            }, 1000 * 60 * 1);
        }

        if (alertReducer.active && !autoLogout) {
            setAutoLogout(true);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [alertReducer, autoLogout, auth, navigate]);

    return (
        <Modal
            isOpen={alertReducer.active}
            closeButtonAriaLabel='Closes the Modal'
            contentAriaLabel='Logout Modal'
            onClose={_onCloseModal}
            shouldShowCloseButton={false}
            isStatic={true}
            className='global-modal__container'
            overlayColor={COLORS['black-20']}
        >
            <div className='global-modal'>
                <IconModal iconType={IconTypes.IconException} />
                <div className='global-modal__content'>
                    <TitleModal>Notification</TitleModal>
                    <DescriptionModal size={TEXT_SIZES['MD']}>
                        You have been inactive for a long time. Would you like to continue?
                    </DescriptionModal>
                    <Countdown />
                    <ButtonGroup
                        size={BUTTON_SIZES.MD}
                        orientation={BUTTONGROUP_ORIENTATION.HORIZONTAL}
                        ariaLabel='Choose an option'
                        dataTestid='sb'
                        isBlock
                        className='mt-4 global-modal__button-group justify-content-end'
                    >
                        <ButtonDHL
                            loading={alertReducer.loading}
                            variant={BUTTON_VARIANTS.PRIMARY}
                            onClick={_onLogout}
                            showLoadingIcon
                        >
                            Logout
                        </ButtonDHL>
                        <ButtonDHL
                            loading={alertReducer.loading}
                            variant={BUTTON_VARIANTS.OUTLINE}
                            onClick={_onCloseModal}
                            showLoadingIcon
                        >
                            Continue
                        </ButtonDHL>
                    </ButtonGroup>
                </div>
            </div>
        </Modal>
    );
};
