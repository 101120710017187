import { BUTTON_TYPES, Center, GridCell, Text } from '@dhl-official/react-ui-library';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ButtonDHL, RadioDHL } from '../../../components';
import InputDHL from '../../../components/common/input';
import InputDHLMask from '../../../components/common/inputMask/input';
import { maskAwb } from '../../../helpers';
import { INPUTFIELD_LABEL_TYPES, INPUTFIELD_TYPES, RADIOFIELD_SIZES, TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { SearchType } from '../search-screen-types';
import { FormSearchProps, FormSearchValues } from './form-types';
import { validateSearch } from './validate';

export const SearchForm = ({ onSubmit, initialValues, loading }: FormSearchProps) => {
    return (
        <Center className='search__wrapper'>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => validateSearch(values)}
                onSubmit={(values) => onSubmit(values)}
                validateOnBlur={false}
                validateOnMount={false}
            >
                {({ setFieldValue, values, handleSubmit, setFieldTouched }: FormikProps<FormSearchValues>) => (
                    <Form
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                        className='search__form'
                    >
                        <GridCell spanColumns={1} className='search__container-radios'>
                            <Field
                                id='invoiceNumber'
                                name='searchType'
                                // eslint-disable-next-line react/no-children-prop
                                children='Invoice number'
                                value={SearchType.invoice_number}
                                isChecked={values.searchType === SearchType.invoice_number}
                                onChange={() => {
                                    setFieldValue('number', '');
                                    setFieldTouched('number', false);
                                    setFieldValue('searchType', SearchType.invoice_number);
                                }}
                                className='search__radio me-3'
                                size={RADIOFIELD_SIZES.MD}
                                component={RadioDHL}
                            />
                            <Text size={TEXT_SIZES['MD']} weight={TEXT_WEIGHTS[400]} className='search__radio-or'>
                                or
                            </Text>
                            <Field
                                id='mawb'
                                name='searchType'
                                // eslint-disable-next-line react/no-children-prop
                                children='MAWB'
                                value={SearchType.mawb}
                                isChecked={values.searchType === SearchType.mawb}
                                onChange={() => {
                                    setFieldValue('number', '');
                                    setFieldTouched('number', false);
                                    setFieldValue('searchType', SearchType.mawb);
                                }}
                                className='search__radio'
                                size={RADIOFIELD_SIZES.MD}
                                component={RadioDHL}
                            />
                        </GridCell>
                        {values.searchType === SearchType.mawb ? (
                            <Field
                                ariaLabel='number'
                                id='number'
                                name='number'
                                isBlock
                                variant={{
                                    label: 'MAWB',
                                    placeholder: 'Enter your MAWB',
                                    type: INPUTFIELD_TYPES.STATIC,
                                }}
                                component={InputDHLMask}
                                type={INPUTFIELD_LABEL_TYPES.TEXT}
                                autoFocus
                                mask={maskAwb}
                            />
                        ) : (
                            <Field
                                ariaLabel='number'
                                id='number'
                                name='number'
                                isBlock
                                variant={{
                                    label: 'Invoice number',
                                    placeholder: 'Enter your invoice number',
                                    type: INPUTFIELD_TYPES.STATIC,
                                }}
                                component={InputDHL}
                                type={INPUTFIELD_LABEL_TYPES.TEXT}
                                autoFocus
                                mask={''}
                            />
                        )}
                        <ButtonDHL
                            id='search'
                            name='search'
                            isBlock
                            type={BUTTON_TYPES.SUBMIT}
                            loading={loading}
                            isDisabled={loading}
                            showLoadingIcon
                            className='search__search-button'
                        >
                            Search
                        </ButtonDHL>
                    </Form>
                )}
            </Formik>
        </Center>
    );
};
