import { useCallback, useState } from 'react';

export enum KeysLocalStorageTypes {
    TOKEN = 'token',
    REF_TOKEN = 'refToken',
    USER_INFO = 'userInfo',
    USER_TYPE = 'userType',
    EXPIRES_AT = 'expiresAt',
    SERVER_TIME = 'serverTime',
}

export const useLocalStorage = <T,>(key: KeysLocalStorageTypes, initialValue?: T) => {
    const [storedValue, setStoredValue] = useState<T | null>(() => {
        try {
            const item = window.sessionStorage.getItem(key);

            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // console.log(error);

            return initialValue;
        }
    });

    const setValue = useCallback(
        (value: T) => {
            try {
                setStoredValue(value);

                if (value) {
                    window.sessionStorage.setItem(key, JSON.stringify(value));
                }
            } catch (error) {
                // console.log(error);
            }
        },
        [key]
    );

    const deleteValue = useCallback(() => {
        try {
            window.sessionStorage.removeItem(key);

            setStoredValue(null);
        } catch (error) {
            // console.log(error);
        }
    }, [key]);

    return [setValue, storedValue, deleteValue] as const;
};
