import { CustomersType, Token } from '@cargos/sprintpay-models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { ThunkConfig } from '..';
import * as Requests from '../../api/request/auth-request';
import { UserProfile } from '../../types/sprintpay';
import { cartActions } from '../actions';
import { handlerErrors } from '../errors/actions';
import { ErrorsType } from '../errors/types';
import { paymentMethodsActions } from '../paymentsMethods/reducers';
import { AuthTokensType, SessionType, ValidateEmailThunkProps, ValidatePasscodeThunkProps } from './types';

const getDecodedAccessToken = (token: string): Token | null => {
    try {
        return jwt_decode(token);
    } catch (error) {
        return null;
    }
};

const currentTime = async () => {
    try {
        const currentTime = await Requests.currentTime();
        return currentTime;
    } catch (error) {
        return {
            currentSeconds: null,
        };
    }
};

const prepareSessionData = async (token: string) => {
    try {
        const [currentT, data] = await Promise.all([currentTime(), getDecodedAccessToken(token)]);
        if (data) {
            const { SPRINT_PAY_CUSTOMER, exp, AUTHORIZATION_TOKEN_ID } = data;

            const customerInfo = JSON.parse(SPRINT_PAY_CUSTOMER);

            return {
                currentT,
                exp,
                customerInfo,
                AUTHORIZATION_TOKEN_ID,
            };
        }
    } catch (error) {
        // throw Error(error);
    }
};

export const login = createAsyncThunk<
    {
        authTokens: AuthTokensType;
        expiresAt: number;
        userInfo: UserProfile;
        userType: CustomersType | string;
        currentT: number;
        authorizationTokenId?: number;
    },
    { email: string; password: string; authorizationTokenId: number },
    ThunkConfig<ErrorsType>
>('auth/login', async (props, { dispatch, rejectWithValue }) => {
    const { email, password, authorizationTokenId } = props;

    try {
        const authTokens: AuthTokensType = await Requests.login(email, password, authorizationTokenId);

        if (authTokens.authorizationToken) {
            const { currentT, exp, customerInfo } = await prepareSessionData(authTokens.authorizationToken);
            dispatch(cartActions.resetCart());
            dispatch(paymentMethodsActions.resetPaymentMethods());

            return {
                authTokens,
                expiresAt: exp,
                userInfo: {
                    ...customerInfo.customer,
                    maxAmountAllowed: 10000,
                },
                userType: customerInfo.customerType,
                currentT: currentT.currentSeconds,
            };
        }
    } catch (error: unknown) {
        if (
            axios.isAxiosError(error) &&
            error.response.data.errors.length > 0 &&
            (error.response.status === 500 || error.response.status === 401)
        ) {
            return rejectWithValue({
                code: 500,
                errors: ['Your username and/or password is incorrect. Please try again.'],
            } as ErrorsType);
        }

        const errors = handlerErrors(error, dispatch);
        return rejectWithValue(errors);
    }
});

export const loginAsGuest = createAsyncThunk<SessionType, void, ThunkConfig<ErrorsType>>(
    'auth/loginAsGuest',
    async (props, { dispatch, rejectWithValue }) => {
        try {
            const authTokens: AuthTokensType = await Requests.loginAsGuest();

            if (authTokens.authorizationToken) {
                const { currentT, exp, customerInfo, AUTHORIZATION_TOKEN_ID } = await prepareSessionData(
                    authTokens.authorizationToken
                );

                dispatch(paymentMethodsActions.resetPaymentMethods());

                return {
                    authTokens,
                    expiresAt: exp,
                    userInfo: {
                        ...customerInfo.customer,
                        maxAmountAllowed: 10000,
                    },
                    userType: customerInfo.customerType,
                    currentT: currentT.currentSeconds,
                    authorizationTokenId: AUTHORIZATION_TOKEN_ID,
                };
            }
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            return rejectWithValue(errors);
        }
    }
);

export const refreshToken = createAsyncThunk<SessionType, string, ThunkConfig<ErrorsType>>(
    'auth/refreshToken',
    async (token, { dispatch, rejectWithValue }) => {
        try {
            const authTokens = await Requests.refreshToken(token);

            if (authTokens.authorizationToken) {
                const { currentT, exp, customerInfo } = await prepareSessionData(authTokens.authorizationToken);

                return {
                    authTokens,
                    expiresAt: exp,
                    userInfo: {
                        ...customerInfo.customer,
                        maxAmountAllowed: 10000,
                    },
                    userType: customerInfo.customerType,
                    currentT: currentT.currentSeconds,
                };
            }
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            return rejectWithValue(errors);
        }
    }
);

export const validateEmail = createAsyncThunk<string, ValidateEmailThunkProps, ThunkConfig<ErrorsType>>(
    'auth/validateEmail',
    async (props, { dispatch, rejectWithValue }) => {
        try {
            const { email } = props;
            const response = await Requests.validateEmail(email);

            dispatch(cartActions.resetCart());
            dispatch(paymentMethodsActions.resetPaymentMethods());

            return response.mfaUuid;
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            return rejectWithValue(errors);
        }
    }
);

export const validateCustomerEmail = createAsyncThunk<
    {
        content: string;
        success: boolean;
        mfaUuid?: string;
    },
    ValidateEmailThunkProps,
    ThunkConfig<ErrorsType>
>('auth/validateCustomerEmail', async (props, { dispatch, rejectWithValue }) => {
    try {
        const { email } = props;
        let response: {
            content: string;
            success: boolean;
            mfaUuid?: string;
        };

        response = await Requests.validateCustomerEmail(email);

        if (response.success) {
            dispatch(cartActions.resetCart());
            dispatch(paymentMethodsActions.resetPaymentMethods());

            const validateEmailResponse = await Requests.validateEmail(email);
            response = { ...response, ...validateEmailResponse };
        }

        return response;
    } catch (error: unknown) {
        const errors = handlerErrors(error, dispatch);
        return rejectWithValue(errors);
    }
});

export const validatePasscode = createAsyncThunk<boolean, ValidatePasscodeThunkProps, ThunkConfig<ErrorsType>>(
    'auth/validatePasscode',
    async (props, { dispatch, rejectWithValue }) => {
        try {
            const { mfaCode, mfaUuId } = props;
            const response = await Requests.validatePasscode(mfaUuId, mfaCode);

            return response.isVerified;
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            return rejectWithValue(errors);
        }
    }
);
