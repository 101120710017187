import { RadioField, Text } from '@dhl-official/react-ui-library';
import React, { useEffect, useState } from 'react';
import { TitleFormSection } from '../../..';
import { formatNumberToDHL } from '../../../../helpers/custom-format-number';
import { RADIOFIELD_SIZES } from '../../../../types';
import { PaymentMethodsType } from '../../../../types/sprintpay';
import { SignetCreditProps } from './credit-types';

export const SignetCredit = ({ onSelectCredit, signetCredit, paymentMethodSelected }: SignetCreditProps) => {
    const [selectCredit, setCreditSelected] = useState(false);

    const buttonHandler = () => onSelectCredit(signetCredit);

    useEffect(() => {
        if (paymentMethodSelected?.type === PaymentMethodsType.SIGNET) {
            setCreditSelected(true);
        }
    }, [paymentMethodSelected]);

    return (
        <>
            <TitleFormSection isParagraph={false} className='credit-card-tab__title'>
                Your credit available
            </TitleFormSection>
            {!!signetCredit?.avaliableCredit ? (
                <RadioField
                    name='signet'
                    id='signet'
                    value={PaymentMethodsType.SIGNET}
                    isChecked={selectCredit}
                    isBlock
                    className='credit-card-tab__radio-credit-card'
                    size={RADIOFIELD_SIZES.MD}
                    onChange={buttonHandler}
                >
                    {`$ ${signetCredit ? formatNumberToDHL(signetCredit.avaliableCredit) : '0.00'} USD`}
                </RadioField>
            ) : (
                <Text>Add funds to your signet account to use it.</Text>
            )}
        </>
    );
};
