import { ButtonGroup, Modal } from '@dhl-official/react-ui-library';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ButtonDHL, ErrorApp, TextAreaDHL } from '../../components';
import { COLORS } from '../../styles/abstracts/colors';
import {
    ALIGNMENTS,
    BUTTONGROUP_ORIENTATION,
    BUTTON_SIZES,
    BUTTON_TYPES,
    BUTTON_VARIANTS,
    INPUTFIELD_TYPES,
    TEXT_SIZES,
} from '../../types';
import DescriptionModal from './description';
import { IconModal } from './icon';
import TitleModal from './title';
import { FormRemoveValues, IconTypes, RemoveModalProps } from './types';
import { validateRemoveForm } from './validate';

export const RemoveModal = ({
    loading = false,
    isOpen,
    onCloseModal,
    lefttButtonVariant = BUTTON_VARIANTS.PRIMARY,
    leftButtonDisabled = false,
    leftButtonLoading = false,
    onPressLeftButton,
    buttonGroupOrder = ALIGNMENTS.CENTER,
    iconType = IconTypes.IconCancelCircle,
    showIcon = true,
    shouldShowCloseButton = true,
    isStatic = true,
    error,
    initialValues,
}: RemoveModalProps) => {
    const _closeModal = () => {
        !loading && onCloseModal && onCloseModal();
    };

    const onSubmit = (values: FormRemoveValues) => {
        onPressLeftButton && onPressLeftButton(values);

        _closeModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            closeButtonAriaLabel='Closes the Modal'
            contentAriaLabel='Remove item'
            onClose={_closeModal}
            shouldShowCloseButton={shouldShowCloseButton}
            isStatic={isStatic}
            className='global-modal__container'
            overlayColor={COLORS['black-20']}
        >
            <div className='global-modal'>
                {showIcon && <IconModal iconType={iconType} />}
                <div className='global-modal__content'>
                    <TitleModal>Oh no! Why are you removing item(s) from your cart?</TitleModal>
                    <DescriptionModal size={TEXT_SIZES['LG']}>
                        if you want to remove item(s), please write the reason(s) in the comment section below, or
                        cancel this process and select the item(s) again.
                    </DescriptionModal>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validate={(values) => validateRemoveForm(values)}
                        onSubmit={(values) => onSubmit(values)}
                        validateOnBlur={false}
                    >
                        {({ handleSubmit }: FormikProps<FormRemoveValues>) => (
                            <Form
                                className='login__form'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Field
                                    id='comments'
                                    name='comments'
                                    isBlock
                                    variant={{
                                        placeholder: 'Comments',
                                        type: INPUTFIELD_TYPES.STATIC,
                                    }}
                                    component={TextAreaDHL}
                                />
                                {error && <ErrorApp>{error}</ErrorApp>}
                                <ButtonGroup
                                    size={BUTTON_SIZES.MD}
                                    orientation={BUTTONGROUP_ORIENTATION.HORIZONTAL}
                                    ariaLabel='Choose an option'
                                    isBlock
                                    className={`mt-4 global-modal__button-group
                            ${buttonGroupOrder === ALIGNMENTS.LEFT && 'justify-content-end'}
                            ${buttonGroupOrder === ALIGNMENTS.RIGHT && 'justify-content-start'}
                            ${buttonGroupOrder === ALIGNMENTS.CENTER && 'justify-content-center'}`}
                                >
                                    <ButtonDHL
                                        onClick={_closeModal}
                                        type={BUTTON_TYPES.BUTTON}
                                        variant={BUTTON_VARIANTS.OUTLINE}
                                    >
                                        Cancel
                                    </ButtonDHL>
                                    <ButtonDHL
                                        loading={leftButtonLoading && loading}
                                        isDisabled={leftButtonDisabled}
                                        type={BUTTON_TYPES.BUTTON}
                                        variant={lefttButtonVariant}
                                        showLoadingIcon
                                        onClick={handleSubmit}
                                    >
                                        Send and continue
                                    </ButtonDHL>
                                </ButtonGroup>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};
