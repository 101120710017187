import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../types';

type Props = {
    size: string;
    weight?: number;
    className?: string;
    children: string;
};

const DescriptionModal = ({
    size = TEXT_SIZES['3XL'],
    weight = TEXT_WEIGHTS[200],
    className = '',
    children,
    ...restProps
}: Props) => {
    return (
        <Text isParagraph size={size} weight={weight} className={`mb-4 ${className}`} {...restProps}>
            {children}
        </Text>
    );
};

export default DescriptionModal;
