import { THEME } from '@dhl-official/react-ui-library';

export const {
    getWebColor,
    getPrimaryColor,
    getSecondaryColor,
    getGrayColor,
    getWarningColor,
    getSuccessColor,
    getErrorColor,
    getFontColor,
} = THEME;

export const COLORS = {
    'black-20': 'rgba(0, 0, 0, 0.2)',
};
