import { Image, Navbar } from '@dhl-official/react-ui-library';
import dhl_aviation from './../../assets/images/dhl_aviation.png';
import dhl_aviation_2x from './../../assets/images/dhl_aviation@2x.png';
import dhl_aviation_3x from './../../assets/images/dhl_aviation@3x.png';

const NavBarDHL = () => {
    return (
        <div className='navbar__main'>
            <Navbar
                logo={{
                    alt: 'DHL Logo',
                    external: false,
                    href: '',
                    title: 'DHL - Excellence. Simply. Delivered',
                }}
            />
            <Image
                src={dhl_aviation}
                alt='DHL_aviation'
                className='navbar__logo'
                sizes={[
                    '(max-width: 320px) 320px',
                    '(max-width:480px) 480px',
                    '(max-width:768px) 768px',
                    '(max-width:1024px) 1024px',
                    '1440px',
                ]}
                srcSet={[
                    `${dhl_aviation} 320w`,
                    `${dhl_aviation_2x} 480w`,
                    `${dhl_aviation_2x} 768w`,
                    `${dhl_aviation_3x} 1024w`,
                    `${dhl_aviation_3x} 1440w`,
                ]}
            />
        </div>
    );
};

export default NavBarDHL;
