import { Button, GridCell, RadioField } from '@dhl-official/react-ui-library';
import { useEffect, useState } from 'react';
import { TitleFormSection } from '../../..';
import { BUTTON_TYPES, BUTTON_VARIANTS, RADIOFIELD_SIZES } from '../../../../types';
import { CreditCardType, PaymentMethodsType } from '../../../../types/sprintpay';
import { Description } from '../../description';
import { CreditCardsListProps } from './list-types';

export const CreditCardList = ({
    onPressAdd,
    onPressSelect,
    creditCards = [],
    paymentMethodSelected,
}: CreditCardsListProps) => {
    const [idCard, setIdCard] = useState<null | string>(null);

    const submitCardSelected = (cardSelected: CreditCardType) => onPressSelect && onPressSelect(cardSelected || null);

    useEffect(() => {
        if (paymentMethodSelected?.type === PaymentMethodsType.CREDIT_CARD) {
            setIdCard(paymentMethodSelected.id);
        }
    }, [paymentMethodSelected]);

    return (
        <>
            <GridCell
                spanColumns={12}
                className='credit-card-tab__container-top justify-content-end'
                isOverflowHidden={false}
            >
                {!!creditCards.length && (
                    <TitleFormSection isParagraph={false} className='credit-card-tab__title'>
                        Select credit card
                    </TitleFormSection>
                )}
                <Button
                    onClick={onPressAdd}
                    id='add'
                    name='add'
                    variant={BUTTON_VARIANTS.OUTLINE}
                    type={BUTTON_TYPES.BUTTON}
                >
                    Add credit card
                </Button>
            </GridCell>
            <GridCell spanColumns={12} className='credit-card-tab__container-cards' isOverflowHidden={false}>
                {!!creditCards.length ? (
                    creditCards.map((item, index) => {
                        return (
                            <GridCell
                                key={index}
                                spanColumns={1}
                                isOverflowHidden={false}
                                className='credit-card-tab__credit-card-row'
                            >
                                <RadioField
                                    name='idCard'
                                    id={item.paymentToken.token}
                                    value={item.paymentToken.token}
                                    item={item}
                                    isChecked={idCard === item.paymentToken.token}
                                    isBlock
                                    onChange={() => submitCardSelected(item)}
                                    className='credit-card-tab__radio-credit-card'
                                    size={RADIOFIELD_SIZES.MD}
                                >
                                    {`****   ****   ****   ${item.accountingDetails.lastFourDigits}`}
                                </RadioField>
                            </GridCell>
                        );
                    })
                ) : (
                    <Description>No credit cards saved, please add a new one to continue</Description>
                )}
            </GridCell>
        </>
    );
};
