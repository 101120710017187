import { ALIGNMENTS, BUTTON_VARIANTS } from '../../../types';
import React from 'react';

export interface SubscribeModalProps {
    isOpen: boolean;
    initialValues: FormSubscribeValues;
    loading?: boolean;
    onCloseModal?: () => void;
    lefttButtonVariant?: BUTTON_VARIANTS;
    leftButtonDisabled?: boolean;
    leftButtonLoading?: boolean;
    buttonGroupOrder?: ALIGNMENTS;
    iconType?: IconTypes;
    showIcon?: boolean;
    shouldShowCloseButton?: boolean;
    isStatic?: boolean;
    error?: string;
}

export type FormSubscribeValues = {
    number: string;
    email: string;
};

export interface IconModalProps {
    className?: string;
    iconType?: IconTypes;
}

export enum IconTypes {
    IconCancelCircle = 'IconCancelCircle',
    IconCheckmarkCircle = 'IconCheckmarkCircle',
    IconException = 'IconException',
}
