import { GridContainer } from '@dhl-official/react-ui-library';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GlobalModal, InvalidCustomerModal } from '../../components';
import { cleanNumber } from '../../helpers/clean-number';
import { selectCustomer, selectCustomerNumber } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { customerActions } from '../../store/actions';
import { searchAWBNumber, searchInvoiceNumber } from '../../store/customer/actions';
import { BUTTON_VARIANTS, TEXT_SIZES } from '../../types';
import Footer from './footer';
import { SearchForm } from './form/form';
import { FormSearchValues } from './form/form-types';
import Header from './header';
import { SearchType } from './search-screen-types';
import { SubscribeModal } from './subscribeModal';

export const SearchScreen = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state }: any = useLocation();
    const [prevLocationLocal, setPrevLocationLocal] = useState(state?.prevLocation);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const customer = useAppSelector(selectCustomer);
    const customerNumber = useAppSelector(selectCustomerNumber);
    const { customerNumber: customerNumberParam } = useParams<'customerNumber'>();
    const [numberToSearch, setNumberToSearch] = useState('');
    const [searchType, setSearchType] = useState<SearchType>(SearchType.mawb);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showCreditNoteModal, setShowCreditNoteModal] = useState(false);
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [showSubscriptionStatusModal, setShowSubscriptionStatusModal] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [searching, setSearching] = useState(false);

    const searchInvoice = useCallback(
        async (invNumber: string, type: string) => {
            const customerNumberLength = customerNumber.length;
            const numberField = invNumber.substring(0, customerNumberLength);

            if (numberField.toLowerCase() !== customerNumber.toLowerCase() && type === SearchType.invoice_number) {
                dispatch(customerActions.resetSearchErrors());

                setShowErrorModal(true);
            } else if (type === SearchType.invoice_number) {
                setPrevLocationLocal(false);

                dispatch(searchInvoiceNumber({ customerNumber, invoiceNumber: invNumber }));
            } else if (type === SearchType.mawb) {
                setPrevLocationLocal(false);

                dispatch(searchAWBNumber({ customerNumber, awbNumber: cleanNumber(invNumber) }));
            }

            setSearching(false);
        },
        [dispatch, customerNumber]
    );

    const onSubmit = useCallback(
        async ({ number, searchType }: FormSearchValues) => {
            const num = searchType === SearchType.invoice_number ? number : cleanNumber(number);
            setSearching(true);
            setNumberToSearch(num);
            setSearchType(searchType);
            searchInvoice(num, searchType);
        },
        [searchInvoice]
    );

    useEffect(() => {
        if (!prevLocationLocal) {
            dispatch(customerActions.resetSearchNumber());
        }
    }, [dispatch, prevLocationLocal]);

    /**
     * Redirect to next screen if is a valid AWB, if not and is a credit note will display a modal
     */
    useEffect(() => {
        if (customer.search && !prevLocationLocal && numberToSearch && !searching) {
            if (!customer.search.is_valid_credit_note) {
                if (searchType === SearchType.invoice_number) {
                    navigate(`./search?invoice_number=${numberToSearch}`);
                } else if (searchType === SearchType.mawb) {
                    navigate(`./search?awb_number=${numberToSearch}`);
                }
            } else if (!showCreditNoteModal && customer.search.is_valid_credit_note) {
                setShowCreditNoteModal(true);
            }
        }
    }, [customer.search, navigate, numberToSearch, searchType, prevLocationLocal, showCreditNoteModal, searching]);

    /**
     * Subscribe modal if BE returns 404
     */
    useEffect(() => {
        if (customer.errors?.errors.length && !showErrorModal && !searching && numberToSearch) {
            if (customer.errors?.code === 404) {
                setShowSubscribeModal(true);
            } else {
                setShowErrorModal(true);
            }
        }
    }, [customer.errors, showErrorModal, searching, numberToSearch]);

    const onCloseErrorModal = useCallback(() => setShowErrorModal(false), []);

    /**
     * Function to close the credit note modal
     */
    const onCloseCreditNoteModal = useCallback(() => {
        setNumberToSearch('');

        dispatch(customerActions.resetSearchNumber());

        setShowCreditNoteModal(false);
    }, []);

    /**
     * Function to close subscribe confirmation modal
     */
    const onCloseSubscriptionStatusModal = useCallback(() => {
        setShowSubscriptionStatusModal(false);
    }, []);

    /**
     * This function set a valid customer number if the user enters another one
     */
    useEffect(() => {
        if (customerNumberParam && customer.customerNumber !== customerNumberParam) {
            window.history.replaceState(null, `${customerNumberParam}`, `${customer.customerNumber}`);
        }
    }, [customerNumberParam, dispatch, customer.customerNumber]);

    /**
     * Function to close the subscribe modal
     */
    const onCloseSubscribeModal = useCallback(async () => {
        await dispatch(customerActions.resetSubscribeStatus());

        setShowSubscribeModal(false);
    }, [dispatch]);

    /**
     * Display a modal with the subscribtion status
     */
    useEffect(() => {
        if (customer.invoiceSubscribeSuccess) {
            setSubscriptionStatus(
                `Thank you for using our subscription service. When your invoice is ready we will send an email to: ${customer.invoiceSubscribeEmail}`
            );

            setShowSubscriptionStatusModal(true);
        } else if (customer.invoiceSubscribeError) {
            setSubscriptionStatus('Oops! An unknown error ocurred. Please try again.');

            setShowSubscriptionStatusModal(true);
        }

        onCloseSubscribeModal();
    }, [customer.invoiceSubscribeSuccess, customer.invoiceSubscribeError, onCloseSubscribeModal]);

    return (
        <GridContainer columns={1} className='search__main'>
            <GridContainer className='search__container-form' columns={1} isFullWidth>
                <Header />
                <SearchForm
                    initialValues={{ number: numberToSearch, searchType: SearchType.mawb }}
                    onSubmit={onSubmit}
                    loading={customer.loading}
                />
                <Footer prevLocationLocal={prevLocationLocal} />
            </GridContainer>
            <SubscribeModal
                isOpen={showSubscribeModal}
                initialValues={{ number: numberToSearch, email: '' }}
                onCloseModal={onCloseSubscribeModal}
            />
            <InvalidCustomerModal
                isOpen={showErrorModal}
                rightButtonText='Back to search'
                onCloseModal={onCloseErrorModal}
            />
            <GlobalModal
                isOpen={showCreditNoteModal}
                description='Please enter a valid invoice number. Credit notes may be applied at the shopping cart screen'
                descriptionSize={TEXT_SIZES.LG}
                rightButtonText='Ok'
                rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
                onCloseModal={onCloseCreditNoteModal}
            />
            <GlobalModal
                isOpen={showSubscriptionStatusModal}
                description={subscriptionStatus}
                descriptionSize={TEXT_SIZES.LG}
                rightButtonText='Ok'
                rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
                onCloseModal={onCloseSubscriptionStatusModal}
                showIcon={false}
            />
        </GridContainer>
    );
};
