export enum ICON_ORIENTATION {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum ICON_SIZES {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}
