import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createLogger } from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { appReducer } from './reducers';

const customerSubsetFilter = createFilter('customerReducer', [
    'customer',
    'customerNumber',
    'search',
    'searchedList',
    'invoiceDocument',
    'creditNotes',
    'creditNotesErrors',
    'editableInvoicesList',
]);

const cartSubsetFilter = createFilter('cartReducer', [
    'cartBill',
    'cartBillCompleted',
    'paymentRequests',
    'dhlPaymentRequests',
    'errors',
    'paymentSuccess',
    'paymentSuccessData',
    'invoicesAdded',
    'paymentNoteSuccess',
    'preTotal',
    'receipt',
]);

const paymentMethodsFilter = createFilter('paymentMethodsReducer', [
    'creditCards',
    'echecks',
    'cargoCredit',
    'signetCredit',
    'paypal',
    'creditCardGuest',
    'isFormCardValid',
    'tab',
]);

const authSubsetFilter = createFilter('authReducer', ['isValidPasscode']);

const isReduxDebug = process.env.REACT_APP_IS_REDUX_DEBUG;

const logger = createLogger({
    collapsed: true,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cartReducer', 'customerReducer', 'paymentMethodsReducer', 'authReducer'],
    transforms: [customerSubsetFilter, cartSubsetFilter, paymentMethodsFilter, authSubsetFilter],
    stateReconciler: autoMergeLevel2,
};

type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState, action: AnyAction) => {
    if (action.type === 'auth/sessionLogout') {
        //TODO check
        storage.removeItem('persist:root');

        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        });

        if (isReduxDebug === 'true') {
            middleware.push(logger);
        }

        return middleware;
    },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type ThunkConfig<T> = {
    rejectValue: T;
    state: RootState;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
