export const formatNumberToDHL = (num: string | number): string => {
    let numb;

    if (typeof num !== 'undefined' && num !== null && num !== '') {
        let n = typeof num == 'number' ? num.toString() : num;
        n = n?.replace(',', '');

        numb = Number(n).toFixed(2);
    } else {
        numb = '0';
    }

    return numb.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
