import { Headline } from '@dhl-official/react-ui-library';
import { HEADLINE_COLORS, HEADLINE_FONT_STRETCHES, HEADLINE_LEVELS, HEADLINE_TAGS } from '../../../types';
import { TitleSummaryCardProps } from './title-card-types';

const TitleSummaryCard = ({
    fontStretch = HEADLINE_FONT_STRETCHES.NORMAL,
    color = HEADLINE_COLORS.RED,
    designLevel = HEADLINE_LEVELS[4],
    tag = HEADLINE_TAGS.H4,
    className = '',
    children,
    ...restProps
}: TitleSummaryCardProps) => {
    return (
        <Headline
            {...restProps}
            fontStretch={fontStretch}
            color={color}
            designLevel={designLevel}
            tag={tag}
            className={className}
        >
            {children}
        </Headline>
    );
};

export default TitleSummaryCard;
