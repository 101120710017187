import { useEffect, useState } from 'react';
import { selectPaymentMethods } from '../selectors';
import { useAppSelector } from '../store';

interface UseCreditAvailable {
    creditAvailable: number;
}

export const useCreditAvailable = (): UseCreditAvailable => {
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const [creditAvailable, setCredit] = useState<number>(0);

    useEffect(() => {
        setCredit(paymentMethods.paymentMethodSelected?.avaliableCredit || 0);
    }, [paymentMethods.paymentMethodSelected]);

    return { creditAvailable };
};
