import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { TextTableProps } from './text-types';

const TextTable = ({
    children,
    weight = TEXT_WEIGHTS[700],
    size = TEXT_SIZES['MD'],
    className = '',
}: TextTableProps) => (
    <Text size={size} weight={weight} className={className}>
        {children}
    </Text>
);

export default TextTable;
