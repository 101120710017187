export const creditCardMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
export const americanExpressMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
export const cvvMask = [/\d/, /\d/, /\d/];
export const prefixMask = [/\d/, /\d/, /\d/];
export const cvvAmexMask = [/\d/, /\d/, /\d/, /\d/];
export const expirationDateMask = [/\d/, /\d/, '/', /\d/, /\d/];
export const customerNumberMask = [/./, /./, /./];
export const maskAwb = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const emailPattern = /^(\w+[-_.ñáéíóúü]*)+@(\w+[-_]*)+(\.\w{2,})+$/;
export const onlyLettersPattern = /^[a-zA-Z\s]*$/;
export const onlyDigitsPattern = /^\d*(\.\d{0,2})?$/;
export const accountNumberPattern = /^\d{5,17}$/;
export const routingNumberPattern = /^\d{5,9}$/;
export const numberCommaPattern = /^(0|[1-9][0-9]{0,2}(?:(,[0-9]{3})*|[0-9]*))(\.[0-9]+){0,1}$/;
export const invoiceNumberPattern = /^(.{3})([0-9]{4})([1-9]|1[012])[-]\d+$/;
export const customerNumberPattern = /^(.{3})([0-9]{4})(?:0?[1-9]|1[012])[-]\d+$/;
