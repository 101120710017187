import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UIStore } from './types';

const initialState: UIStore = {
    loading: false,
    msgError: '',
    transactions: 0,
    totalTransactions: 0,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string>) => {
            state.msgError = action.payload;
        },
        removeError: (state) => {
            state.msgError = '';
        },
        loading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        increaseTransaction: (state) => {
            state.transactions += 1;
        },
        decreaseTransaction: (state) => {
            state.transactions -= 1;
        },
        resetTransaction: (state) => {
            state.transactions = 0;
            state.totalTransactions = 0;
        },
    },
});

export const { actions: uiActions } = uiSlice;
export const { reducer: uiReducer } = uiSlice;
