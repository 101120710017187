import { Card, IconTrashBinDelete } from '@dhl-official/react-ui-library';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDHL, GlobalModal } from '..';
import useAuth from '../../context/authContext/auth-context';
import { useCreditAvailable } from '../../hooks/get-credit';
import { selectCartReducer, selectCustomer, selectPaymentMethods } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { deleteDHLOneAwbFromCart, getCartBill } from '../../store/actions';
import { BUTTON_TYPES, BUTTON_VARIANTS, ICON_ORIENTATION } from '../../types';
import { COLORS } from '../../types/@dhl-official:react-ui-library/constants';
import { CustomersType, DHLPaymentRequestType, PaymentMethodsType, PaymentRequestType } from '../../types/sprintpay';
import Fee from './fee/fee';
import InvoiceNumberSection from './invoice-number-section';
import PayPalButton from './paypalButton/paypal-button';
import { SummaryCardProps } from './summary-types';
import TitleSummaryCard from './titleCard/title-card';
import TitleSectionSummary from './titleSection/title-section';
import TotalFee from './totalFee/total-fee';

export const SummaryCard = ({
    onPressPay,
    onPressDeleteCart,
    onPressDHLInvoiceCartDelete,
    onEditInvoice,
}: SummaryCardProps) => {
    const auth = useAuth();
    const dispatch = useAppDispatch();
    const cart = useAppSelector(selectCartReducer);
    const customer = useAppSelector(selectCustomer);
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showInsufficientFundsModal, setInsufficientFundsModal] = useState(false);
    const [showMaxAmountAllowed, setMaxAmountAllowed] = useState(false);
    const [paymentsReq, setPayments] = useState<DHLPaymentRequestType[]>([]);
    const [creditNotes, setCreditNotes] = useState<PaymentRequestType[]>([]);
    const { creditAvailable } = useCreditAvailable();
    const navigate = useNavigate();

    const userType: CustomersType | string = useMemo(() => auth.getUserType(), [auth]);

    const { loading, dhlPaymentRequests, deletingCart, cartBill, preTotal } = cart;

    const _onPressPay = (paymentMethodType?: PaymentMethodsType) => {
        if (!!paymentsReq.length) {
            if (
                (paymentMethods.paymentMethodType === PaymentMethodsType.SIGNET ||
                    paymentMethods.paymentMethodType === PaymentMethodsType.CARGOSPRINT_CREDIT) &&
                creditAvailable < cart.cartBill.total
            ) {
                setInsufficientFundsModal(true);
                return;
            }

            onPressPay(paymentMethodType);
        } else {
            setShowErrorModal(true);
        }
    };

    const _onPressAdd = useCallback(
        () =>
            navigate(`/customer/${customer.customerNumber}`, {
                state: { prevLocation: true },
            }),
        [customer.customerNumber]
    );

    const onCloseModal = useCallback(() => setShowErrorModal(false), []);

    const onPressOk = useCallback(() => {
        onCloseModal();

        navigate(`/customer/${customer.customerNumber}`, {
            state: { prevLocation: true },
        });
    }, [navigate, onCloseModal]);

    const onCloseFundsModal = useCallback(() => setInsufficientFundsModal(false), []);

    const onCloseMaxAmountModal = useCallback(() => setMaxAmountAllowed(false), []);

    useEffect(() => {
        const cartPaymentRequests = dhlPaymentRequests.filter(
            (item: DHLPaymentRequestType) => item.is_credit_note === false
        );

        const creditNotes =
            dhlPaymentRequests.find((item: DHLPaymentRequestType) => item.is_credit_note === true)?.fee_content ?? [];
        setPayments(cartPaymentRequests);
        setCreditNotes(creditNotes);
    }, [dhlPaymentRequests]);

    useEffect(() => {
        if (Number(preTotal) < 0) {
            creditNotes.forEach((note) => {
                if (note.awb) {
                    dispatch(deleteDHLOneAwbFromCart(note.awb));
                }
            });
            if (paymentMethods.paymentMethodType) {
                dispatch(getCartBill(paymentMethods.paymentMethodType));
            }
        }
    }, [preTotal]);

    /**
     * This useEffect is going to display a modal if the total > max amount allowed to make a payment
     * */
    // useEffect(() => {
    //   if (
    //     paymentMethods.paymentMethodType === PaymentMethodsType.PAYPAL &&
    //     (cart.cartBill.total > maxAmountAllowed)) {
    //     setMaxAmountAllowed(true);
    //   }
    // }, [
    //   paymentMethods.paymentMethodType,
    //   cart.cartBill.total,
    //   maxAmountAllowed
    // ]);

    return (
        <>
            <Card className='cart__summary-body mt-3'>
                <Card.Body>
                    <TitleSummaryCard>Payment summary</TitleSummaryCard>
                    <ButtonDHL
                        onClick={onPressDeleteCart}
                        id='delete'
                        name='delete'
                        isBlock={false}
                        variant={BUTTON_VARIANTS.TEXT}
                        type={BUTTON_TYPES.BUTTON}
                        className='summary__delete-button p-0 float-end'
                        icon={<IconTrashBinDelete style={{ fill: COLORS.RED }} />}
                        iconOrientation={ICON_ORIENTATION.LEFT}
                        loading={deletingCart}
                        showLoadingIcon
                    >
                        Empty cart
                    </ButtonDHL>
                    {!!paymentsReq.length && <TitleSectionSummary className='mt-4'>Due to DHL</TitleSectionSummary>}
                    {paymentsReq.length > 0 &&
                        paymentsReq.map((data: DHLPaymentRequestType, index: number) => (
                            <Fragment key={index}>
                                <InvoiceNumberSection
                                    invoiceNumber={data.invoice_number}
                                    containerClassName='cart__invoice-number-section'
                                    onPressEdit={() => onEditInvoice(data.invoice_number)}
                                    onPressDelete={() => onPressDHLInvoiceCartDelete(data.invoice_number)}
                                />
                                {data.fee_content.length > 0 &&
                                    data.fee_content.map((item: PaymentRequestType) => (
                                        <Fee key={item.id} fee={item.awb} feeAmount={item.amount} />
                                    ))}
                            </Fragment>
                        ))}
                    {!!creditNotes.length && <TitleSectionSummary className='mt-2'>Credit notes</TitleSectionSummary>}
                    {creditNotes.map((item: PaymentRequestType, index: number) => (
                        <Fragment key={index}>
                            <InvoiceNumberSection
                                invoiceNumber={item.awb || ''}
                                containerClassName='cart__invoice-number-section'
                                onPressDelete={() => onPressDHLInvoiceCartDelete(item.awb || '', true)}
                                isEditable={false}
                            />
                            <Fee key={item.id} fee={item.awb} feeAmount={item.amount} />
                        </Fragment>
                    ))}
                    {!!cartBill.subTotal && (
                        <div
                            style={{
                                borderTop: `1px solid rgba(140, 140, 140, 0.25)`,
                                borderBottom: `1px solid rgba(140, 140, 140, 0.25)`,
                                marginTop: 20,
                                marginBottom: 20,
                                paddingBottom: 10,
                            }}
                        >
                            <Fee
                                fee='Charge & Fees subtotal'
                                feeAmount={cartBill.subTotal}
                                feeDescription='Due to DHL'
                                showFeeDescription
                                className='mt-3'
                            />
                        </div>
                    )}
                    {(!!cartBill.creditCardFee || !!cartBill.handlingFee) && (
                        <TitleSectionSummary>Due to SprintPay</TitleSectionSummary>
                    )}
                    {!!cartBill.creditCardFee && (
                        <Fee
                            fee='Credit card fee'
                            feeAmount={cartBill.creditCardFee}
                            feeDescription='Due to CargoSprint and is the credit card merchant processing fee'
                            showFeeDescription
                        />
                    )}
                    {!!cartBill.handlingFee && (
                        <Fee
                            fee='Handling Fee'
                            feeAmount={cartBill.handlingFee}
                            feeDescription='Due to CargoSprint'
                            showFeeDescription
                        />
                    )}
                    {(!!cartBill.creditCardFee || !!cartBill.handlingFee) && (
                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: -40,
                                paddingBottom: 10,
                            }}
                        />
                    )}
                    <div
                        style={{
                            borderTop: `1px solid rgba(140, 140, 140, 0.25)`,
                            marginTop: 40,
                            marginBottom: 20,
                            paddingBottom: 10,
                        }}
                    >
                        <TotalFee totalAmount={cartBill.total || preTotal} />
                    </div>
                    {paymentMethods.paymentMethodType !== PaymentMethodsType.PAYPAL && (
                        <ButtonDHL
                            onClick={() => {
                                if (
                                    paymentMethods.paymentMethodSelected ||
                                    auth.getUserType() === CustomersType.GUEST
                                ) {
                                    if (userType === CustomersType.GUEST) {
                                        _onPressPay(PaymentMethodsType.CREDIT_CARD);
                                    } else {
                                        _onPressPay();
                                    }
                                }
                            }}
                            id='pay'
                            name='pay'
                            isBlock
                            type={BUTTON_TYPES.SUBMIT}
                            className='mt-4'
                            loading={loading}
                            isDisabled={loading}
                        >
                            {paymentMethods.paymentMethodSelected || auth.getUserType() === CustomersType.GUEST
                                ? 'Continue to pay'
                                : 'Please, select a payment method'}
                        </ButtonDHL>
                    )}
                    <PayPalButton
                        loadButton={paymentMethods.paymentMethodType === PaymentMethodsType.PAYPAL}
                        onPressPay={() => _onPressPay(PaymentMethodsType.PAYPAL)}
                        // disabled={cart.cartBill.total > maxAmountAllowed}
                    />
                    <ButtonDHL
                        onClick={_onPressAdd}
                        id='add'
                        name='add'
                        isBlock
                        type={BUTTON_TYPES.BUTTON}
                        variant={BUTTON_VARIANTS.OUTLINE}
                        className='mt-4'
                        isDisabled={loading}
                    >
                        Add more items
                    </ButtonDHL>
                </Card.Body>
            </Card>
            <GlobalModal
                isOpen={showErrorModal}
                title='Oops! Your cart is empty'
                rightButtonText='Ok'
                onPressRightButton={onPressOk}
                onCloseModal={onCloseModal}
                shouldShowCloseButton={false}
            />
            <GlobalModal
                isOpen={showInsufficientFundsModal}
                title='Oops! Insufficient funds in your account.'
                rightButtonText='Ok'
                onPressRightButton={onCloseFundsModal}
                onCloseModal={onCloseFundsModal}
                shouldShowCloseButton={false}
            />
            <GlobalModal
                isOpen={showMaxAmountAllowed}
                title='Payments over 10k are not allowed'
                rightButtonText='Ok'
                onPressRightButton={onCloseMaxAmountModal}
                onCloseModal={onCloseMaxAmountModal}
                shouldShowCloseButton={false}
            />
        </>
    );
};
