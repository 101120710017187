import { Image, RadioField } from '@dhl-official/react-ui-library';
import { useEffect, useState } from 'react';
import { TitleFormSection } from '../../..';
import paypalImage from '../../../../assets/images/paypal.png';
import { RADIOFIELD_SIZES } from '../../../../types';
import { PaymentMethodsType } from '../../../../types/sprintpay';
import { PaypalProps } from './paypal-types';

export const Paypal = ({ onSelectPaypal, paypal, paymentMethodSelected }: PaypalProps) => {
    const [selectCredit, setCreditSelected] = useState(false);
    const buttonHandler = () => onSelectPaypal(paypal);

    useEffect(() => {
        if (paymentMethodSelected?.type === PaymentMethodsType.PAYPAL) {
            setCreditSelected(true);
        }
    }, [paymentMethodSelected]);

    return (
        <>
            <TitleFormSection isParagraph={false} className='paypal-tab__title'>
                Select Paypal as payment method
            </TitleFormSection>
            <RadioField
                name='paypal'
                id='paypal'
                value={PaymentMethodsType.PAYPAL}
                isChecked={selectCredit}
                isBlock
                className='paypal-tab__radio-paypal'
                size={RADIOFIELD_SIZES.MD}
                onChange={buttonHandler}
            >
                <Image
                    src={paypalImage}
                    alt='paypal'
                    className='paypal-tab__paypal-image'
                    sizes={[
                        '(max-width: 320px) 320px',
                        '(max-width: 480px) 480px',
                        '(max-width: 768px) 768px',
                        '(max-width: 1024px) 1024px',
                        '1440px',
                    ]}
                    srcSet={[
                        `${paypalImage} 320w`,
                        `${paypalImage} 480w`,
                        `${paypalImage} 768w`,
                        `${paypalImage} 1024w`,
                        `${paypalImage} 1440w`,
                    ]}
                />
            </RadioField>
        </>
    );
};
