import { IconCancelCircle, Text, GridCell } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { ErrorAppProps } from './types';

export const ErrorApp = ({
    children,
    size = TEXT_SIZES.XS,
    weight = TEXT_WEIGHTS[400],
    isParagraph = false,
    ...restProps
}: ErrorAppProps) => {
    return (
        <GridCell className='error__container animate__animated animate__fadeIn'>
            <IconCancelCircle name='error' title='Error' className='error__icon' />
            <Text isParagraph={isParagraph} size={size} weight={weight} className='error__text' {...restProps}>
                {children}
            </Text>
        </GridCell>
    );
};
