export enum BUTTON_TYPES {
    BUTTON = 'button',
    RESET = 'reset',
    SUBMIT = 'submit',
}

export enum BUTTON_VARIANTS {
    PRIMARY = 'primary',
    OUTLINE = 'outline',
    TEXT = 'text',
}

export enum BUTTON_SIZES {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}
