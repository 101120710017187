import { RootState } from '../store';

export const selectCreditCards = ({ paymentMethodsReducer: { creditCards } }: RootState) => creditCards;

export const selectEchecks = ({ paymentMethodsReducer: { echecks } }: RootState) => echecks;

export const selectCargoCredit = ({ paymentMethodsReducer: { cargoCredit } }: RootState) => cargoCredit;

export const selectSignet = ({ paymentMethodsReducer: { signetCredit } }: RootState) => signetCredit;

export const selectPaymentMethods = ({ paymentMethodsReducer }: RootState) => paymentMethodsReducer;
