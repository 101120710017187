import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Requests from '../../api/request/auth-request';
import { braintreeActions } from './reducers';

export const getBraintreeClientToken = createAsyncThunk<void, void>(
    'braintree/getBraintreeClientToken',
    async (props, { dispatch }) => {
        try {
            const response = await Requests.braintreeClientToken();

            dispatch(braintreeActions.saveBraintreeClientToken(response.clientToken));
        } catch (error: unknown) {}
    }
);
