import { Center, Loader } from '@dhl-official/react-ui-library';
import { BraintreePayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../store';
import { braintreeActions } from '../../../store/actions';
import { ButtonWrapperType, PaypalButtonStyleType } from './paypal-button-types';

const style: PaypalButtonStyleType = { label: 'paypal', layout: 'vertical' };
const amount = '2';

export const ButtonWrapper = ({ currency, showSpinner, onPressPay, disabled }: ButtonWrapperType) => {
    const [{ options, isPending, isResolved }, dispatchPaypal] = usePayPalScriptReducer();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatchPaypal({
            type: 'resetOptions',
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency]);

    return (
        <>
            {showSpinner && isPending && !isResolved ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                <BraintreePayPalButtons
                    style={style}
                    disabled={disabled}
                    fundingSource='paypal'
                    forceReRender={[style, amount]}
                    createOrder={function (data, actions) {
                        return actions.braintree.createPayment({
                            flow: 'checkout',
                            amount: amount,
                            currency: 'USD',
                            intent: 'capture',
                            enableShippingAddress: false,
                        });
                    }}
                    onApprove={function (data, actions) {
                        return actions.braintree.tokenizePayment(data).then((payload) => {
                            dispatch(braintreeActions.savePaypalPayload(payload));

                            onPressPay();
                        });
                    }}
                />
            )}
        </>
    );
};
