import { InputField } from '@dhl-official/react-ui-library';
import { INPUTFIELD_VALIDATION_TYPES } from '../../types';
import { InputProps } from './input-types';

const InputDHL = (props: InputProps) => {
    const {
        field,
        form,
        className = '',
        id,
        name,
        isBlock,
        onKeyDown,
        variant,
        innerRef,
        rightIcon,
        type = 'text',
        autoFocus,
        disabled,
    } = props;
    const { touched, errors } = form;
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: INPUTFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    return (
        <InputField
            {...field}
            id={id}
            name={name}
            type={type}
            autoFocus={autoFocus}
            rightIcon={rightIcon}
            isBlock={isBlock}
            onKeyDown={onKeyDown}
            disabled={disabled}
            variant={variant}
            validation={validations}
            className={`input__dhl ${className}`}
            ref={innerRef}
        />
    );
};

export default InputDHL;
