import { createSelector } from 'reselect';
import { RootState } from '../store';

export const selectCustomer = ({ customerReducer }: RootState) => customerReducer;

export const selectCustomerNumber = ({ customerReducer }: RootState) => customerReducer.customerNumber;

export const selectCustomerProfile = ({ authReducer }: RootState) => authReducer.userInfo;

export const selectMaxAmountAllowed = createSelector(
    (state: RootState) => state.authReducer.userInfo,
    (userInfo) => userInfo?.maxAmountAllowed || 0
);
