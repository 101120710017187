import { IconCancelCircle } from '@dhl-official/react-ui-library';
import { useCallback, useContext } from 'react';
import { BraintreeContext } from '../../context/braintree/braintree-context';
import { BraintreeFieldProps } from './braintree-types';

export const BraintreeField = ({ label, id, type, placeholder }: BraintreeFieldProps) => {
    const { fieldsConditions } = useContext(BraintreeContext);

    const getFieldValidity = useCallback(() => {
        if (!fieldsConditions || !fieldsConditions[type]) return false;
        return fieldsConditions[type].isRequired || fieldsConditions[type].isWrongValue;
    }, [fieldsConditions[type]]);

    const classNames = useCallback(() => {
        const className = ['braintree-hosted-fields'];
        if (
            fieldsConditions &&
            fieldsConditions[type] &&
            (fieldsConditions[type].isRequired || fieldsConditions[type].isWrongValue)
        ) {
            className.push('invalid');
        }
        if (fieldsConditions && fieldsConditions[type] && fieldsConditions[type].isFocused) {
            className.push('focused');
        }

        return className.join(' ');
    }, [fieldsConditions[type]]);

    return (
        <div className='braintree-field-wrapper'>
            <label className='braintree-label' htmlFor={id}>
                {label}
            </label>
            <div id={id} className={classNames()}></div>
            {getFieldValidity() && (
                <div className='braintree-error'>
                    <IconCancelCircle />
                    <>
                        {fieldsConditions[type].isRequired && <div>{label} is required</div>}
                        {fieldsConditions[type].isWrongValue && <div>Please enter a valid {label}</div>}
                    </>
                </div>
            )}
        </div>
    );
};

BraintreeField.defaultProps = {
    __isBraintreeField: true,
};
