import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentSuccessResponseType } from '../../types/sprintpay';
import { ShipmentDetailsType } from '../customer/types';
import { ErrorsType } from '../errors/types';
import { getCartBill, getDHLCart } from './actions';
import { PaymentsStore } from './types';

const initialState: PaymentsStore = {
    cartBill: {
        subTotal: 0,
        handlingFee: 0,
        creditCardFee: 0,
        convenienceFee: 0,
        total: 0,
        iscFee: 0,
        nonIscFee: 0,
        customsFee: 0,
        handlingRate: 0,
        cart: [],
    },
    cartBillCompleted: false,
    paymentRequests: [],
    dhlPaymentRequests: [],
    loading: false,
    errors: null,
    paymentSuccess: false,
    paymentSuccessData: null,
    paying: false,
    deletingCart: false,
    deletingDHLInvoiceFromCart: false,
    addingPayment: false,
    invoicesAdded: [],
    addingPaymentNote: false,
    paymentNoteSuccess: false,
    preTotal: 0,
    updatingPaymentRequest: false,
    savingComments: false,
    cartEdited: false,
    editingCart: false,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        invoiceAdded: (state, action: PayloadAction<ShipmentDetailsType>) => {
            state.invoicesAdded.push(action.payload);
        },
        paymentSuccess: (state, action: PayloadAction<PaymentSuccessResponseType>) => {
            return {
                ...initialState,
                paymentSuccess: true,
                paymentSuccessData: action.payload,
                dhlPaymentRequests: [],
            };
        },
        paymentError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.errors = action.payload;
            state.paymentSuccess = false;
            state.loading = false;
            state.paying = false;
            state.updatingPaymentRequest = false;
        },
        payingCart: (state, action: PayloadAction<boolean>) => {
            state.paying = action.payload;
            state.paymentSuccess = false;
            state.errors = null;
        },
        deleteCartSuccess: (state) => {
            state.cartBill = initialState.cartBill;
            state.cartBillCompleted = false;
            state.invoicesAdded = [];
        },
        deleteDHLInvoiceFromCartSuccess: (state) => {
            state.invoicesAdded = [];
        },
        deleteDHLOneAwbFromCartSuccess: (state) => {
            state.invoicesAdded = [];
        },
        deletingCart: (state, action: PayloadAction<boolean>) => {
            state.deletingCart = action.payload;
        },
        deletingDHLInvoiceFromCart: (state, action: PayloadAction<boolean>) => {
            state.deletingDHLInvoiceFromCart = action.payload;
        },
        deletingDHLOneAwbFromCart: (state, action: PayloadAction<boolean>) => {
            state.deletingCart = action.payload;
        },
        addingPaymentRequest: (state, action: PayloadAction<boolean>) => {
            state.addingPayment = action.payload;
        },
        updatingPaymentRequest: (state, action: PayloadAction<boolean>) => {
            state.updatingPaymentRequest = action.payload;
        },
        resetCartErrors: (state) => {
            state.errors = null;
        },
        addingPaymentNote: (state, action: PayloadAction<boolean>) => {
            state.addingPaymentNote = action.payload;
            state.paymentNoteSuccess = false;
        },
        addPaymentNoteSuccess: (state) => {
            state.paymentNoteSuccess = true;
        },
        cartEdited: (state, action: PayloadAction<boolean>) => {
            state.cartEdited = action.payload;
        },
        editingCart: (state, action: PayloadAction<boolean>) => {
            state.editingCart = action.payload;
        },
        resetCart: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDHLCart.pending, (state) => {
            state.loading = true;
            state.errors = null;
        });
        builder.addCase(getDHLCart.fulfilled, (state, action) => {
            state.dhlPaymentRequests = action.payload.dhlPaymentsRequest;
            state.preTotal = action.payload.sum;
            state.loading = false;
        });
        builder.addCase(getDHLCart.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });

        builder.addCase(getCartBill.pending, (state) => {
            state.loading = true;
            state.errors = null;
        });
        builder.addCase(getCartBill.fulfilled, (state, action) => {
            state.dhlPaymentRequests = action.payload.cartBill.cart;
            state.cartBill = action.payload.cartBill;
            state.cartBillCompleted = true;
            state.paymentNoteSuccess = false;
            state.preTotal = action.payload.sum;
            state.loading = false;
        });
        builder.addCase(getCartBill.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
    },
});

export const { actions: cartActions } = cartSlice;
export const { reducer: cartReducer } = cartSlice;
