export enum PaymentMethodsType {
    SIGNET = 'SIGNET',
    CREDIT_CARD = 'CREDIT_CARD',
    ECHECK = 'ECHECK',
    CARGOSPRINT_CREDIT = 'CARGOSPRINT_CREDIT',
    PAYPAL = 'PAYPAL',
}

export enum PaymentMethodsComponentNameType {
    SIGNET = 'signet',
    CREDIT_CARD = 'credit-card',
    ECHECK = 'echeck',
    CARGOSPRINT_CREDIT = 'cargo-credit',
    PAYPAL = 'paypal',
}

export type TypesPaymentMethods = keyof typeof PaymentMethodsType;

export interface PaymentType {
    awb: string;
    amount: number | string;
    hawb?: string;
    notes?: string;
    glCode?: string;
    paymentType?: string;
    facility?: number;
    notificationEmail?: string;
    customerReference?: string;
    paidTo?: string;
    externalData?: PaymentTypeExternalData;
    source?: string;
    origin?: string;
    destination?: string;
}

export interface PaymentTypeExternalData {
    id?: number;
    source?: string;
    name?: string;
    awbId?: string;
    hawbId?: string;
    chargeId?: string;
    pickupDate?: string;
    paymentType?: string;
    salesforceId?: string;
    sourceSystem?: string;
    product?: string;
    station?: string;
    type?: string;
    vendor?: string;
    voucher?: string;
    glCode?: string;
    createdDate?: string;
    fet?: string;
    tsc?: string;
    weightUnit?: string;
    pieces?: string;
    mawb?: string;
    iataNumber?: string;
    billedWeight?: string;
    businessUnit?: string;
    masterInvoiceNumber?: string;
    grossWeight?: string;
    billedTo?: string;
    billingCode?: string;
    date?: string;
    currencyCode?: string;
}

export interface PaymentRequestType {
    id: number;
    amount: number;
    orderId?: number;
    paymentType?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source: any;
    name?: string;
    awb?: string;
    hawb?: string;
    notes?: string;
    attachments?: string;
    paidTo?: string;
    notificationEmail?: string;
    customerReference?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    facility?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customer?: any;
    createdDate?: Date;
    searchId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [propName: string]: any;
}

export interface DHLPaymentRequestType {
    fee_content: PaymentRequestType[];
    invoice_number: string;
    is_credit_note?: boolean;
}

export interface ClientCartType {
    invoice_number: string;
    fee_content: CartContentDetails[];
}

export type CartContentDetails = {
    awb: string;
    amount: number;
    hawb?: string;
    notes?: string;
    paymentType: string;
    cargoFacility: string;
    notificationEmail: string;
    customerReference: string;
    paidTo: string;
    id: number;
};

export interface PaymentDataRequestType {
    braintreePaymentDataRequest?: BraintreePaymentDataRequest;
    payPalPaymentDataRequest?: PayPalPaymentDataRequestType;
    paymentMethod?: string;
    customerReference?: string;
    paymentNonce?: string;
}

export interface BraintreePaymentDataRequest {
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    customerReference: string;
    paymentNonce: string;
    email: string;
}

export interface PaymentTokenDataRequest {
    paymentMethod: {
        name: string;
    };
    paymentProcessor: {
        name: string;
    };
    token: string;
}

export interface FacilityType {
    handlingInstructionsSalesforceId: string;
    id: number;
}

export interface InvoiceType {
    id: number;
}

export interface CompletedPaymentType {
    amount: number;
    awb: string;
    createdDate: number;
    customerReference: '';
    facility: FacilityType;
    hawb: string;
    id: number;
    invoice: InvoiceType;
    isExternalPaymentConfirmationAvailable: boolean;
    isPaymentConfirmationAvailable: boolean;
    name: string;
}

export interface PaymentSuccessResponseType {
    authenticationToken: string;
    completedPayments: CompletedPaymentType[];
    saleStatus: string;
    transactionId: string;
}

export interface PayPalPaymentDataRequestType {
    accountType?: string;
    amount?: number;
    email?: string;
    firstName?: string;
    fullName?: string;
    lastName?: string;
    payerId?: string;
    paymentNonce?: string;
    phoneNumber?: string;
}
