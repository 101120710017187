import { Center } from '@dhl-official/react-ui-library';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ButtonDHL, ErrorApp } from '../../../components';
import InputDHL from '../../../components/common/input';
import { BUTTON_TYPES, BUTTON_VARIANTS, INPUTFIELD_TYPES } from '../../../types';
import { FormCustomerEmailProps, FormCustomerEmailValues } from './form-types';
import HeaderForm from './header-form';
import { validateEmailCustomer } from './validate';

export const SearchCustomerForm = ({
    onSubmitEmail,
    onSubmitPasscode,
    initialValues,
    loading,
    error,
    isValidEmail = false,
    onClickAnotherAccount,
}: FormCustomerEmailProps) => {
    return (
        <Center className='search-customer__wrapper'>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => validateEmailCustomer(values, isValidEmail)}
                onSubmit={(values) => {
                    if (!isValidEmail) {
                        onSubmitEmail(values);
                    } else {
                        onSubmitPasscode(values);
                    }
                }}
            >
                {({ handleSubmit, resetForm }: FormikProps<FormCustomerEmailValues>) => (
                    <Form
                        className='search-customer__form'
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <HeaderForm />
                        <Field
                            ariaLabel='email'
                            id='email'
                            name='email'
                            isBlock
                            variant={{
                                label: 'Email',
                                placeholder: 'Enter your email',
                                type: INPUTFIELD_TYPES.STATIC,
                            }}
                            component={InputDHL}
                            autoFocus
                            disabled={isValidEmail}
                        />
                        {isValidEmail && (
                            <Field
                                id='passcode'
                                name='passcode'
                                isBlock
                                variant={{
                                    label: 'One-time passcode',
                                    placeholder: 'Enter your passcode',
                                    type: INPUTFIELD_TYPES.STATIC,
                                }}
                                component={InputDHL}
                            />
                        )}
                        {!!error && <ErrorApp>{error}</ErrorApp>}
                        <ButtonDHL
                            id='search'
                            name='search'
                            isBlock
                            type={BUTTON_TYPES.SUBMIT}
                            loading={loading}
                            isDisabled={loading}
                            showLoadingIcon
                            className='search-customer__search-button'
                        >
                            {isValidEmail ? 'Validate code' : 'Validate email'}
                        </ButtonDHL>
                        {isValidEmail && (
                            <ButtonDHL
                                onClick={() => {
                                    resetForm();
                                    onClickAnotherAccount();
                                }}
                                className='mt-4'
                                id='another_acount'
                                name='another_acount'
                                isBlock
                                type={BUTTON_TYPES.BUTTON}
                                variant={BUTTON_VARIANTS.TEXT}
                            >
                                Use another email
                            </ButtonDHL>
                        )}
                    </Form>
                )}
            </Formik>
        </Center>
    );
};
