import { FormCreditCardValues } from '../components/paymentMethods/creditCard/newCreditCardForm/form-types';
import { FormCreditCardGuest } from '../components/paymentMethods/creditCardGuest/formGuest/form-types';
import { CreditCardParameters } from '../types/sprintpay';

export function mapFormToCardBraintree({
    street,
    country,
    state,
    city,
    zipCode,
}: FormCreditCardGuest | FormCreditCardValues): CreditCardParameters {
    return {
        billingAddress: {
            postalCode: zipCode,
            streetAddress: street,
            state,
            city,
            countryName: country,
        },
    };
}
