import { GridContainer, Loader } from '@dhl-official/react-ui-library';
import { useEffect, useState } from 'react';
import { GlobalModal } from '../..';
import { selectEchecksReducer } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { echecksActions } from '../../../store/actions';
import { deleteEcheck, saveEcheck } from '../../../store/echecks/actions';
import { EcheckType, PaymentMethodsType } from '../../../types/sprintpay';
import { IconTypes } from '../../common/modal/types';
import { Description } from '../description';
import { EcheckTabProps } from './echeck-tab-types';
import { EchecksList } from './listEchecks';
import { EcheckForm } from './newEcheckForm';
import { FormEcheckValues } from './newEcheckForm/form-types';

export const EcheckTab = ({ setPaymentMethodSelected, echecks, paymentMethodSelected, loading }: EcheckTabProps) => {
    const dispatch = useAppDispatch();
    const [echeckFormVisible, setEcheckFormVisible] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [echeckMessage, setEcheckMessage] = useState('');
    const [echeckToDelete, setEcheckToDelete] = useState<EcheckType>();
    const echecksReducer = useAppSelector(selectEchecksReducer);

    const onPressSelectEcheck = (echeck: EcheckType) => setPaymentMethodSelected(echeck, PaymentMethodsType.ECHECK);

    const onPressDeleteEcheck = (echeck: EcheckType) => {
        setEcheckToDelete(echeck);

        setShowConfirmModal(true);
    };

    const onConfirmDeleteEcheck = () => echeckToDelete && dispatch(deleteEcheck(echeckToDelete));

    const _onCloseModal = () => setShowConfirmModal(false);

    const _onCloseResponseModal = async () => {
        setShowResponseModal(false);

        setTimeout(() => {
            dispatch(echecksActions.resetEchecksHandler());
        }, 250);
    };

    const onSubmit = async ({
        type,
        accountHolderName,
        checkingAccountNumber,
        routingNumber,
        confirmRoutingNumber,
    }: FormEcheckValues) => {
        await dispatch(
            saveEcheck({
                accountType: type,
                accountNumber: checkingAccountNumber,
                holderName: accountHolderName,
                routingNumber: routingNumber,
                reRoutingNumber: confirmRoutingNumber,
            })
        );
    };

    useEffect(() => {
        if (echecksReducer.deleted || echecksReducer.added) {
            const message = echecksReducer.deleted
                ? 'Your eCheck has been deleted'
                : echecksReducer.added
                ? 'Your eCheck has been added'
                : '';

            setEcheckMessage(message);

            setEcheckFormVisible(false);

            _onCloseModal();

            setShowResponseModal(true);
        }
    }, [echecksReducer.deleted, echecksReducer.added]);

    useEffect(() => {
        if (!!echecksReducer.errors?.errors.length) {
            const message = echecksReducer.errors?.errors
                ? echecksReducer.errors.errors[0]
                : 'Oops! An unknown error ocurred. Please try again.';

            setEcheckMessage(message);

            _onCloseModal();

            setShowResponseModal(true);
        }
    }, [echecksReducer.errors]);

    const onPressAddEcheck = () => {
        setEcheckFormVisible(true);
    };

    return (
        <GridContainer
            columns={1}
            isFullWidth
            className='d-flex flex-column payment-methods__tab credit-card-tab__container'
        >
            {loading ? (
                <Loader />
            ) : (
                <>
                    {!echeckFormVisible ? (
                        <>
                            {echecks?.active ? (
                                <EchecksList
                                    echecks={echecks?.value}
                                    onPressSelect={onPressSelectEcheck}
                                    onPressDelete={onPressDeleteEcheck}
                                    onPressAdd={onPressAddEcheck}
                                    paymentMethodSelected={paymentMethodSelected}
                                />
                            ) : (
                                <Description>This payment method is not available</Description>
                            )}
                        </>
                    ) : (
                        <EcheckForm
                            onSubmit={onSubmit}
                            onPressCancel={() => setEcheckFormVisible(false)}
                            loading={echecksReducer.loading}
                        />
                    )}
                    <GlobalModal
                        isOpen={showConfirmModal}
                        loading={echecksReducer.deleting}
                        title='Are you sure you want to delete this payment method?'
                        description='If you delete this payment method, you will not be able to make any transactions with it, unless you add it again.'
                        onPressLeftButton={onConfirmDeleteEcheck}
                        leftButtonText='Delete payment method'
                        leftButtonLoading={true}
                        rightButtonText='Cancel'
                        onCloseModal={_onCloseModal}
                    />
                    <GlobalModal
                        isOpen={showResponseModal}
                        title={echeckMessage}
                        rightButtonText='OK'
                        iconType={
                            (echecksReducer.deleted || echecksReducer.added) && !echecksReducer.errors?.errors.length
                                ? IconTypes.IconCheckmarkCircle
                                : IconTypes.IconCancelCircle
                        }
                        onCloseModal={_onCloseResponseModal}
                        isStatic={false}
                    />
                </>
            )}
        </GridContainer>
    );
};
