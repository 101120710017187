import { Button, GridCell, GridContainer, Headline } from '@dhl-official/react-ui-library';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ButtonDHL, RadioDHL, TitleFormSection } from '../../..';
import {
    BUTTON_TYPES,
    BUTTON_VARIANTS,
    HEADLINE_COLORS,
    HEADLINE_FONT_STRETCHES,
    HEADLINE_LEVELS,
    HEADLINE_TAGS,
    HEADLINE_TEXT_TRANSFORMS,
    HEADLINE_VARIANTS,
    INPUTFIELD_TYPES,
    RADIOFIELD_SIZES,
} from '../../../../types';
import InputDHL from '../../../common/input';
import { EcheckFormProps, FormEcheckValues } from './form-types';
import { validateEcheckForm } from './validate';

const initialValues = {
    type: '',
    accountHolderName: '',
    checkingAccountNumber: '',
    routingNumber: '',
    confirmRoutingNumber: '',
};

export const EcheckForm = ({ onSubmit, onPressCancel, loading }: EcheckFormProps) => {
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={(values) => validateEcheckForm(values)}
            onSubmit={(values) => onSubmit(values)}
        >
            {({ handleSubmit, setFieldValue, values }: FormikProps<FormEcheckValues>) => (
                <Form
                    className='credit-card-tab__new-card-form'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                >
                    <GridCell className='credit-card-tab__container-cancel-button'>
                        <Headline
                            fontStretch={HEADLINE_FONT_STRETCHES.CONDENSED}
                            color={HEADLINE_COLORS.RED}
                            designLevel={HEADLINE_LEVELS[3]}
                            tag={HEADLINE_TAGS.H3}
                            variant={HEADLINE_VARIANTS.LIGHT}
                            textTransform={HEADLINE_TEXT_TRANSFORMS.UPPERCASE}
                            className='mt-1'
                        >
                            NEW eCheck (ACH Debit)
                        </Headline>
                        <ButtonDHL
                            onClick={onPressCancel}
                            id='cancel'
                            name='cancel'
                            variant={BUTTON_VARIANTS.OUTLINE}
                            type={BUTTON_TYPES.BUTTON}
                            className='credit-card-tab__cancel-button'
                        >
                            Cancel
                        </ButtonDHL>
                    </GridCell>
                    <TitleFormSection className='mt-3'>Select bank account type</TitleFormSection>
                    <GridCell spanColumns={1}>
                        <Field
                            id='checking'
                            name='type'
                            // eslint-disable-next-line react/no-children-prop
                            children='Checking'
                            value='checking'
                            isChecked={values.type === 'checking'}
                            onChange={() => setFieldValue('type', 'checking')}
                            className='credit-card-tab__radio-credit-card me-3'
                            size={RADIOFIELD_SIZES.MD}
                            component={RadioDHL}
                        />
                        <Field
                            id='savings'
                            name='type'
                            // eslint-disable-next-line react/no-children-prop
                            children='Savings'
                            value='savings'
                            isChecked={values.type === 'savings'}
                            onChange={() => setFieldValue('type', 'savings')}
                            className='credit-card-tab__radio-credit-card'
                            size={RADIOFIELD_SIZES.MD}
                            component={RadioDHL}
                        />
                    </GridCell>
                    <Field
                        id='accountHolderName'
                        name='accountHolderName'
                        isBlock
                        variant={{
                            label: 'Account holder name',
                            placeholder: 'Account holder name',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='checkingAccountNumber'
                        name='checkingAccountNumber'
                        isBlock
                        variant={{
                            label: 'Checking account number',
                            placeholder: 'Checking account number',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='routingNumber'
                        name='routingNumber'
                        isBlock
                        variant={{
                            label: 'Routing number',
                            placeholder: 'Routing number',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='confirmRoutingNumber'
                        name='confirmRoutingNumber'
                        isBlock
                        variant={{
                            label: 'Confirm routing number number',
                            placeholder: 'Confirm routing number number',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <GridContainer columns={1} isFullWidth rowGap='none' className='credit-card-tab__add-button'>
                        <ButtonDHL
                            onClick={handleSubmit}
                            id='add'
                            name='add'
                            isBlock
                            variant={BUTTON_VARIANTS.OUTLINE}
                            type={BUTTON_TYPES.BUTTON}
                            loading={loading}
                            showLoadingIcon
                        >
                            Add new eCheck
                        </ButtonDHL>
                    </GridContainer>
                </Form>
            )}
        </Formik>
    );
};
