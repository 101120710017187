import { createSlice } from '@reduxjs/toolkit';
import { GlobalModalSettings } from './types';

const initialState: GlobalModalSettings = {
    active: false,
    loading: false,
    modalHidden: false,
};

const globalAlertSlice = createSlice({
    name: 'globalAlert',
    initialState,
    reducers: {
        showGlobalModal: (state) => {
            state.active = true;
            state.modalHidden = false;
        },
        hideGlobalModal: (state) => {
            state.active = false;
        },
        onHideGlobalModal: (state) => {
            state.modalHidden = true;
        },
    },
});

export const { actions: globalAlertActions } = globalAlertSlice;
export const { reducer: globalAlertReducer } = globalAlertSlice;
