import { BRANDS, DuiThemeProvider } from '@dhl-official/react-ui-library';
import '@dhl-official/react-ui-library/dist/es/tokens/tokens.css';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AlertApp } from './components/globalAlert';
import { AuthProvider } from './context/authContext';
import { BraintreeProvider } from './context/braintree/braintree-context';
import { AppRoutes } from './routes/app-router';

export const App = () => {
    useEffect(() => {
        document.title = 'DHL Aviation';
    }, []);

    return (
        <Router>
            <AuthProvider>
                <DuiThemeProvider theme={{ name: BRANDS.DHL }}>
                    <BraintreeProvider>
                        <AppRoutes />
                        <AlertApp />
                    </BraintreeProvider>
                </DuiThemeProvider>
            </AuthProvider>
        </Router>
    );
};
