import moment from 'moment';
import { filterInt } from '.';
import { KeysLocalStorageTypes } from '../hooks';

export const getRemainingTokenTime = (serverCurrentTime?: string): number => {
    const expiresAt = sessionStorage.getItem(KeysLocalStorageTypes.EXPIRES_AT);

    const exp = filterInt(expiresAt);
    const newExp = moment.unix(exp);
    const now = serverCurrentTime ? moment.unix(filterInt(serverCurrentTime)) : +moment();

    return newExp.diff(now, 'minutes');
};
