import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    CargoCreditType,
    CreditCardPaymentTokenType,
    CreditCardType,
    EcheckType,
    PaymentMethodsType,
    PaymentSuccessResponseType,
    PaypalType,
    SignetCreditType,
} from '../../types/sprintpay';
import { PaymentMethods, PaymentMethodsStore } from './types';

const initialState: PaymentMethodsStore = {
    creditCards: {
        type: PaymentMethodsType.CREDIT_CARD,
        active: false,
        value: [],
    },
    echecks: {
        type: PaymentMethodsType.ECHECK,
        active: false,
        value: [],
    },
    cargoCredit: {
        type: PaymentMethodsType.CARGOSPRINT_CREDIT,
        active: false,
        value: {
            avaliableCredit: 0,
            paymentToken: null,
        },
    },
    signetCredit: {
        type: PaymentMethodsType.SIGNET,
        active: false,
        value: {
            avaliableCredit: 0,
            creditLimit: 0,
            wallet: 0,
            paymentToken: null,
        },
    },
    paypal: {
        type: PaymentMethodsType.PAYPAL,
        active: false,
        value: {
            balance: {},
            id: 0,
            name: '',
            paymentToken: null,
        },
    },
    loading: false,
    paymentMethodSelected: null,
    paymentMethodType: undefined,
    paymentMethodId: '',
    creditCardGuest: null,
    isFormCardValid: false,
    tab: 0,
};

const paymentMethodsSlice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {
        savePaymentMethodSelected: (
            state,
            action: PayloadAction<{
                paymentMethod: CreditCardType | EcheckType | CargoCreditType | SignetCreditType | PaypalType;
                paymentMethodType?: PaymentMethodsType;
                paymentMethodId?: string;
            }>
        ) => {
            state.paymentMethodSelected = action.payload.paymentMethod;
            state.paymentMethodType = action.payload.paymentMethodType;
            state.paymentMethodId = action.payload.paymentMethodId;
        },
        savePaymentMethodsTabs: (state, action: PayloadAction<number>) => {
            state.tab = action.payload;
        },
        resetPaymentMethods: (state) => {
            return {
                ...initialState,
                loading: state.loading,
            };
        },
        isCreditCardGuessFormValid: (state, action: PayloadAction<boolean>) => {
            state.isFormCardValid = action.payload;
        },
        getloading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setCreditCards: (state, action: PayloadAction<PaymentMethods<CreditCardType[]>>) => {
            state.creditCards = action.payload;
        },
        setEchecks: (state, action: PayloadAction<PaymentMethods<EcheckType[]>>) => {
            state.echecks = action.payload;
        },
        setCargoCredit: (state, action: PayloadAction<PaymentMethods<CargoCreditType>>) => {
            state.cargoCredit = action.payload;
        },
        updateCargoCredit: (state, action: PayloadAction<Omit<CargoCreditType, 'paymentToken'>>) => {
            state.cargoCredit.value.creditLimit = action.payload.creditLimit;
            state.cargoCredit.value.avaliableCredit = action.payload.avaliableCredit;
        },
        setSignetCredit: (state, action: PayloadAction<PaymentMethods<SignetCreditType>>) => {
            state.signetCredit = action.payload;
        },
        setPaypal: (state, action: PayloadAction<PaymentMethods<PaypalType>>) => {
            state.paypal = action.payload;
        },
        deleteCreditCardSuccess: (state, action: PayloadAction<CreditCardPaymentTokenType>) => {
            state.creditCards.value = state.creditCards.value.filter(
                (item) => item.paymentToken.token !== action.payload.token
            );
        },
        deleteEchecksSuccess: (state, action: PayloadAction<EcheckType>) => {
            state.echecks.value = state.echecks.value.filter(
                (item) => item.paymentToken.token !== action.payload.paymentToken.token
            );
        },
        paymentSuccess: (state, action: PayloadAction<PaymentSuccessResponseType>) => {
            state.loading = false;
            state.paymentMethodSelected = null;
            state.paymentMethodType = undefined;
            state.paymentMethodId = '';
            state.creditCardGuest = null;
            state.isFormCardValid = false;
            state.tab = 0;
        },
    },
});

export const { actions: paymentMethodsActions } = paymentMethodsSlice;
export const { reducer: paymentMethodsReducer } = paymentMethodsSlice;
