import { Button, GridCell, RadioField } from '@dhl-official/react-ui-library';
import React, { useEffect, useState } from 'react';
import { TitleFormSection } from '../../..';
import { BUTTON_TYPES, BUTTON_VARIANTS, RADIOFIELD_SIZES } from '../../../../types';
import { EcheckType, PaymentMethodsType } from '../../../../types/sprintpay';
import { Description } from '../../description';
import { EchecksListProps } from './list-types';

export const EchecksList = ({
    onPressAdd,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onPressDelete,
    onPressSelect,
    echecks = [],
    paymentMethodSelected,
}: EchecksListProps) => {
    const [idEcheck, setidEcheck] = useState<null | string>(null);

    const submitEcheckSelected = (echeckSelected: EcheckType) => onPressSelect && onPressSelect(echeckSelected || null);

    useEffect(() => {
        if (paymentMethodSelected?.type === PaymentMethodsType.ECHECK) {
            setidEcheck(paymentMethodSelected.id);
        }
    }, [paymentMethodSelected]);

    return (
        <>
            <GridCell
                spanColumns={12}
                className='credit-card-tab__container-top justify-content-end'
                isOverflowHidden={false}
            >
                {!!echecks.length && (
                    <TitleFormSection isParagraph={false} className='credit-card-tab__title'>
                        Select eCheck
                    </TitleFormSection>
                )}
                <Button
                    onClick={onPressAdd}
                    id='add'
                    name='add'
                    variant={BUTTON_VARIANTS.OUTLINE}
                    type={BUTTON_TYPES.BUTTON}
                >
                    Add e-check
                </Button>
            </GridCell>
            <GridCell spanColumns={12} className='credit-card-tab__container-cards' isOverflowHidden={false}>
                {!!echecks.length ? (
                    echecks.map((item, index) => {
                        return (
                            <GridCell
                                key={index}
                                spanColumns={1}
                                isOverflowHidden={false}
                                className='credit-card-tab__credit-card-row'
                            >
                                <RadioField
                                    name='idEcheck'
                                    id={item.paymentToken.token}
                                    value={item.paymentToken.token}
                                    item={item}
                                    isChecked={idEcheck === item.paymentToken.token}
                                    isBlock
                                    onChange={() => submitEcheckSelected(item)}
                                    className='credit-card-tab__radio-credit-card'
                                    size={RADIOFIELD_SIZES.MD}
                                >
                                    {item.accountingDetails.routingNumber}
                                </RadioField>
                            </GridCell>
                        );
                    })
                ) : (
                    <Description>No echecks saved, please add a new one to continue</Description>
                )}
            </GridCell>
        </>
    );
};
