import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './auth/reducers';
import { braintreeReducer } from './braintree/reducers';
import { cartReducer } from './cart/reducers';
import { creditCardReducer } from './creditCard/reducers';
import { customerReducer } from './customer/reducers';
import { echecksReducer } from './echecks/reducers';
import { globalAlertReducer } from './globalAlert/reducers';
import { paymentMethodsReducer } from './paymentsMethods/reducers';
import { uiReducer } from './ui/reducers';

export const appReducer = combineReducers({
    authReducer,
    uiReducer,
    paymentMethodsReducer,
    cartReducer,
    creditCardReducer,
    echecksReducer,
    globalAlertReducer,
    customerReducer,
    braintreeReducer,
});
