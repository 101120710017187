import { ForteException } from '@cargos/sprintpay-models';
import { ErrorsType } from './types';

export const handlerForteError = (err: ForteException): ErrorsType => {
    if (err) {
        return {
            errors: err.response_description
                ? [err.response_description]
                : ['Oops! An unknown error ocurred. Please try again.'],
        };
    }

    return {
        errors: ['Oops! An unknown error ocurred. Please try again.'],
    };
};
