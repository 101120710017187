import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useEffect, useState } from 'react';
import { selectBraintreeClientToken } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getBraintreeClientToken } from '../../../store/actions';
import { PayPalButtonType } from './paypal-button-types';
import { ButtonWrapper } from './paypal-button-wrapper';

const PayPalButton = ({ loadButton, onPressPay, disabled = false }: PayPalButtonType) => {
    const dispatch = useAppDispatch();
    const _clientToken = useAppSelector(selectBraintreeClientToken);
    const [clientToken, setClientToken] = useState('');

    useEffect(() => {
        dispatch(getBraintreeClientToken());
    }, []);

    useEffect(() => {
        setClientToken(_clientToken);
    }, [_clientToken]);

    return (
        <>
            {clientToken && (
                <PayPalScriptProvider
                    options={{
                        'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
                        components: 'buttons',
                        'data-client-token': clientToken,
                        intent: 'capture',
                        vault: false,
                    }}
                    deferLoading={false}
                >
                    {loadButton ? (
                        <ButtonWrapper
                            onPressPay={onPressPay}
                            currency={'USD'}
                            showSpinner={true}
                            disabled={disabled}
                        />
                    ) : (
                        <></>
                    )}
                </PayPalScriptProvider>
            )}
        </>
    );
};

export default PayPalButton;
