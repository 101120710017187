import { BraintreeTokenizePayload } from '@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BraintreeStore } from './types';

const initialState: BraintreeStore = {
    clientToken: '',
    paypalPayload: null,
};

const braintreeSlice = createSlice({
    name: 'braintree',
    initialState,
    reducers: {
        saveBraintreeClientToken: (state, action: PayloadAction<string>) => {
            state.clientToken = action.payload;
        },
        savePaypalPayload: (state, action: PayloadAction<BraintreeTokenizePayload>) => {
            state.paypalPayload = action.payload;
        },
    },
});

export const { actions: braintreeActions } = braintreeSlice;
export const { reducer: braintreeReducer } = braintreeSlice;
