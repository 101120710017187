import { FormikErrors } from 'formik';
import { invoiceNumberPattern } from '../../../helpers';
import { SearchType } from '../search-screen-types';
import { FormSearchValues } from './form-types';

export const validateSearch = (values: FormSearchValues) => {
    const { number, searchType } = values;
    const errors: FormikErrors<FormSearchValues> = {};

    if (!searchType) {
        errors.number = 'Please select invoice number or mawb';
    }

    if (!number) {
        errors.number = searchType === SearchType.invoice_number ? 'Invoice number is required' : 'MAWB is required';
    } else if (searchType === SearchType.invoice_number && !invoiceNumberPattern.test(number)) {
        errors.number = 'Add a valid invoice number';
    } else if (searchType === SearchType.invoice_number) {
        const actualYear = new Date().getFullYear();
        const match = invoiceNumberPattern.exec(number);

        if (match && Number(match[2]) > actualYear) {
            errors.number = 'Add a valid invoice number';
        }
    }

    return errors;
};
