import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../context/authContext/auth-context';
import { selectPasscodeValidation } from '../selectors';
import { useAppSelector } from '../store';

function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const auth = useAuth();

    const isAuthenticated = auth.isAuthenticated();
    const passcodeValidation = useAppSelector(selectPasscodeValidation);

    if (!isAuthenticated || !passcodeValidation.isValidPasscode) {
        return <Navigate to='/' state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth;
