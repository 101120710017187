import { Headline } from '@dhl-official/react-ui-library';
import React from 'react';
import { HEADLINE_COLORS, HEADLINE_FONT_STRETCHES, HEADLINE_LEVELS, HEADLINE_TAGS } from '../../types';

const TitleModal = ({
    fontStretch = HEADLINE_FONT_STRETCHES.NORMAL,
    color = HEADLINE_COLORS.BLACK,
    designLevel = HEADLINE_LEVELS[3],
    tag = HEADLINE_TAGS.H3,
    className = '',
    children,
    ...restProps
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
    return (
        <Headline
            {...restProps}
            fontStretch={fontStretch}
            color={color}
            designLevel={designLevel}
            tag={tag}
            className={className}
        >
            {children}
        </Headline>
    );
};

export default TitleModal;
