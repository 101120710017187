import { GridCell, GridContainer, Image } from '@dhl-official/react-ui-library';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDHL } from '../../components';
import { BUTTON_TYPES, BUTTON_VARIANTS } from '../../types';
import badge from './../../assets/images/badge.png';
import badge_2x from './../../assets/images/badge@2x.png';
import badge_3x from './../../assets/images/badge@3x.png';
import DescriptionMessage from './description';
import PaymentPdf from './payment-pdf';
import TitleMessage from './title-message';

const PaymentSuccessScreen = () => {
    const navigate = useNavigate();

    const onClick = () => navigate('/', { replace: true });

    useEffect(() => {
        window.history.pushState(null, '');

        window.addEventListener('popstate', () => {
            window.history.pushState(null, '');
        });
    }, []);

    return (
        <GridContainer className='payment-success' direction='column' columns={12} rowGap='normal' isFullWidth>
            <GridCell spanColumns={12} className='payment-success__container'>
                <Image
                    src={badge}
                    alt='badge'
                    className='payment-success__icon'
                    sizes={[
                        '(max-width: 320px) 320px',
                        '(max-width:480px) 480px',
                        '(max-width:768px) 768px',
                        '(max-width:1024px) 1024px',
                        '1440px',
                    ]}
                    srcSet={[
                        `${badge} 320w`,
                        `${badge_2x} 480w`,
                        `${badge_2x} 768w`,
                        `${badge_3x} 1024w`,
                        `${badge_3x} 1440w`,
                    ]}
                />
                <GridCell spanColumns={12}>
                    <TitleMessage>
                        Thank you <br />
                        your payment was successful
                    </TitleMessage>
                    <DescriptionMessage className='payment-success__description'>
                        The payment confirmation has been sent to your email
                    </DescriptionMessage>
                    <PaymentPdf />
                    <ButtonDHL
                        onClick={onClick}
                        id='goHome'
                        name='goHome'
                        isBlock
                        variant={BUTTON_VARIANTS.PRIMARY}
                        type={BUTTON_TYPES.BUTTON}
                    >
                        Go to home
                    </ButtonDHL>
                </GridCell>
            </GridCell>
        </GridContainer>
    );
};

export default PaymentSuccessScreen;
