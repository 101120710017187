import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useInterval = (callback: Function, delay: number, firstTime?: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const savedCallback = useRef<any>();
    const [runFirstTime] = useState(firstTime);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };

        if (delay !== null) {
            runFirstTime && tick();
            const id = setInterval(tick, delay);

            return () => clearInterval(id);
        }
    }, [delay, runFirstTime]);
};
