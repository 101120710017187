import { Flex, Text } from '@dhl-official/react-ui-library';
import { FC } from 'react';
import { BUTTON_SIZES, BUTTON_TYPES, BUTTON_VARIANTS, ICON_ORIENTATION, TEXT_SIZES, TEXT_WEIGHTS } from '../../types';
import { ButtonDHL } from '../common/buttonDHL/button';
import { InvoiceNumberSectionProps } from './invoice-number-section-types';

const InvoiceNumberSection: FC<InvoiceNumberSectionProps> = ({
    invoiceNumber,
    containerClassName = '',
    onPressDelete,
    onPressEdit,
    isEditable = true,
}) => {
    return (
        <Flex className={`d-flex mt-2 ${containerClassName}`}>
            <Flex className='flex-grow-1 align-items-center'>
                <Text size={TEXT_SIZES['SM']} weight={TEXT_WEIGHTS[700]}>
                    {invoiceNumber}
                </Text>
            </Flex>
            <div className='d-flex action-button-group'>
                {isEditable && (
                    <>
                        <ButtonDHL
                            dataTestid='buttonText-edit'
                            className='invoice-edit-btn action-button'
                            iconOrientation={ICON_ORIENTATION.RIGHT}
                            size={BUTTON_SIZES.SM}
                            type={BUTTON_TYPES.BUTTON}
                            variant={BUTTON_VARIANTS.TEXT}
                            onClick={onPressEdit}
                        >
                            <Text className='align-self-center' size={TEXT_SIZES['SM']} weight={TEXT_WEIGHTS[400]}>
                                Edit
                            </Text>
                        </ButtonDHL>
                        <Text
                            className='align-self-center line-vertical px-2'
                            size={TEXT_SIZES['SM']}
                            weight={TEXT_WEIGHTS[400]}
                        >
                            |
                        </Text>
                    </>
                )}
                <ButtonDHL
                    dataTestid='buttonText-delete'
                    className='invoice-delete-btn action-button'
                    iconOrientation={ICON_ORIENTATION.RIGHT}
                    size={BUTTON_SIZES.SM}
                    type={BUTTON_TYPES.BUTTON}
                    variant={BUTTON_VARIANTS.TEXT}
                    onClick={onPressDelete}
                >
                    <Text className='align-self-center' size={TEXT_SIZES['SM']} weight={TEXT_WEIGHTS[400]}>
                        Delete
                    </Text>
                </ButtonDHL>
            </div>
        </Flex>
    );
};

export default InvoiceNumberSection;
