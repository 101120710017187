import { GridContainer } from '@dhl-official/react-ui-library';
import React from 'react';
import { CargoCreditType, PaymentMethodsType } from '../../../types/sprintpay';
import { Description } from '../description';
import { SprintPayTabProps } from './bill-tab-types';
import { CargoCredit } from './credit';

export const CargoCreditTab = ({ setPaymentMethodSelected, cargoCredit, paymentMethodSelected }: SprintPayTabProps) => {
    const onSelectCargoCredit = (credit?: CargoCreditType) =>
        setPaymentMethodSelected(credit, PaymentMethodsType.CARGOSPRINT_CREDIT);

    return (
        <GridContainer
            columns={1}
            isFullWidth
            className='d-flex flex-column payment-methods__tab credit-card-tab__container'
        >
            {cargoCredit?.active ? (
                <CargoCredit
                    cargoCredit={cargoCredit?.value}
                    onSelectCredit={onSelectCargoCredit}
                    paymentMethodSelected={paymentMethodSelected}
                />
            ) : (
                <Description>No SprintPay credit available for this account yet</Description>
            )}
        </GridContainer>
    );
};
