import { GridContainer, Loader } from '@dhl-official/react-ui-library';
import { useContext, useEffect, useState } from 'react';
import { GlobalModal } from '../..';
import { BraintreeContext } from '../../../context/braintree/braintree-context';
import { mapFormToCardBraintree } from '../../../helpers';
import { selectCreditCardReducer } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { creditCardActions } from '../../../store/actions';
import { deleteCreditCard, saveCreditCard } from '../../../store/creditCard/actions';
import { CreditCardType, PaymentMethodsType } from '../../../types/sprintpay';
import { IconTypes } from '../../common/modal/types';
import { Description } from '../description';
import { CreditCardTabProps } from './credit-card-tab-types';
import { CreditCardList } from './listCreditCards';
import { CreditCardForm } from './newCreditCardForm';
import { FormCreditCardValues } from './newCreditCardForm/form-types';

export const CreditCardTab = ({
    setPaymentMethodSelected,
    creditCards,
    paymentMethodSelected,
    loading,
}: CreditCardTabProps) => {
    const dispatch = useAppDispatch();
    const [ccFormVisible, setCCFormVisible] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [creditCardMessage, setCreditCardMessage] = useState('');
    const [cardToDelete, setCardToDelete] = useState<CreditCardType>();
    const creditCardReducer = useAppSelector(selectCreditCardReducer);
    const { getPaymentNonce, isBraintreeFormValid } = useContext(BraintreeContext);

    const onPressSelectCard = (card: CreditCardType) => setPaymentMethodSelected(card, PaymentMethodsType.CREDIT_CARD);

    const onPressDeleteCard = (cardToDelete: CreditCardType) => {
        setCardToDelete(cardToDelete);

        setShowConfirmModal(true);
    };

    const onConfirmDeleteCard = () => cardToDelete && dispatch(deleteCreditCard(cardToDelete.paymentToken));

    const _onCloseModal = () => setShowConfirmModal(false);

    const _onCloseResponseModal = async () => {
        setShowResponseModal(false);

        setTimeout(() => {
            dispatch(creditCardActions.resetCreditCardHandler());
        }, 250);
    };

    const onSubmit = (form: FormCreditCardValues) => {
        const card = mapFormToCardBraintree(form);
        if (isBraintreeFormValid) {
            getPaymentNonce(card).then((paymentNonce) => {
                if (paymentNonce) {
                    dispatch(saveCreditCard(paymentNonce));
                }
            });
        }
    };

    useEffect(() => {
        if (creditCardReducer.deleted || creditCardReducer.added) {
            const message = creditCardReducer.deleted
                ? 'Your credit card has been deleted'
                : creditCardReducer.added
                ? 'Your credit card has been added'
                : '';

            setCreditCardMessage(message);

            setCCFormVisible(false);

            _onCloseModal();

            setShowResponseModal(true);
        }
    }, [creditCardReducer.deleted, creditCardReducer.added]);

    useEffect(() => {
        if (creditCardReducer.errors?.errors.length) {
            const message = creditCardReducer.errors?.errors
                ? creditCardReducer.errors.errors[0]
                : 'Oops! An unknown error ocurred. Please try again.';

            setCreditCardMessage(message);

            _onCloseModal();

            setShowResponseModal(true);
        }
    }, [creditCardReducer.errors]);

    return (
        <GridContainer
            columns={1}
            isFullWidth
            className='d-flex flex-column payment-methods__tab credit-card-tab__container'
        >
            {loading ? (
                <Loader />
            ) : (
                <>
                    {!ccFormVisible ? (
                        <>
                            {creditCards?.active ? (
                                <CreditCardList
                                    creditCards={creditCards?.value}
                                    onPressSelect={onPressSelectCard}
                                    onPressDelete={onPressDeleteCard}
                                    onPressAdd={() => setCCFormVisible(true)}
                                    paymentMethodSelected={paymentMethodSelected}
                                />
                            ) : (
                                <Description>This payment method is not available</Description>
                            )}
                        </>
                    ) : (
                        <CreditCardForm
                            onSubmit={onSubmit}
                            onPressCancel={() => setCCFormVisible(false)}
                            loading={creditCardReducer.loading}
                        />
                    )}
                    <GlobalModal
                        isOpen={showConfirmModal}
                        loading={creditCardReducer.deleting}
                        title='Are you sure you want to delete this payment method?'
                        description='If you delete this payment method, you will not be able to make any transactions with it, unless you add it again.'
                        notes={`**** **** **** ${cardToDelete?.accountingDetails.lastFourDigits}`}
                        onPressLeftButton={onConfirmDeleteCard}
                        leftButtonText='Delete payment method'
                        leftButtonLoading={true}
                        rightButtonText='Cancel'
                        onCloseModal={_onCloseModal}
                    />
                    <GlobalModal
                        isOpen={showResponseModal}
                        title={creditCardMessage}
                        rightButtonText='OK'
                        iconType={
                            (creditCardReducer.deleted || creditCardReducer.added) &&
                            !creditCardReducer.errors?.errors.length
                                ? IconTypes.IconCheckmarkCircle
                                : IconTypes.IconCancelCircle
                        }
                        onCloseModal={_onCloseResponseModal}
                        isStatic={false}
                    />
                </>
            )}
        </GridContainer>
    );
};
