import { ButtonGroup, Modal } from '@dhl-official/react-ui-library';
import { ButtonDHL } from '../..';
import { COLORS } from '../../../styles/abstracts/colors';
import { ALIGNMENTS, BUTTONGROUP_ORIENTATION, BUTTON_SIZES, BUTTON_VARIANTS, TEXT_SIZES } from '../../../types';
import DescriptionModal from './description';
import { IconModal } from './icon';
import InfoModal from './info';
import TitleModal from './title';
import { GlobalModalProps, IconTypes } from './types';

export const GlobalModal = ({
    loading = false,
    isOpen,
    onCloseModal,
    contentAriaLabel = 'Confirm Modal',
    title,
    description,
    descriptionSize = TEXT_SIZES['MD'],
    notes,
    onPressRightButton,
    rightButtonText = '',
    rightButtonVariant = BUTTON_VARIANTS.OUTLINE,
    rightButtonDisabled = false,
    rightButtonLoading = false,
    onPressLeftButton,
    leftButtonText = '',
    lefttButtonVariant = BUTTON_VARIANTS.PRIMARY,
    leftButtonDisabled = false,
    leftButtonLoading = false,
    buttonGroupSize = BUTTON_SIZES.MD,
    buttonGroupOrientation = BUTTONGROUP_ORIENTATION.HORIZONTAL,
    buttonGroupOrder = ALIGNMENTS.LEFT,
    iconType = IconTypes.IconCancelCircle,
    showIcon = true,
    shouldShowCloseButton = true,
    isStatic = true,
    component,
}: GlobalModalProps) => {
    const _closeModal = () => {
        !loading && onCloseModal && onCloseModal();
    };

    const getButtons = () => {
        return (
            <>
                {leftButtonText && (
                    <ButtonDHL
                        loading={leftButtonLoading && loading}
                        variant={lefttButtonVariant}
                        onClick={onPressLeftButton || _closeModal}
                        isDisabled={leftButtonDisabled}
                        showLoadingIcon
                    >
                        {leftButtonText}
                    </ButtonDHL>
                )}
                <div style={{ width: 10 }} />
                {rightButtonText && (
                    <ButtonDHL
                        loading={rightButtonLoading && loading}
                        variant={rightButtonVariant}
                        onClick={onPressRightButton || _closeModal}
                        isDisabled={rightButtonDisabled}
                        showLoadingIcon
                    >
                        {rightButtonText}
                    </ButtonDHL>
                )}
            </>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            closeButtonAriaLabel='Closes the Modal'
            contentAriaLabel={contentAriaLabel}
            onClose={_closeModal}
            shouldShowCloseButton={shouldShowCloseButton}
            isStatic={isStatic}
            className='global-modal__container'
            overlayColor={COLORS['black-20']}
        >
            {component ? (
                component
            ) : (
                <div className='global-modal'>
                    {showIcon && <IconModal iconType={iconType} />}
                    <div className='global-modal__content'>
                        {!!title && <TitleModal>{title}</TitleModal>}
                        {!!description && <DescriptionModal size={descriptionSize}>{description}</DescriptionModal>}
                        {!!notes && <InfoModal>{notes}</InfoModal>}
                        {(rightButtonText || leftButtonText) && (
                            <ButtonGroup
                                size={buttonGroupSize}
                                orientation={buttonGroupOrientation}
                                ariaLabel='Choose an option'
                                dataTestid='sb'
                                isBlock
                                className={`mt-4 global-modal__button-group
                            ${buttonGroupOrder === ALIGNMENTS.LEFT && 'justify-content-end'}
                            ${buttonGroupOrder === ALIGNMENTS.RIGHT && 'justify-content-start'}
                            ${buttonGroupOrder === ALIGNMENTS.CENTER && 'justify-content-center'}`}
                            >
                                {getButtons()}
                            </ButtonGroup>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
};
