import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppShellScreen from '../pages/appShell/app-shell';
import LayoutWithNavbar from '../pages/layoutWithNavbar/layout-with-navbar';
import PaymentSuccessScreen from '../pages/paymentSuccess/payment-success-screen';
import { SearchScreen } from '../pages/search/search-screen';
import { SearchCustomerScreen } from '../pages/searchCustomer/search-customer-screen';
import { ShipmentDetailsScreen } from '../pages/shipmetDetails/shipment-details-screen';
import RequireAuth from './private-route';

const LoginSprintPayScreen = lazy(() => import('../pages/loginSprintPay/login-screen'));
const CartScreen = lazy(() => import('../pages/cart/cart-screen'));

export const AppRoutes = () => {
    return (
        <div>
            <Suspense fallback={<AppShellScreen />}>
                <Routes>
                    <Route path='/' element={<SearchCustomerScreen />} />
                    <Route element={<LayoutWithNavbar />}>
                        <Route
                            path='/customer/:customerNumber'
                            element={
                                <RequireAuth>
                                    <SearchScreen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path='/customer/:customerNumber/search'
                            element={
                                <RequireAuth>
                                    <ShipmentDetailsScreen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path='/cart'
                            element={
                                <RequireAuth>
                                    <CartScreen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path='/paymentSuccessful'
                            element={
                                <RequireAuth>
                                    <PaymentSuccessScreen />
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route path='/auth/login' element={<LoginSprintPayScreen />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </Suspense>
        </div>
    );
};
