import { Image } from '@dhl-official/react-ui-library';
import logo_dhl from './../../assets/images/logo_dhl.png';

const Header = () => (
    <header className='header__container'>
        <Image src={logo_dhl} alt='Logo' className='header__logo' />
    </header>
);

export default Header;
