export interface CreditCardDetailsType {
    cardHolderName: string;
    cardType: TypesCrediCard;
    expirationMonth: string;
    expirationYear: string;
    lastFourDigits: string;
}

export interface CreditCardPaymentTokenType {
    token: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentMethod: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentProcessor: any;
}

export interface CreditCardType {
    accountingDetails: CreditCardDetailsType;
    address: string;
    paymentToken: CreditCardPaymentTokenType;
}

export enum CreditCardsTypes {
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    AMERICANEXPRESS = 'AMERICAN EXPRESS',
    DISCOVER = 'DISCOVER',
}

export type TypesCrediCard = keyof typeof CreditCardsTypes;

export interface CreditCardParameters {
    billingAddress: {
        postalCode: string;
        streetAddress: string;
        state: string;
        city: string;
        countryName: string;
    };
}

export type InvoiceNumber = string;

export type MAWB = string;
