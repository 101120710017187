import { createAsyncThunk } from '@reduxjs/toolkit';
import { finalize } from 'rxjs/operators';
import * as Requests from '../../api/request/card-request';
import { CreditCardPaymentTokenType } from '../../types/sprintpay';
import { getPaymentMethods, handlerErrors, paymentMethodsActions } from '../actions';
import { creditCardActions } from './reducers';

export const deleteCreditCard = createAsyncThunk<void, CreditCardPaymentTokenType>(
    'creditCard/deleteCreditCard',
    async (card, { dispatch }) => {
        try {
            dispatch(creditCardActions.deletingCard(true));

            const response = Requests.deleteCreditCard(card)
                .pipe(
                    finalize(() => {
                        response && response.unsubscribe();
                    })
                )
                .subscribe(
                    (data) => {
                        if (data) {
                            dispatch(creditCardActions.deleteCreditCardSuccess(card));
                            dispatch(paymentMethodsActions.deleteCreditCardSuccess(card));
                        }
                    },
                    async (error: unknown) => {
                        const errors = handlerErrors(error, dispatch);

                        dispatch(creditCardActions.deleteCreditCardError(errors));
                    }
                );
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(creditCardActions.deleteCreditCardError(errors));
        }
    }
);

export const saveCreditCard = createAsyncThunk<void, string>(
    'creditCard/saveCreditCard',
    async (paymentNonce, { dispatch }) => {
        try {
            dispatch(creditCardActions.getCardsLoading(true));
            await Requests.saveCreditCard(paymentNonce);
            dispatch(creditCardActions.saveCreditCardSuccess(true));
            dispatch(getPaymentMethods(false));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(creditCardActions.saveCreditCardError(errors));
        }
    }
);
