import { ShipmentDetailsType } from '../../store/customer/types';
import { RequestAuthToken } from '../config';
import * as Url from '../urls';

export const searchInvoiceNumber = async (
    customerNumber: string,
    invoiceNumber: string
): Promise<{
    content: ShipmentDetailsType[];
    success: boolean;
}> => {
    const { data } = await RequestAuthToken({
        url: `${Url.getDHLInvoice}/?customer_number=${customerNumber}&invoice_number=${invoiceNumber}`,
        method: 'GET',
    });

    return data;
};

export const searchAWBNumber = async (customerNumber: string, awbNumber: string) => {
    const { data } = await RequestAuthToken({
        url: `${Url.getDHLAWB}/?mawb=${awbNumber}&customer_number=${customerNumber}`,
        method: 'GET',
    });

    return data;
};

export const downloadInvoiceDocument = async (invoiceNumber: string) => {
    const { data } = await RequestAuthToken({
        url: `${Url.downloadInvoiceDocument}/${invoiceNumber}`,
        method: 'GET',
        responseType: 'blob',
    });

    return data;
};

export const invoiceSubscribe = async (invoiceNumber: string, emails: string) => {
    const { data } = await RequestAuthToken({
        url: `${Url.invoiceSubscribe}?invoice_number=${invoiceNumber}&emails=${emails}`,
        method: 'POST',
    });

    return data;
};
