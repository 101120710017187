import { Button, Loader } from '@dhl-official/react-ui-library';
import { LOADER_VARIANTS } from '../../../types';
import { ButtonDHLProps } from './button-types';

export const ButtonDHL = ({
    children,
    loading = false,
    showLoadingIcon = false,
    showLoadingText = true,
    loaderVariant = LOADER_VARIANTS.PRIMARY,
    isRound = true,
    ...rest
}: ButtonDHLProps) => {
    return (
        <Button {...rest}>
            {!loading ? (
                children
            ) : (
                <>
                    {showLoadingText && <>Loading</>}
                    {showLoadingText && showLoadingIcon && <>&nbsp; &nbsp; &nbsp;</>}
                    {showLoadingIcon && <Loader variant={loaderVariant} />}
                </>
            )}
        </Button>
    );
};
