import { LEVELS } from '../constants';

export {
    ALIGNMENTS as HEADLINE_ALIGNMENTS,
    COLORS as HEADLINE_COLORS,
    STRETCH as HEADLINE_FONT_STRETCHES,
    TAGS as HEADLINE_TAGS,
    TEXT_TRANSFORMS as HEADLINE_TEXT_TRANSFORMS,
    FONT_VARIANTS as HEADLINE_VARIANTS,
} from '../constants';

export const HEADLINE_LEVELS = LEVELS;
