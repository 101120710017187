import { Headline, Image } from '@dhl-official/react-ui-library';
import { HEADLINE_ALIGNMENTS, HEADLINE_COLORS, HEADLINE_LEVELS, HEADLINE_TAGS } from '../../../types';
import dhl_aviation from './../../../assets/images/dhl_aviation.png';
import dhl_aviation_2x from './../../../assets/images/dhl_aviation@2x.png';
import dhl_aviation_3x from './../../../assets/images/dhl_aviation@3x.png';

const HeaderForm = () => (
    <>
        <Image
            src={dhl_aviation}
            alt='DHL_Logo'
            className='search-customer__dhl-aviation'
            sizes={[
                '(max-width: 320px) 320px',
                '(max-width:480px) 480px',
                '(max-width:768px) 768px',
                '(max-width:1024px) 1024px',
                '1440px',
            ]}
            srcSet={[
                `${dhl_aviation} 320w`,
                `${dhl_aviation_2x} 480w`,
                `${dhl_aviation_2x} 768w`,
                `${dhl_aviation_3x} 1024w`,
                `${dhl_aviation_3x} 1440w`,
            ]}
        />
        <Headline
            className='sb-class search-customer__title-welcome'
            dataTestid='sb'
            color={HEADLINE_COLORS.RED}
            tag={HEADLINE_TAGS.H1}
            designLevel={HEADLINE_LEVELS[1]}
            textAlign={HEADLINE_ALIGNMENTS.CENTER}
        >
            Welcome to our <br className='search-customer__line-break' /> payment portal
        </Headline>
    </>
);

export default HeaderForm;
