import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { login, loginAsGuest, refreshToken, validateCustomerEmail, validatePasscode } from '../actions';
import { AuthStore, SessionType } from './types';

const initialState: AuthStore = {
    logged: false,
    loading: false,
    errors: null,
    token: '',
    refreshToken: '',
    expiresAt: null,
    currentT: null,
    userInfo: null,
    tokens: null,
    forceLogout: false,
    authorizationTokenId: null,
    isValidEmail: null,
    customerNumberApproved: null,
    validatingEmail: false,
    mfaUuId: null,
    isValidPasscode: null,
    validatingPasscode: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        sessionSuccess: (state, action: PayloadAction<SessionType>) => {
            state.loading = false;
            state.errors = null;
            state.logged = true;
            state.token = action.payload.authTokens.authorizationToken;
            state.refreshToken = action.payload.authTokens.refreshToken;
            state.userInfo = action.payload.userInfo;
            state.userType = action.payload.userType;
            state.expiresAt = action.payload.expiresAt;
            state.currentT = action.payload.currentT;
            state.tokens = action.payload.authTokens;
            state.authorizationTokenId = action.payload.authorizationTokenId;
        },
        forceLogout: (state, action: PayloadAction<boolean>) => {
            state.forceLogout = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        sessionLogout() {},
        emailValidationSuccess: (state, action: PayloadAction<boolean>) => {
            state.isValidEmail = action.payload;
        },
        resetEmailValidation: (state) => {
            state.isValidEmail = null;
            state.validatingEmail = false;
            state.mfaUuId = null;
            state.errors = null;
            state.isValidPasscode = null;
            state.validatingPasscode = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.errors = null;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            authSlice.caseReducers.sessionSuccess(state, action);
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });

        builder.addCase(loginAsGuest.pending, (state) => {
            authSlice.caseReducers.sessionLogout();
            state.loading = true;
            state.errors = null;
        });
        builder.addCase(loginAsGuest.fulfilled, (state, action) => {
            state.loading = false;
            authSlice.caseReducers.sessionSuccess(state, action);
        });
        builder.addCase(loginAsGuest.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });

        builder.addCase(refreshToken.pending, (state) => {
            state.loading = true;
            state.errors = null;
        });
        builder.addCase(refreshToken.fulfilled, (state, action) => {
            state.loading = false;
            authSlice.caseReducers.sessionSuccess(state, action);
        });
        builder.addCase(refreshToken.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });

        builder.addCase(validateCustomerEmail.pending, (state) => {
            state.validatingEmail = true;
            state.isValidEmail = null;
            state.customerNumberApproved = null;
            state.mfaUuId = null;
            state.errors = null;
        });
        builder.addCase(validateCustomerEmail.fulfilled, (state, action) => {
            state.validatingEmail = false;
            state.isValidEmail = action.payload.success;
            state.customerNumberApproved = action.payload.content;
            state.mfaUuId = action.payload.mfaUuid;
            state.errors = null;
        });
        builder.addCase(validateCustomerEmail.rejected, (state, action) => {
            state.validatingEmail = false;
            state.isValidEmail = false;
            state.customerNumberApproved = null;
            state.mfaUuId = null;
            state.errors = action.payload;
        });

        builder.addCase(validatePasscode.pending, (state) => {
            state.validatingPasscode = true;
            state.isValidPasscode = null;
            state.errors = null;
        });
        builder.addCase(validatePasscode.fulfilled, (state, action) => {
            state.validatingPasscode = false;
            state.isValidPasscode = action.payload;
            state.errors = null;
        });
        builder.addCase(validatePasscode.rejected, (state, action) => {
            state.validatingPasscode = false;
            state.isValidPasscode = false;
            state.errors = action.payload;
        });
    },
});

export const { actions: authActions } = authSlice;
export const { reducer: authReducer } = authSlice;
