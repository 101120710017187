import { Center, Flex, Loader, Tab, Tabs } from '@dhl-official/react-ui-library';
import { useCallback, useEffect, useState } from 'react';
import { selectPaymentMethods } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getPaymentMethods, paymentMethodsActions } from '../../../store/actions';
import { TAB_VARIANTS } from '../../../types';
import {
    CargoCreditType,
    CreditCardType,
    EcheckType,
    PaymentMethodsType,
    PaypalType,
    SignetCreditType,
} from '../../../types/sprintpay';
import { CreditCardGuestTab } from '../creditCardGuest/credit-card-guest-tab';
import { PaypalTab } from '../paypal/paypal-tab';
import { TabGuestProps } from './tabs-guest-types';

export const PaymentMethodsTabsGuest = ({ getPaymentMethodSelected, onSubmit, getStatus, innerRef }: TabGuestProps) => {
    const dispatch = useAppDispatch();
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const [tab, setTab] = useState(0);
    const [tabsMethods, setTabsMethods] = useState<
        {
            tab: number;
            type: PaymentMethodsType;
        }[]
    >([]);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState<{
        paymentMethod: CreditCardType | EcheckType | CargoCreditType | SignetCreditType | null;
        type?: PaymentMethodsType | null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        id?: any;
    }>({
        paymentMethod: paymentMethods.paymentMethodSelected,
        type: paymentMethods.paymentMethodType,
        id: paymentMethods.paymentMethodId,
    });

    const _getPaymentMethodSelected = useCallback(
        (
            paymentMethod: CreditCardType | EcheckType | CargoCreditType | SignetCreditType | PaypalType,
            type: PaymentMethodsType
        ) => {
            const id = type === PaymentMethodsType.CREDIT_CARD ? paymentMethod : paymentMethod.paymentToken;

            dispatch(
                paymentMethodsActions.savePaymentMethodSelected({
                    paymentMethod,
                    paymentMethodType: type,
                    paymentMethodId: id,
                })
            );

            getPaymentMethodSelected && getPaymentMethodSelected(paymentMethod, type);
        },
        [dispatch, getPaymentMethodSelected]
    );

    useEffect(() => {
        dispatch(getPaymentMethods(true));
    }, []);

    useEffect(() => {
        setPaymentMethodSelected({
            paymentMethod: paymentMethods.paymentMethodSelected,
            type: paymentMethods.paymentMethodType,
            id: paymentMethods.paymentMethodId,
        });
    }, [paymentMethods.paymentMethodSelected, paymentMethods.paymentMethodType, paymentMethods.paymentMethodId]);

    useEffect(() => {
        const methodSelected = tabsMethods.find((elem) => elem.type === paymentMethods.paymentMethodType);

        if (methodSelected) {
            setTab(methodSelected.tab);
        } else {
            if (paymentMethods.tab === 0) {
                selectCreditCardAsPayment();
            }
            setTab(paymentMethods.tab);
        }
    }, [paymentMethodSelected]);

    useEffect(() => {
        let index = -1;
        setTabsMethods([]);

        if (paymentMethods.creditCards.active) {
            index = index + 1;
            const c = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: c,
                    type: PaymentMethodsType.CREDIT_CARD,
                },
            ]);
        }
        if (paymentMethods.paypal && paymentMethods.paypal.active) {
            index = index + 1;
            const p = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: p,
                    type: PaymentMethodsType.PAYPAL,
                },
            ]);
        }
    }, [paymentMethods.paymentMethodId]);

    const onChange = (tab: number) => {
        if (tab === 0) {
            selectCreditCardAsPayment();
        }
    };

    const selectCreditCardAsPayment = () => {
        dispatch(
            paymentMethodsActions.savePaymentMethodSelected({
                paymentMethod: null,
                paymentMethodType: PaymentMethodsType.CREDIT_CARD,
                paymentMethodId: null,
            })
        );
    };

    return (
        <>
            {!paymentMethods.loading ? (
                <Tabs variant={TAB_VARIANTS.UNDERLINE} initialActiveIndex={tab} onChange={onChange}>
                    <Tab label='Credit Card' key={PaymentMethodsType.CREDIT_CARD}>
                        <CreditCardGuestTab
                            creditCard={paymentMethods.creditCards}
                            onSubmit={onSubmit}
                            getFormStatus={getStatus}
                            innerRef={innerRef}
                        />
                    </Tab>
                    <Tab label='Paypal' key={PaymentMethodsType.PAYPAL}>
                        <PaypalTab
                            paypalCredit={paymentMethods.paypal}
                            setPaymentMethodSelected={_getPaymentMethodSelected}
                            paymentMethodSelected={paymentMethodSelected}
                        />
                    </Tab>
                </Tabs>
            ) : (
                <Flex>
                    <Center>
                        <Loader />
                    </Center>
                </Flex>
            )}
        </>
    );
};
