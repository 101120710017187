import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EcheckType } from '../../types/sprintpay';
import { ErrorsType } from '../errors/types';
import { PaymentMethods } from '../paymentsMethods/types';
import { EchecksStore } from './types';

const initialState: EchecksStore = {
    echecks: [],
    deleting: false,
    deleted: false,
    loading: false,
    uploading: false,
    added: false,
    errors: null,
};

const echecksSlice = createSlice({
    name: 'echecks',
    initialState,
    reducers: {
        echecksLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
            state.added = false;
            state.errors = null;
        },
        getEcheckUploading: (state, action: PayloadAction<boolean>) => {
            state.uploading = action.payload;
        },
        getEchecksSuccess: (state, action: PayloadAction<PaymentMethods<EcheckType[]>>) => {
            state.echecks = action.payload.value;
            state.loading = false;
        },
        deletingEcheck: (state, action: PayloadAction<boolean>) => {
            state.deleting = true;
            state.deleted = false;
            state.errors = null;
        },
        deleteEchecksSuccess: (state, action: PayloadAction<EcheckType>) => {
            state.deleted = true;
            state.deleting = false;
            state.loading = false;
            state.errors = null;
        },
        deleteEcheckError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.deleting = false;
            state.loading = false;
            state.errors = action.payload;
        },
        getEchecksError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.loading = false;
            state.uploading = false;
            state.errors = action.payload;
        },
        saveEcheckSuccess: (state, action: PayloadAction<boolean>) => {
            state.added = action.payload;
            state.loading = false;
            state.errors = null;
        },
        saveEcheckError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.errors = action.payload;
            state.loading = false;
        },
        resetEchecksHandler: () => {
            return { ...initialState };
        },
    },
});

export const { actions: echecksActions } = echecksSlice;
export const { reducer: echecksReducer } = echecksSlice;
