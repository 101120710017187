export const addZeroes = (num: string | number) => {
    if (num) {
        let n = typeof num == 'number' ? num.toString() : num;
        n = n?.replace(',', '');
        const dec = n?.split('.')[1];
        const len = dec && dec.length > 2 ? dec.length : 2;

        return Number(n).toFixed(len);
    }

    return '0';
};
