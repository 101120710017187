import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import * as Requests from '../../api/request/customer-request';
import { DHLPaymentRequestType, PaymentRequestType } from '../../types/sprintpay';
import { customerActions, handlerErrors } from '../actions';
import {
    InvoiceSubscribeThunkProps,
    InvoicesType,
    SearchAWBNumberThunkProps,
    SearchCreditNoteThunkProps,
    SearchEditableInvoicesThunkProps,
    SearchInvoiceNumberThunkProps,
    UpdatePaymentRequestsThunkProps,
} from './types';

export const searchInvoiceNumber = createAsyncThunk<void, SearchInvoiceNumberThunkProps>(
    'customer/searchInvoiceNumber',
    async (props, { dispatch }) => {
        const { customerNumber, invoiceNumber } = props;
        try {
            dispatch(customerActions.customerSearchLoading(true));

            const response = await Requests.searchInvoiceNumber(customerNumber, invoiceNumber);

            response.content[0]?.list_invoices.forEach((item) => {
                item.added = true;
                item.amount = (Number(item.total_net) - Number(item.paid_amount) || 0).toString();
            });

            if (response.content[0]?.list_invoices) {
                response.content[0].list_invoices = _.orderBy(response.content[0]?.list_invoices, ['mawb'], ['asc']);
            }

            dispatch(customerActions.searchNumberSuccess(response.content[0]));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(customerActions.searchNumberError(errors));
        }
    }
);

export const searchEditableInvoices = createAsyncThunk<void, SearchEditableInvoicesThunkProps>(
    'customer/searchEditableInvoices',
    async (props, { dispatch }) => {
        const { data, dhlPaymentRequest } = props;
        let feeContentsArr: PaymentRequestType[] = [];

        dhlPaymentRequest.map((data: DHLPaymentRequestType) => {
            feeContentsArr = feeContentsArr.concat(data.fee_content);
            return feeContentsArr;
        });

        try {
            dispatch(customerActions.searchingEditableInvoicesRequest(true));

            if (Array.isArray(data)) {
                const arrayPromises = data.map(async (element) => {
                    const response = await Requests.searchAWBNumber(element.customerNumber, element.awbNumber);

                    response.content[0]?.list_invoices.forEach((item) => {
                        const existing = feeContentsArr.filter((f) => f.awb === item.mawb && f.amount > 0);

                        item.added = existing.length > 0;
                        item.deleted = !(existing.length > 0);
                        item.amount =
                            existing[0]?.amount.toString() ||
                            (Number(item.total_net) - Number(item.paid_amount) || 0).toString();

                        return item;
                    });

                    if (response.content[0]?.list_invoices) {
                        response.content[0].list_invoices = _.orderBy(
                            response.content[0]?.list_invoices,
                            ['mawb'],
                            ['asc']
                        );
                    }
                    return response.content[0];
                });

                const result = await Promise.all(arrayPromises);

                dispatch(customerActions.searchEditableInvoicesSuccess(result));

                dispatch(customerActions.searchingEditableInvoicesRequest(false));
            }
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(customerActions.searchNumberError(errors));
        }
    }
);

export const searchAWBNumber = createAsyncThunk<void, SearchAWBNumberThunkProps>(
    'customer/searchAWBNumber',
    async (props, { dispatch }) => {
        const { customerNumber, awbNumber } = props;
        try {
            dispatch(customerActions.customerSearchLoading(true));

            const response = await Requests.searchAWBNumber(customerNumber, awbNumber);

            response.content[0]?.list_invoices.forEach((item: InvoicesType) => {
                item.added = true;
                item.amount = (Number(item.total_net) - Number(item.paid_amount) || 0).toString();
            });

            if (response.content[0]?.list_invoices) {
                response.content[0].list_invoices = _.orderBy(response.content[0]?.list_invoices, ['mawb'], ['asc']);
            }

            dispatch(customerActions.searchNumberSuccess(response.content[0]));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(customerActions.searchNumberError(errors));
        }
    }
);

export const searchCreditNote = createAsyncThunk<void, SearchCreditNoteThunkProps>(
    'customer/searchCreditNote',
    async (props, { dispatch }) => {
        const { customerNumber, creditNote } = props;
        try {
            dispatch(customerActions.searchCreditNoteLoading(true));

            const response = await Requests.searchInvoiceNumber(customerNumber, creditNote);

            response.content[0]?.list_invoices.forEach((item: InvoicesType) => (item.added = true));

            dispatch(customerActions.searchCreditNoteSuccess(response.content[0]));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(customerActions.searchCreditNoteError(errors));
        }
    }
);

export const invoiceSubscribe = createAsyncThunk<void, InvoiceSubscribeThunkProps>(
    'customer/invoiceSubscribe',
    async (props, { dispatch }) => {
        const { invoiceNumber, emails } = props;
        try {
            dispatch(customerActions.subscribing(true));

            const response = await Requests.invoiceSubscribe(invoiceNumber, emails);
            const email = response?.content[0]?.emails[response?.content[0]?.emails.length - 1];

            dispatch(customerActions.invoiceSubscribeSuccess(email));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(customerActions.invoiceSubscribeError(errors));
        }
    }
);

export const updatePaymentRequests = createAsyncThunk<void, UpdatePaymentRequestsThunkProps>(
    'customer/updatePaymentRequests',
    async (props, { dispatch }) => {
        dispatch(customerActions.updateInvoiceAmountLoading(true));

        dispatch(customerActions.updatePaymentRequestsSuccess(props));

        dispatch(customerActions.updateInvoiceAmountLoading(false));
    }
);

export const deleteDHLInvoiceEditCartRequest = createAsyncThunk<void, string>(
    'customer/deleteDHLInvoiceEditCartRequest',
    async (IATA_code, { dispatch }) => {
        dispatch(customerActions.updateInvoiceAmountLoading(true));

        dispatch(customerActions.deleteDHLInvoiceEditCartSuccess(IATA_code));

        dispatch(customerActions.updateInvoiceAmountLoading(false));
    }
);
