export enum SIZES {
    '2XS' = '0.625rem',
    XS = '0.75rem',
    SM = '0.875rem',
    MD = '1rem',
    LG = '1.125rem',
    XL = '1.25rem',
    '2XL' = '1.375rem',
    '3XL' = '1.5rem',
    '4XL' = '1.75rem',
    '5XL' = '1.875rem',
    '6XL' = '2rem',
    '7XL' = '2.125rem',
    '8XL' = '2.25rem',
    '9XL' = '2.5rem',
    '10XL' = '2.625rem',
    '11XL' = '3rem',
}

export enum STRETCH {
    NORMAL = 'normal',
    CONDENSED = 'condensed',
}

export enum STYLES {
    NORMAL = 'normal',
    ITALIC = 'italic',
}

export const WEIGHTS = Object.freeze({
    200: 200,
    400: 400,
    700: 700,
    800: 800,
} as const);

export enum ALIGNMENTS {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum TAGS {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    P = 'p',
}

export enum COLORS {
    BLACK = 'black',
    DEFAULT = 'default',
    RED = 'red',
    WHITE = 'white',
    HEXRED = '#D40511',
}

export const LEVELS = Object.freeze({
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
} as const);

export enum TEXT_TRANSFORMS {
    NONE = 'none',
    UPPERCASE = 'uppercase',
    LOWERCASE = 'lowercase',
    CAPITALIZE = 'capitalize',
}

export enum FONT_VARIANTS {
    LIGHT = 'light',
    BLACK = 'black',
}

export enum PLACEMENTS {
    AUTO = 'auto',
    AUTO_START = 'auto-start',
    AUTO_END = 'auto-end',
    TOP = 'top',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    BOTTOM = 'bottom',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    RIGHT = 'right',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    LEFT = 'left',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end',
}

export enum VALIDATION_TYPES {
    VALID = 'valid',
    INVALID = 'invalid',
    NOTE = 'note',
}

export enum TYPES {
    ANIMATED = 'animated',
    STATIC = 'static',
}

export enum LABEL_TYPES {
    EMAIL = 'email',
    NUMBER = 'number',
    NUMERIC = 'numeric',
    PASSWORD = 'password',
    TELEPHONE = 'telephone',
    TEXT = 'text',
    DATE = 'date',
}

export enum TRIGGERS {
    CLICK = 'click',
    HOVER = 'hover',
}
