import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ErrorsType } from '../errors/types';
import { CustomerStore, ShipmentDetailsType, UpdatePaymentRequestsThunkProps } from './types';

const initialState: CustomerStore = {
    loading: false,
    errors: null,
    customer: null,
    customerNumber: null,
    search: null,
    searchedList: [],
    editableInvoicesList: [],
    creditNotes: null,
    creditNotesErrors: null,
    subscripting: false,
    invoiceSubscribeSuccess: false,
    invoiceSubscribeError: false,
    invoiceSubscribeEmail: '',
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        updatePaymentRequestsSuccess: (state, action: PayloadAction<UpdatePaymentRequestsThunkProps>) => {
            if (state.search) {
                state.search.list_invoices = [
                    ...state.search?.list_invoices.slice(0, action.payload.index),
                    {
                        ...state.search?.list_invoices[action.payload.index],
                        [`${action.payload.property}`]: action.payload.value,
                    },
                    ...state.search?.list_invoices.slice(action.payload.index + 1),
                ];
            }
        },
        updateEditablePaymentsRequests: (state, action: PayloadAction<UpdatePaymentRequestsThunkProps>) => {
            const index = state.editableInvoicesList.map((item) => item.IATA_code).indexOf(action.payload.IATA_code);
            const focusedSearch = state.editableInvoicesList[index];

            const updatedFocusedSearch = focusedSearch && {
                ...focusedSearch,
                list_invoices: [
                    ...focusedSearch?.list_invoices.slice(0, action.payload.index),
                    {
                        ...focusedSearch?.list_invoices[action.payload.index],
                        [`${action.payload.property}`]: action.payload.value,
                    },
                    ...focusedSearch?.list_invoices.slice(action.payload.index + 1),
                ],
            };
            state.editableInvoicesList[index] = updatedFocusedSearch;
            state.editableInvoicesList = [...state.editableInvoicesList];
        },
        deleteDHLInvoiceEditCartSuccess: (state, action: PayloadAction<string>) => {
            state.searchedList = state.searchedList.filter((item) => item.IATA_code !== action.payload);
        },
        resetSearchErrors: (state) => {
            state.errors = null;
        },
        customerSearchLoading: (state, action: PayloadAction<boolean>) => {
            state.errors = null;
            state.loading = action.payload;
        },
        setCustomerNumber: (state, action: PayloadAction<string>) => {
            state.customerNumber = action.payload;
        },
        resetCustomerSearch: (state) => {
            state.customer = null;
            state.errors = null;
        },
        searchNumberSuccess: (state, action: PayloadAction<ShipmentDetailsType>) => {
            state.loading = false;
            state.errors = null;
            state.search = action.payload;
            state.searchedList.push(action.payload);
        },
        searchNumberError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.loading = false;
            state.errors = action.payload;
            state.search = null;
        },
        resetSearchNumber: (state) => {
            state.search = null;
        },
        searchCreditNoteSuccess: (state, action: PayloadAction<ShipmentDetailsType>) => {
            state.loading = false;
            state.errors = null;
            state.creditNotes = action.payload;
        },
        searchCreditNoteError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.loading = false;
            state.creditNotesErrors = action.payload;
        },
        searchCreditNoteLoading: (state, action: PayloadAction<boolean>) => {
            state.creditNotes = null;
            state.creditNotesErrors = null;
            state.loading = action.payload;
        },
        updateInvoiceAmountLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        subscribing: (state, action: PayloadAction<boolean>) => {
            state.subscripting = action.payload;
            state.invoiceSubscribeError = false;
            state.invoiceSubscribeSuccess = false;
            state.invoiceSubscribeEmail = '';
        },
        invoiceSubscribeSuccess: (state, action: PayloadAction<string>) => {
            state.invoiceSubscribeSuccess = true;
            state.subscripting = false;
            state.invoiceSubscribeError = false;
            state.invoiceSubscribeEmail = action.payload;
        },
        invoiceSubscribeError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.subscripting = false;
            state.invoiceSubscribeError = true;
            state.invoiceSubscribeEmail = '';
        },
        resetSubscribeStatus: (state) => {
            state.subscripting = false;
            state.invoiceSubscribeSuccess = false;
            state.invoiceSubscribeError = false;
            state.invoiceSubscribeEmail = '';
        },
        searchingEditableInvoicesRequest: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
            state.errors = null;
        },
        searchEditableInvoicesSuccess: (state, action: PayloadAction<ShipmentDetailsType[]>) => {
            state.editableInvoicesList = action.payload;
        },
        resetPaymentNote: (state) => {
            state.creditNotes = null;
        },
    },
});

export const { actions: customerActions } = customerSlice;
export const { reducer: customerReducer } = customerSlice;
