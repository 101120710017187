import { GridCell, GridContainer, Headline } from '@dhl-official/react-ui-library';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useContext } from 'react';
import { ButtonDHL, TitleFormSection } from '../../..';
import { braintreeClientToken } from '../../../../api/request/auth-request';
import { BraintreeContext } from '../../../../context/braintree/braintree-context';
import {
    BUTTON_TYPES,
    BUTTON_VARIANTS,
    HEADLINE_COLORS,
    HEADLINE_FONT_STRETCHES,
    HEADLINE_LEVELS,
    HEADLINE_TAGS,
    HEADLINE_TEXT_TRANSFORMS,
    HEADLINE_VARIANTS,
    INPUTFIELD_TYPES,
} from '../../../../types';
import { BraintreeField } from '../../../braintree/BraintreeField';
import { BraintreeForm } from '../../../braintree/BraintreeForm';
import AutoCompleteDHL from '../../../common/autoComplete/autocomplete';
import { AddressComplete } from '../../../common/autoComplete/autocomplete-types';
import InputDHL from '../../../common/input';
import { PhoneInputDHL } from '../../../common/phoneInputDHL/phone-input-dhl';
import SelectDHL from '../../../common/selectDHL/select';
import { CreditCardFormProps, FormCreditCardValues } from './form-types';
import { validateCreditCardForm } from './validate';

const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    street: '',
    country: 'US',
    state: '',
    city: '',
    zipCode: '',
};

export const CreditCardForm = ({ onSubmit, onPressCancel, loading }: CreditCardFormProps) => {
    const { checkBraintreeFormValidity } = useContext(BraintreeContext);
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={(values) => validateCreditCardForm(values)}
            onSubmit={(values) => onSubmit(values)}
        >
            {({ handleSubmit, setFieldValue }: FormikProps<FormCreditCardValues>) => (
                <Form
                    className='credit-card-tab__new-card-form'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                >
                    <GridCell className='credit-card-tab__container-cancel-button'>
                        <Headline
                            fontStretch={HEADLINE_FONT_STRETCHES.CONDENSED}
                            color={HEADLINE_COLORS.RED}
                            designLevel={HEADLINE_LEVELS[3]}
                            tag={HEADLINE_TAGS.H3}
                            variant={HEADLINE_VARIANTS.LIGHT}
                            textTransform={HEADLINE_TEXT_TRANSFORMS.UPPERCASE}
                            className='mt-1'
                        >
                            New credit card
                        </Headline>
                        <ButtonDHL
                            onClick={onPressCancel}
                            id='cancel'
                            name='cancel'
                            isBlock={false}
                            variant={BUTTON_VARIANTS.OUTLINE}
                            type={BUTTON_TYPES.BUTTON}
                            className='credit-card-tab__cancel-button'
                        >
                            Cancel
                        </ButtonDHL>
                    </GridCell>
                    <TitleFormSection className='mt-3'>Personal Information</TitleFormSection>
                    <Field
                        id='firstName'
                        name='firstName'
                        isBlock
                        variant={{
                            label: 'First name',
                            placeholder: 'First name',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='lastName'
                        name='lastName'
                        isBlock
                        variant={{
                            label: 'Last name',
                            placeholder: 'Last name',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='phoneNumber'
                        name='phoneNumber'
                        isBlock
                        label='Country code'
                        placeholder='+1 000 0000 000'
                        variant={{
                            label: 'Phone number',
                            placeholder: '+1 000 0000 000',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={PhoneInputDHL}
                    />
                    <Field
                        id='email'
                        name='email'
                        isBlock
                        variant={{
                            label: 'Email',
                            placeholder: 'Email address',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <TitleFormSection className='mt-3'>Payment Information</TitleFormSection>
                    <BraintreeForm
                        getBraintreeToken={braintreeClientToken}
                        styles={{
                            input: {
                                'font-family': 'Delivery, Verdana, sans-serif',
                                'font-size': '0.875rem',
                            },
                            '::-webkit-input-placeholder': {
                                color: 'rgba(0, 0, 0, 0.35)',
                            },
                            ':-moz-placeholder': {
                                color: 'rgba(0, 0, 0, 0.35)',
                            },
                            '::-moz-placeholder': {
                                color: 'rgba(0, 0, 0, 0.35)',
                            },
                            ':-ms-input-placeholder': {
                                color: 'rgba(0, 0, 0, 0.35)',
                            },
                        }}
                    >
                        <BraintreeField
                            id='cardholder-name'
                            type='cardholderName'
                            placeholder='Cardholder name'
                            label='Cardholder name'
                        />
                        <BraintreeField
                            id='card-number'
                            type='number'
                            placeholder='####-####-####-####'
                            label='Card Number'
                        />
                        <GridContainer columns={12} isFullWidth rowGap='none'>
                            <GridCell spanColumns={[12, 6, 6]} isOverflowHidden={false}>
                                <BraintreeField
                                    id='expiration-date'
                                    type='expirationDate'
                                    placeholder='MM/YY'
                                    label='Expiration Date'
                                />
                            </GridCell>
                            <GridCell startColumn={[1, 7, 7]} spanColumns={[12, 6, 6]} isOverflowHidden={false}>
                                <BraintreeField id='cvv' type='cvv' placeholder='###' label='cvv' />
                            </GridCell>
                        </GridContainer>
                    </BraintreeForm>
                    <TitleFormSection className='mt-3'>Billing Address</TitleFormSection>
                    <Field
                        id='street'
                        name='street'
                        isBlock
                        variant={{
                            label: 'Street',
                            placeholder: 'Street',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        onOptionSelected={(item: AddressComplete) => {
                            item.street && setFieldValue('street', item.street);
                            item.country && setFieldValue('country', item.country);
                            item.state && setFieldValue('state', item.state);
                            item.city && setFieldValue('city', item.city);
                            item.zipCode && setFieldValue('zipCode', item.zipCode);
                        }}
                        onChange={(value: string) => setFieldValue('street', value)}
                        component={AutoCompleteDHL}
                    />
                    <Field
                        id='country'
                        name='country'
                        isBlock
                        variant={{
                            label: 'Country',
                            placeholder: 'Country',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={SelectDHL}
                    >
                        <option value='US'>United States of America</option>
                        <option value='CA'>Canada</option>
                    </Field>
                    <Field
                        id='state'
                        name='state'
                        isBlock
                        variant={{
                            label: 'State',
                            placeholder: 'State',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='city'
                        name='city'
                        isBlock
                        variant={{
                            label: 'City',
                            placeholder: 'City',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <Field
                        id='zipCode'
                        name='zipCode'
                        variant={{
                            label: 'Zip code',
                            placeholder: 'Zip code',
                            type: INPUTFIELD_TYPES.STATIC,
                        }}
                        component={InputDHL}
                    />
                    <GridContainer columns={1} isFullWidth rowGap='none' className='credit-card-tab__add-button'>
                        <ButtonDHL
                            onClick={() => {
                                checkBraintreeFormValidity();
                                handleSubmit();
                            }}
                            id='add'
                            name='add'
                            isBlock
                            variant={BUTTON_VARIANTS.OUTLINE}
                            type={BUTTON_TYPES.BUTTON}
                            loading={loading}
                            showLoadingIcon
                        >
                            Add new credit card
                        </ButtonDHL>
                    </GridContainer>
                </Form>
            )}
        </Formik>
    );
};
