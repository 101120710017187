import { FormikErrors } from 'formik';
import React from 'react';
import { FormRemoveValues } from './types';

export const validateRemoveForm = (values: FormRemoveValues) => {
    const { comments } = values;
    const errors: FormikErrors<FormRemoveValues> = {};

    if (!comments) {
        errors.comments = 'This field is required';
    }

    return errors;
};
