import {
    GridCell,
    IconDocument,
    Link,
    Loader,
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeadCell,
    TableRow,
    Text,
} from '@dhl-official/react-ui-library';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Requests from '../../../api/request/customer-request';
import {
    BackButton,
    ButtonDHL,
    ErrorApp,
    GlobalModal,
    InvalidCustomerModal,
    ProgressLoaderModal,
} from '../../../components';
import { RemoveModal } from '../../../components/removeModal';
import { FormRemoveValues } from '../../../components/removeModal/types';
import useAuth from '../../../context/authContext/auth-context';
import { addZeroes, filterInt, numberCommaPattern, toCamelCase } from '../../../helpers';
import { cleanNumber } from '../../../helpers/clean-number';
import { formatNumberToDHL } from '../../../helpers/custom-format-number';
import { useAddInvoicesToCart, useFindDuplicates } from '../../../hooks';
import { selectCartReducer, selectCustomer } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { customerActions, deleteCart, loginAsGuest, saveComments, uiActions } from '../../../store/actions';
import { searchAWBNumber, searchInvoiceNumber, updatePaymentRequests } from '../../../store/customer/actions';
import { InvoicesType } from '../../../store/customer/types';
import { BUTTON_TYPES, BUTTON_VARIANTS, ICON_ORIENTATION, TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { CustomersType } from '../../../types/sprintpay';
import { SearchType } from '../../search/search-screen-types';
import { DHLTableProps } from './table-types';
import TextTable from './text';

export const DHLTable = ({ loading }: DHLTableProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const customer = useAppSelector(selectCustomer);
    const cart = useAppSelector(selectCartReducer);
    const auth = useAuth();
    const { customerNumber = '' } = useParams<'customerNumber'>();
    const location = useLocation();
    const { invoice_number: invoiceNumber = '', awb_number: mawb = '' } = queryString.parse(location.search);
    const [invoices, setInvoices] = useState<InvoicesType[]>([]);
    const [countInvoicesAdded, setCountInvoicesAdded] = useState<InvoicesType[]>([]);
    const [totalNet, setTotalNet] = useState('0');
    const [invoiceAmount, setInvoiceAmount] = useState('0');
    const [invoiceSubtotal, setInvoiceSubtotal] = useState('0');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showErrorDifCustomerModal, setShowErrorDifCustomerModal] = useState(false);
    const [showLoaderModal, setLoaderModal] = useState(false);
    const [showRemoveModal, setRemoveModal] = useState<{
        isVisible: boolean;
        awbs?: InvoicesType[];
    }>({
        isVisible: false,
        awbs: [],
    });
    const [showErrorAddingModal, setShowErrorAddingModal] = useState(false);
    const [pressSignin, setPressSignin] = useState(false);
    const [showDuplicateInvoiceModal, setShowDuplicateInvoiceModal] = useState(false);
    const {
        error: errorDescriptionAddingPayment,
        showError: showAddingError,
        addPaymentsRequestToCart,
        setShowErrors,
        processCompleted: paymetsAddedCompleted,
    } = useAddInvoicesToCart();
    const [searching, setSearching] = useState(false);
    const { findAWBDuplicates } = useFindDuplicates(invoiceNumber as string, mawb as string);
    const [initialValues] = useState<FormRemoveValues>({ comments: '' });
    const {
        register,
        formState: { errors },
        watch,
    } = useForm({
        mode: 'all',
    });
    const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
    const [downloadingPDFError, setDownloadingPDFError] = useState(null);

    const onChangeAmount = _.debounce((value: string, index: number): void => {
        if (customer.search?.IATA_code && customer.search?.IATA_code !== undefined) {
            dispatch(
                updatePaymentRequests({ property: 'amount', value, index, IATA_code: customer.search?.IATA_code })
            );
        }
    }, 400);

    const onClickAction = (index: number): void => {
        if (customer.search?.IATA_code && customer.search?.IATA_code !== undefined) {
            dispatch(
                updatePaymentRequests({
                    property: 'added',
                    value: !invoices[index].added,
                    index,
                    IATA_code: customer.search?.IATA_code,
                })
            );
        }
    };

    const onDownloadPDF = async (): Promise<void> => {
        if (customer.search?.IATA_code) {
            let link = null;
            try {
                setIsDownloadingPDF(true);
                setDownloadingPDFError(null);
                const response = await Requests.downloadInvoiceDocument(customer.search.IATA_code);
                const url = window.URL.createObjectURL(new Blob([response]));
                link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${customer.search?.batch_number}.pdf`);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                setDownloadingPDFError('Oops! Failed to find PDF document.');
            } finally {
                setIsDownloadingPDF(false);
                if (link) {
                    document.body.removeChild(link);
                }
            }
        }
    };

    /**
     *
     * This useEffect is going to watch the inputs (Total Net) to validate the amount and
     * to add zeros. Example: 1.00
     */
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            const indexInput = filterInt(name?.split('name.')[1]);
            const newValue = cleanNumber(value.name[indexInput]);

            onChangeAmount(addZeroes(newValue), indexInput);
        });

        return () => subscription.unsubscribe();
    }, [watch, onChangeAmount, dispatch]);

    /**
     *
     * This function is going to make login.
     * Conditions: **The total net input has to be valid**
     */
    const onPressSignIn = async (): Promise<void> => {
        const userRemovePayment = await customer.search?.list_invoices.filter((element) => !element.added);

        if (!!userRemovePayment?.length) {
            setPressSignin(true);

            setRemoveModal({
                isVisible: true,
                awbs: userRemovePayment,
            });
        } else {
            navigate('/auth/login');
        }
    };

    const navigateToCart = (): void => {
        if (!showAddingError) {
            dispatch(uiActions.resetTransaction());

            navigate('/cart');
        }
    };

    const continueToCart = async (isGuest: boolean): Promise<void> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let res: any;

        if (isGuest && auth.getUserType() !== CustomersType.GUEST) {
            await auth.logout();

            res = await dispatch(loginAsGuest());

            if (res?.payload?.authTokens) {
                await dispatch(deleteCart());
            }
        }

        setLoaderModal(true);
        addPaymentsRequestToCart(customer.search, '');
    };

    const onPressContinue = async (isGuest: boolean): Promise<void> => {
        const inv = await invoices.map((i) => i.mawb);
        const dup = inv && findAWBDuplicates(inv);

        if (dup) {
            setShowDuplicateInvoiceModal(true);
        } else {
            const userRemovePayment = await invoices.filter((element) => !element.added);

            if (!!userRemovePayment?.length) {
                setRemoveModal({
                    isVisible: true,
                    awbs: userRemovePayment,
                });
            } else {
                continueToCart(isGuest);
            }
        }
    };

    /**
     *
     * This useEffect is going to add the invoices to the state
     */
    useEffect(() => {
        if (customer.search?.list_invoices) {
            setInvoices(customer.search?.list_invoices);
        }
    }, [customer.search?.list_invoices]);

    /**
     *
     * This useEffect is going to calculate the total and subtotal amount
     */
    useEffect(() => {
        if (invoices.length) {
            let total = 0;
            let subtotal = 0;
            let amount = 0;

            const countInv = invoices.filter((item) => item.added && !item.is_fully_paid);

            countInv.forEach((invoice) => {
                total += parseFloat(cleanNumber(invoice.total_net));
                subtotal += parseFloat(cleanNumber(invoice.sub_total_tax_excl));
                amount += parseFloat(cleanNumber(invoice.amount));
            });

            setInvoiceSubtotal(subtotal.toFixed(2));
            setTotalNet(total.toFixed(2));
            setInvoiceAmount(amount.toFixed(2));
            setCountInvoicesAdded(countInv);
        }
    }, [invoices]);

    const onCloseErrorModal = useCallback((): void => setShowErrorModal(false), []);

    const logout = (): void => {
        navigate('/');

        auth.logout();
    };

    const onCloseRemoveModal = useCallback(
        (): void =>
            setRemoveModal({
                isVisible: false,
            }),
        []
    );

    const onCloseErrorDifCustomerModal = useCallback((): void => setShowErrorDifCustomerModal(false), []);

    const onCloseLoaderModal = useCallback((): void => setLoaderModal(false), []);

    const onCloseDuplicateInvModal = useCallback((): void => {
        setShowDuplicateInvoiceModal(false);

        navigateToCart();
    }, [dispatch]);

    const onCloseErrorAddingModal = useCallback((): void => {
        setShowErrors(false);

        setShowErrorAddingModal(false);
    }, []);

    const saveCommentsAPI = useCallback(
        async (comments: string): Promise<void> => {
            const awbs =
                showRemoveModal.awbs &&
                showRemoveModal?.awbs.map((awb) => ({
                    hawbId: awb.hawbId,
                    awb: awb.mawb,
                }));

            dispatch(saveComments({ comment: comments, awbs: awbs || [] }));
        },
        [showRemoveModal?.awbs]
    );

    const sendComments = useCallback(
        async ({ comments }: { comments: string }): Promise<void> => {
            await saveCommentsAPI(comments);

            if (pressSignin) {
                navigate('/auth/login');
            } else {
                continueToCart(auth.getUserType() === CustomersType.GUEST);
            }
        },
        [pressSignin, continueToCart, showRemoveModal.awbs]
    );

    /**
     *
     * This useEffect is going to watch the url to make a search in case of exists the
     * customerNumber and the invoice/awb number
     */
    useEffect(() => {
        const searchInvoice = (invNumber: string, type: string) => {
            try {
                const customerNumberLength = customer.customerNumber?.length;
                const numberField = invNumber.substring(0, customerNumberLength);

                if (
                    numberField.toLowerCase() !== customer.customerNumber?.toLowerCase() &&
                    type === SearchType.invoice_number
                ) {
                    setShowErrorDifCustomerModal(true);
                } else if (type === SearchType.invoice_number) {
                    dispatch(searchInvoiceNumber({ customerNumber, invoiceNumber: invNumber }));
                } else if (type === SearchType.mawb) {
                    dispatch(searchAWBNumber({ customerNumber, awbNumber: cleanNumber(invNumber) }));
                }

                setSearching(false);
            } catch (error) {
                setSearching(false);
            }
        };

        const onSubmit = async (invoiceNumber: string, searchType: SearchType.invoice_number | SearchType.mawb) => {
            const numberToSearch =
                searchType === SearchType.invoice_number ? invoiceNumber : cleanNumber(invoiceNumber);

            searchInvoice(numberToSearch, searchType);
        };

        if (customerNumber === customer.customerNumber && !searching) {
            if (mawb && (typeof mawb === 'string' || mawb instanceof String)) {
                const m = cleanNumber(mawb as string);
                const elem = customer.search?.list_invoices.find(
                    (payment) => payment.mawb?.toString().toLowerCase() === m.toLowerCase()
                );

                if (!elem) {
                    setSearching(true);

                    onSubmit(mawb as string, SearchType.mawb);
                }
            }
        } else if (customerNumber !== customer.customerNumber && !searching) {
            setShowErrorDifCustomerModal(true);
        }
    }, [customerNumber, dispatch, invoiceNumber, customer.search, searching, mawb, customer.customerNumber]);

    const onPressBack = useCallback(() => {
        dispatch(customerActions.resetSearchNumber());

        if (customer.customerNumber) {
            navigate(`/customer/${customer.customerNumber}`);
        } else {
            navigate('/', { replace: true });
        }
    }, [cart.dhlPaymentRequests, customer.customerNumber, navigate]);

    useEffect(() => {
        if (showAddingError) {
            setLoaderModal(false);
            setShowErrorAddingModal(true);
        }
    }, [showAddingError]);

    useEffect(() => {
        if (paymetsAddedCompleted && !showAddingError) {
            navigateToCart();
        }
    }, [paymetsAddedCompleted, showAddingError]);

    const validateInput = (value: string): boolean => {
        const cleanNum = cleanNumber(value);

        return numberCommaPattern.test(cleanNum);
    };

    return (
        <>
            {(searching || customer.loading) && (
                <GridCell
                    className='shipment__container-table d-flex justify-content-center mt-3 align-items-center'
                    spanColumns={[12]}
                >
                    <Loader />
                    <Text size={TEXT_SIZES['2XL']} weight={TEXT_WEIGHTS[400]} className='ms-3'>
                        Loading...
                    </Text>
                </GridCell>
            )}
            {!customer.loading && !customer.search && !searching && (
                <GridCell
                    className='shipment__container-table d-flex justify-content-center mt-3 align-items-center'
                    spanColumns={[12]}
                >
                    <Text size={TEXT_SIZES['LG']} weight={TEXT_WEIGHTS[200]} className='ms-3'>
                        Oops. Invoice number/mawb not found. Please try again.
                    </Text>
                    <BackButton
                        showIcon={false}
                        onClick={onPressBack}
                        text='Back to search'
                        type='link'
                        useBackAction={false}
                        className='shipment__side-bar-back-button-link ms-2'
                    />
                </GridCell>
            )}
            {!customer.loading && customer.search && !searching && customer.search?.is_valid_credit_note && (
                <GridCell
                    className='shipment__container-table d-flex justify-content-center mt-3 align-items-center'
                    spanColumns={[12]}
                >
                    <Text size={TEXT_SIZES['LG']} weight={TEXT_WEIGHTS[200]} className='ms-3'>
                        Oops. Invoice number/mawb not found. Please try again.
                    </Text>
                    <BackButton
                        showIcon={false}
                        onClick={onPressBack}
                        text='Back to search'
                        type='link'
                        useBackAction={false}
                        className='shipment__side-bar-back-button-link ms-2'
                    />
                </GridCell>
            )}
            {(!customer.loading || !searching) &&
                !!customer.search &&
                !customer.errors?.errors.length &&
                !customer.search?.is_valid_credit_note && (
                    <GridCell
                        className='shipment__container-table'
                        spanColumns={[12, 8, 9, 10]}
                        startColumn={[1, 5, 4, 3]}
                    >
                        <GridCell className='shipment__table-header' spanColumns={[12]}>
                            {invoiceSubtotal && (
                                <div className='shipment__table-info'>
                                    <TextTable weight={TEXT_WEIGHTS[200]}>Invoice subtotal</TextTable>
                                    <TextTable>{`$${formatNumberToDHL(invoiceSubtotal)}`}</TextTable>
                                </div>
                            )}
                            {totalNet && (
                                <div className='shipment__table-info'>
                                    <TextTable weight={TEXT_WEIGHTS[200]}>Total net</TextTable>
                                    <TextTable>{`$${formatNumberToDHL(totalNet)}`}</TextTable>
                                </div>
                            )}
                            {invoiceAmount && (
                                <div className='shipment__table-info'>
                                    <TextTable weight={TEXT_WEIGHTS[200]}>Invoice Amount</TextTable>
                                    <TextTable>{`$${formatNumberToDHL(invoiceAmount)}`}</TextTable>
                                </div>
                            )}
                        </GridCell>
                        {downloadingPDFError && <ErrorApp>{downloadingPDFError}</ErrorApp>}
                        <div className='shipment__table-container'>
                            <Table className='shipment__table'>
                                <TableCaption className='shipment__table-caption'>
                                    <div className='shipment__table-pdf-dhl'>
                                        <div className='shipment__table-title' />
                                        <ButtonDHL
                                            onClick={onDownloadPDF}
                                            id='cancel'
                                            name='cancel'
                                            isBlock={false}
                                            variant={BUTTON_VARIANTS.TEXT}
                                            type={BUTTON_TYPES.BUTTON}
                                            className='credit-card-tab__cancel-button'
                                            icon={<IconDocument />}
                                            iconOrientation={ICON_ORIENTATION.LEFT}
                                            loading={isDownloadingPDF}
                                            showLoadingIcon
                                        >
                                            View PDF invoice
                                        </ButtonDHL>
                                    </div>
                                </TableCaption>
                                <TableHead className='shipment__table-head'>
                                    <TableRow>
                                        <TableHeadCell align='center' scope='col'>
                                            Date
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            Service
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            ORG
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            DST
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            Mawb
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            PCS
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            Gross Weight {toCamelCase(invoices[0]?.weight_unit)}.
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            Billed Weight {toCamelCase(invoices[0]?.weight_unit)}.
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            FREIGHT
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            TSC
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            SUB TOTAL (tax excl.)
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            FET
                                        </TableHeadCell>
                                        <TableHeadCell align='center' scope='col'>
                                            TOTAL NET
                                        </TableHeadCell>
                                        <TableHeadCell align='left' scope='col' />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoices.map((item: InvoicesType, index) => {
                                        return (
                                            <TableRow variant='regular' key={index}>
                                                <TableHeadCell align='center' data-label='Date'>
                                                    {item.date}
                                                </TableHeadCell>
                                                <TableCell align='center' data-label='Service'>
                                                    {item.service}
                                                </TableCell>
                                                <TableCell align='center' data-label='ORG'>
                                                    {item.org}
                                                </TableCell>
                                                <TableCell align='center' data-label='Runtime'>
                                                    {item.dst}
                                                </TableCell>
                                                <TableCell align='center' data-label='Mawb'>
                                                    {item.mawb}
                                                </TableCell>
                                                <TableCell align='center' data-label='PCS'>
                                                    {item.pcs}
                                                </TableCell>
                                                <TableCell align='center' data-label='Gross Weight Lbs.'>
                                                    {`${formatNumberToDHL(item.gross_weight)}`}
                                                </TableCell>
                                                <TableCell align='center' data-label='Billed Weight Lbs.'>
                                                    {`${formatNumberToDHL(item.billed_weight)}`}
                                                </TableCell>
                                                <TableCell align='center' data-label='Freight'>
                                                    {`$${formatNumberToDHL(item.sub_total)}`}
                                                </TableCell>
                                                <TableCell align='center' data-label='TSC'>
                                                    {`$${formatNumberToDHL(item.tsc)}`}
                                                </TableCell>
                                                <TableCell align='center' data-label='SUB TOTAL'>
                                                    {`$${formatNumberToDHL(item.sub_total_tax_excl)}`}
                                                </TableCell>
                                                <TableCell align='center' data-label='FET'>
                                                    {`$${formatNumberToDHL(item.fet)}`}
                                                </TableCell>
                                                <TableCell
                                                    align='left'
                                                    data-label='TOTAL NET'
                                                    className='total-net_cell'
                                                >
                                                    <div className='popover__container-main'>
                                                        <div
                                                            className={`total-net__wrap  ${
                                                                errors?.name &&
                                                                errors?.name.hasOwnProperty(`${index.toString()}`)
                                                                    ? 'shipment__table-input-valid'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <p className='total-net__amount-text'>Amount</p>
                                                            <input
                                                                type='text'
                                                                className={`shipment__table-input`}
                                                                defaultValue={`${formatNumberToDHL(item.amount)}`}
                                                                {...register(`name.${index}`, {
                                                                    required: item.added,
                                                                    validate: (value: string) => validateInput(value),
                                                                    onBlur: (event) => {
                                                                        if (!item.is_fully_paid) {
                                                                            const { value } = event.target;
                                                                            const a = cleanNumber(value);

                                                                            event.target.value = `${formatNumberToDHL(
                                                                                a
                                                                            )}`;
                                                                        }
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align='center' scope='row'>
                                                    <ButtonDHL
                                                        onClick={() => onClickAction(index)}
                                                        id='add'
                                                        name='add'
                                                        isBlock
                                                        variant={
                                                            item.added
                                                                ? BUTTON_VARIANTS.PRIMARY
                                                                : BUTTON_VARIANTS.OUTLINE
                                                        }
                                                        type={BUTTON_TYPES.BUTTON}
                                                        isDisabled={!!item.is_fully_paid}
                                                    >
                                                        {item.is_fully_paid ? 'Is Paid' : item.added ? 'Remove' : 'Add'}
                                                    </ButtonDHL>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow />
                                </TableFooter>
                            </Table>
                        </div>
                        <GridCell
                            className='shipment__footer'
                            spanColumns={[12]}
                            startColumn={[1]}
                            isOverflowHidden={false}
                        >
                            <GridCell
                                className='shipment__footer-buttons'
                                spanColumns={[12]}
                                startColumn={[1]}
                                isOverflowHidden={false}
                            >
                                {auth.getUserType() === CustomersType.GUEST && (
                                    <Link
                                        href={`${process.env.REACT_APP_SPRINT_PAY_URL}/${process.env.REACT_APP_SOURCE}/account-type`}
                                        className='shipment__sign-up-button mb-3'
                                        target='_blank'
                                    >
                                        <ButtonDHL
                                            id='singUp'
                                            name='singUp'
                                            isBlock={false}
                                            variant={BUTTON_VARIANTS.OUTLINE}
                                            type={BUTTON_TYPES.BUTTON}
                                        >
                                            Sign Up
                                        </ButtonDHL>
                                    </Link>
                                )}
                                {auth.getUserType() === CustomersType.GUEST && (
                                    <>
                                        <ButtonDHL
                                            onClick={onPressSignIn}
                                            id='signIn'
                                            name='signIn'
                                            isBlock={false}
                                            variant={BUTTON_VARIANTS.OUTLINE}
                                            type={BUTTON_TYPES.BUTTON}
                                            className='shipment__sign-in-button mb-3'
                                            isDisabled={
                                                loading ||
                                                cart.deletingCart ||
                                                cart.addingPayment ||
                                                !countInvoicesAdded.length
                                            }
                                        >
                                            Sign in with CargoSprint
                                        </ButtonDHL>
                                        <TextTable weight={TEXT_WEIGHTS[200]} className='shipment__or mb-3'>
                                            OR
                                        </TextTable>
                                    </>
                                )}
                                {auth.getUserType() === CustomersType.GUEST && (
                                    <div className='shipment__continue-button mb-3'>
                                        <TextTable
                                            weight={TEXT_WEIGHTS[700]}
                                            className='shipment__continue-button-text'
                                        >
                                            Continue with {countInvoicesAdded.length} items in cart
                                        </TextTable>
                                        <ButtonDHL
                                            onClick={() => onPressContinue(true)}
                                            id='cancel'
                                            name='cancel'
                                            isBlock={false}
                                            variant={BUTTON_VARIANTS.PRIMARY}
                                            type={BUTTON_TYPES.BUTTON}
                                            loading={loading || cart.deletingCart || cart.addingPayment}
                                            isDisabled={
                                                loading ||
                                                cart.deletingCart ||
                                                cart.addingPayment ||
                                                !countInvoicesAdded.length
                                            }
                                            showLoadingIcon
                                            className='pe-5 ps-5'
                                        >
                                            Continue as guest
                                        </ButtonDHL>
                                    </div>
                                )}
                                {auth.getUserType() !== CustomersType.GUEST && (
                                    <>
                                        <ButtonDHL
                                            onClick={logout}
                                            id='signOut'
                                            name='signOut'
                                            isBlock={false}
                                            variant={BUTTON_VARIANTS.OUTLINE}
                                            type={BUTTON_TYPES.BUTTON}
                                            className='shipment__sign-in-button mb-3'
                                        >
                                            Logout
                                        </ButtonDHL>
                                        <TextTable weight={TEXT_WEIGHTS[200]} className='shipment__or mb-3'>
                                            OR
                                        </TextTable>
                                    </>
                                )}
                                {auth.getUserType() !== CustomersType.GUEST && (
                                    <div className='shipment__continue-button mb-3'>
                                        <TextTable
                                            weight={TEXT_WEIGHTS[700]}
                                            className='shipment__continue-button-text'
                                        >
                                            Continue with {countInvoicesAdded.length} items in cart
                                        </TextTable>
                                        <ButtonDHL
                                            onClick={() => onPressContinue(false)}
                                            id='cancel'
                                            name='cancel'
                                            isBlock={false}
                                            variant={BUTTON_VARIANTS.PRIMARY}
                                            type={BUTTON_TYPES.BUTTON}
                                            loading={loading || cart.deletingCart || cart.addingPayment}
                                            isDisabled={
                                                loading ||
                                                cart.deletingCart ||
                                                cart.addingPayment ||
                                                !countInvoicesAdded.length
                                            }
                                            showLoadingIcon
                                            className='pe-5 ps-5'
                                        >
                                            Continue
                                        </ButtonDHL>
                                    </div>
                                )}
                            </GridCell>
                        </GridCell>
                    </GridCell>
                )}
            <ProgressLoaderModal
                isOpen={!!countInvoicesAdded.length && showLoaderModal}
                onCloseModal={onCloseLoaderModal}
                total={countInvoicesAdded.length}
                description={cart.addingPayment ? 'Adding payment...' : 'Loading...'}
            />
            <GlobalModal
                isOpen={showErrorModal}
                description={`Some fields weren't completed correctly. Please review them and try again.`}
                descriptionSize={TEXT_SIZES['LG']}
                rightButtonText='Ok'
                rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
                onCloseModal={onCloseErrorModal}
            />
            <GlobalModal
                isOpen={showDuplicateInvoiceModal}
                description='This invoice has already been added to your cart'
                descriptionSize={TEXT_SIZES['LG']}
                rightButtonText='Ok'
                rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
                onCloseModal={onCloseDuplicateInvModal}
            />
            <InvalidCustomerModal isOpen={showErrorDifCustomerModal} onCloseModal={onCloseErrorDifCustomerModal} />
            <GlobalModal
                isOpen={showErrorAddingModal}
                description={errorDescriptionAddingPayment}
                descriptionSize={TEXT_SIZES['LG']}
                rightButtonText='Ok'
                rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
                onCloseModal={onCloseErrorAddingModal}
            />
            <RemoveModal
                isOpen={showRemoveModal.isVisible}
                initialValues={initialValues}
                onPressLeftButton={sendComments}
                onCloseModal={onCloseRemoveModal}
            />
        </>
    );
};
