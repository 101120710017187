import axios, { AxiosInstance } from 'axios';
import { getAuthorizationHeader } from './authToken';

const baseDHLURL = process.env.REACT_APP_DHL_URL;
const baseCardPaymentURL = process.env.REACT_APP_CARD_URL;
const baseCartPaymentURL = process.env.REACT_APP_CART_URL;
const baseAuthPaymentURL = process.env.REACT_APP_AUTH_URL;

const timeout = 960000;
const headers = {
    common: { Accept: 'application/json, text/plain, */*' },
    get: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    post: { 'Content-Type': 'application/json' },
    put: { 'Content-Type': 'application/json' },
    patch: { 'Content-Type': 'application/json' },
};

const baseHeaders = { timeout, headers };

const RequestAuthWithoutToken: AxiosInstance = axios.create({
    ...baseHeaders,
    baseURL: baseDHLURL,
});

const RequestAuthToken: AxiosInstance = axios.create({
    ...baseHeaders,
    baseURL: baseDHLURL,
});

RequestAuthToken.interceptors.request.use((config) => {
    config.headers.Authorization = getAuthorizationHeader();

    return config;
});

// Temporal client to connect with the card API
const RequestCard: AxiosInstance = axios.create({
    ...baseHeaders,
    baseURL: baseCardPaymentURL,
});

RequestCard.interceptors.request.use((config) => {
    config.headers.Authorization = getAuthorizationHeader();

    return config;
});

// Temporal client to connect with the cart API
const RequestCart: AxiosInstance = axios.create({
    ...baseHeaders,
    baseURL: baseCartPaymentURL,
});

RequestCart.interceptors.request.use((config) => {
    config.headers.Authorization = getAuthorizationHeader();

    return config;
});

// Temporal client to connect with the auth API
const RequestAuth: AxiosInstance = axios.create({
    ...baseHeaders,
    baseURL: baseAuthPaymentURL,
});

RequestAuth.interceptors.request.use((config) => {
    config.headers.Authorization = getAuthorizationHeader();

    return config;
});

export { RequestAuth, RequestAuthToken, RequestAuthWithoutToken, RequestCard, RequestCart };
