import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../types';
import { DescriptionMessageProps } from './description-types';

const DescriptionMessage = ({
    size = TEXT_SIZES['LG'],
    weight = TEXT_WEIGHTS[400],
    className = '',
    children,
    ...restProps
}: DescriptionMessageProps) => {
    return (
        <Text isParagraph size={size} weight={weight} className={`mb-0 ${className}`} {...restProps}>
            {children}
        </Text>
    );
};

export default DescriptionMessage;
