import { useCallback, useEffect, useRef, useState } from 'react';
import { removeWhiteSpaces } from '../helpers/remove_white_spaces';
import { selectCartReducer } from '../selectors';
import { useAppSelector } from '../store';

export const useFindDuplicates = (invoiceNumberDefault?: string, mawbNumberDefault?: string | string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findAWBDuplicates = useRef<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findInvoiceNumberDuplicates = useRef<any>();
    const cart = useAppSelector(selectCartReducer);
    const [isDuplicated, setIsDuplicated] = useState(false);
    const [isInvoiceNumberDuplicated, setIsInvoiceNumberDuplicated] = useState(false);
    const [isMawbNumberDuplicated, setIsMawbNumberDuplicated] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState(invoiceNumberDefault);
    const [mawbNumber, setMawbNumber] = useState(mawbNumberDefault);

    const findCreditNoteInCart = useCallback(
        (creditNote: string): boolean => {
            const _creditNote = removeWhiteSpaces(creditNote);

            const elem = cart.dhlPaymentRequests.find(
                (request) =>
                    request.is_credit_note &&
                    request.fee_content.find((content) => content.awb?.toLowerCase() === _creditNote.toLowerCase())
            );

            return !!elem;
        },
        [cart.dhlPaymentRequests, cart.cartBill]
    );

    const findAWBInCart = useCallback(
        (awb: string): boolean => {
            const _awb = removeWhiteSpaces(awb);
            let isIncluded = false;
            cart.dhlPaymentRequests.forEach((paymentRequest) => {
                paymentRequest.fee_content.forEach((content) => {
                    if (content.awb === _awb) {
                        isIncluded = true;
                        return true;
                    }
                });
            });
            return isIncluded;
        },
        [cart.dhlPaymentRequests]
    );

    const findAWBInLocalStorage = useCallback(
        (awb: string): boolean => {
            const _awb = removeWhiteSpaces(awb);

            let duplicated = false;

            cart.invoicesAdded.forEach((inv) => {
                const elem = inv.list_invoices.find(
                    (payment) => payment.mawb?.toString().toLowerCase() === _awb.toLowerCase()
                );

                if (!!elem) {
                    duplicated = true;

                    return;
                }
            });

            return duplicated;
        },
        [cart.invoicesAdded]
    );

    const findInvoiceNumber = useCallback(
        (invoice: string) => {
            const _invoice = removeWhiteSpaces(invoice);
            let duplicated = false;

            cart.invoicesAdded.forEach((inv) => {
                if (inv.IATA_code.toString().toLowerCase() === _invoice.toString().toLowerCase()) {
                    duplicated = true;

                    return;
                }
            });

            return duplicated;
        },
        [cart.invoicesAdded]
    );

    useEffect(() => {
        findAWBDuplicates.current = (data: string | string[], findLocal = true): boolean => {
            let a = false;

            if (Array.isArray(data)) {
                a = data.some((awb: string) => {
                    const isDuplicatedInCart = findAWBInCart(awb);
                    const isDuplicatedInStorage = findLocal ? findAWBInLocalStorage(awb) : false;

                    if (isDuplicatedInCart || isDuplicatedInStorage) {
                        setIsDuplicated(true);
                        setIsMawbNumberDuplicated(true);

                        return isDuplicatedInCart || isDuplicatedInStorage;
                    }

                    setIsMawbNumberDuplicated(false);
                    return false;
                });
            } else {
                const isDuplicatedInCart = findAWBInCart(data);
                const isDuplicatedInStorage = findLocal ? findAWBInLocalStorage(data) : false;

                if (isDuplicatedInCart || isDuplicatedInStorage) {
                    setIsDuplicated(true);
                    setIsMawbNumberDuplicated(true);

                    a = isDuplicatedInCart || isDuplicatedInStorage;
                }
            }

            setIsMawbNumberDuplicated(a);
            return a;
        };

        findInvoiceNumberDuplicates.current = (invoice: string): boolean => {
            const isDuplicatedInStorage = findInvoiceNumber(invoice);

            if (isDuplicatedInStorage) {
                setIsDuplicated(true);
                setIsInvoiceNumberDuplicated(true);

                return isDuplicatedInStorage;
            }

            setIsInvoiceNumberDuplicated(false);
            return false;
        };

        if (invoiceNumber) {
            findInvoiceNumberDuplicates.current(invoiceNumber);
        }

        if (mawbNumber) {
            findAWBDuplicates.current(mawbNumber, false);
        }
    }, [
        cart.paymentRequests,
        cart.invoicesAdded,
        invoiceNumber,
        mawbNumber,
        findAWBInLocalStorage,
        findAWBInCart,
        findInvoiceNumber,
    ]);

    return {
        isDuplicated,
        /**
         * This function is going to find awb duplicates on the cart and local storage
         */
        findAWBDuplicates: (data: string | string[], findLocal = true) => findAWBDuplicates.current(data, findLocal),
        findInvoiceNumberDuplicates: (invoice: string) => findInvoiceNumberDuplicates.current(invoice),
        setInvoiceNumber,
        setMawbNumber,
        isInvoiceNumberDuplicated,
        isMawbNumberDuplicated,
        findAWBInLocalStorage,
        findAWBInCart,
        findCreditNoteInCart,
    };
};
