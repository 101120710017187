import { Flex, IconInformation, Popover, Text } from '@dhl-official/react-ui-library';
import { formatNumberToDHL } from '../../../helpers/custom-format-number';
import { POPOVER_PLACEMENTS, POPOVER_TRIGGERS, TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { FeeProps } from './fee-types';

const Fee = ({ fee, feeAmount, feeDescription, containerClassName = '', showFeeDescription = false }: FeeProps) => {
    return (
        <Flex className={`d-flex mt-2 ${containerClassName}`}>
            <Flex className='flex-grow-1 align-items-center'>
                <Text size={TEXT_SIZES['SM']}>{fee}</Text>
                {showFeeDescription && (
                    <Popover
                        className='ms-2'
                        id='popover'
                        showCloseButton={false}
                        popoverTriggerAriaLabel='Description'
                        trigger={POPOVER_TRIGGERS.HOVER}
                        placement={POPOVER_PLACEMENTS.RIGHT_START}
                        triggerEl={
                            <IconInformation
                                name='Information'
                                title='Information'
                                width={15}
                                height={15}
                                className='ms-3'
                            />
                        }
                    >
                        <p style={{ margin: 0 }}>{feeDescription}</p>
                    </Popover>
                )}
            </Flex>
            <Text className='align-self-end' size={TEXT_SIZES['XS']} weight={TEXT_WEIGHTS[700]}>
                {`$${formatNumberToDHL(feeAmount)}`}
            </Text>
        </Flex>
    );
};

export default Fee;
