import { deleteCard } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/deleteCreditCard';
import { getCards } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/getCreditCards';
import { RequestCard } from '../config';
import * as Url from '../urls';

export const getCreditCards = () => getCards();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteCreditCard = (card: any) => deleteCard(card);

export const saveCreditCard = async (paymentNonce: string) => {
    const { data } = await RequestCard({
        url: `${Url.savePaymentMethod}`,
        method: 'POST',
        data: {
            saveNonce: paymentNonce,
        },
    });

    return data;
};
