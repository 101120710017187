import { getPaymentNonce } from '@cargos/sprintpay_frontend_core_api/lib/payments-processors/braintree/braintree';
import { CartBillType } from '../../store/cart/types';
import {
    CartContentDetails,
    CreditCardParameters,
    DHLPaymentRequestType,
    InvoiceNumber,
    MAWB,
    PaymentDataRequestType,
    PaymentMethodsType,
    PaymentRequestType,
    PaymentSuccessResponseType,
    PaymentTokenDataRequest,
    PaymentType,
} from '../../types/sprintpay';
import { RequestAuthToken } from '../config';
import * as Url from '../urls';

export const addPaymentRequest = async (createPaymentRequest: PaymentType) => {
    const { data } = await RequestAuthToken({
        url: `${Url.addPaymentRequest}`,
        method: 'POST',
        data: createPaymentRequest,
    });

    return data;
};

export const pay = async (
    paymentToken: PaymentTokenDataRequest,
    clientCart: CartContentDetails[],
    paymentRequest: PaymentDataRequestType
): Promise<PaymentSuccessResponseType> => {
    const { data } = await RequestAuthToken({
        url: `${Url.payDHL}`,
        method: 'POST',
        data: { paymentToken, clientCart, ...paymentRequest },
    });

    return data;
};

export const guestPay = async (
    paymentToken: PaymentTokenDataRequest,
    clientCart: CartContentDetails[],
    paymentRequest?: PaymentDataRequestType
): Promise<PaymentSuccessResponseType> => {
    const { data } = await RequestAuthToken({
        url: `${Url.payCart}`,
        method: 'POST',
        data: {
            paymentToken,
            clientCart,
            ...paymentRequest,
        },
    });

    return data;
};

export const getCardNonce = (creditCard: CreditCardParameters) => getPaymentNonce(creditCard, {});

export const editPaymentRequest = async (paymentRequestType: PaymentRequestType) => {
    const { data } = await RequestAuthToken({
        url: `${Url.updateDHLPaymentRequest}`,
        method: 'POST',
        data: paymentRequestType,
    });

    return data;
};

export const getCartBill = async (paymentMethod: PaymentMethodsType) => {
    const { data } = await RequestAuthToken({
        url: `${Url.getCartBill}?paymentMethod=${paymentMethod}`,
        method: 'GET',
    });

    return data as CartBillType;
};

export const deleteCart = async () => {
    const { data } = await RequestAuthToken({
        url: `${Url.deleteCart}`,
        method: 'DELETE',
    });

    return data;
};

// DHL PAYMENT REQUESTS
export const updateDHLPaymentRequest = async (updatePaymentRequest: PaymentRequestType) => {
    const { data } = await RequestAuthToken({
        url: `${Url.updateDHLPaymentRequest}`,
        method: 'POST',
        data: updatePaymentRequest,
    });

    return data;
};

export const getDHLCart = async () => {
    const { data } = await RequestAuthToken({
        url: `${Url.getDHLCart}`,
        method: 'GET',
    });

    return data as DHLPaymentRequestType[];
};

export const getDHLCartBill = async (paymentMethod: PaymentMethodsType) => {
    const { data } = await RequestAuthToken({
        url: `${Url.getDHLCartBill}?paymentMethod=${paymentMethod}`,
        method: 'GET',
    });

    return data;
};

export const deleteDHLInvoiceFromCart = async (invoiceNumber: InvoiceNumber) => {
    const { data } = await RequestAuthToken({
        url: `${Url.deleteDHLInvoiceFromCart}?invoice_number=${invoiceNumber}`,
        method: 'DELETE',
    });

    return data;
};

export const deleteDHLOneAwbFromCart = async (mawb: MAWB) => {
    const { data } = await RequestAuthToken({
        url: `${Url.deleteDHLOneAwbFromCart}?mawb=${mawb}`,
        method: 'DELETE',
    });

    return data;
};

export const downloadReceiptByInvoices = async (invoices: number[], confirmationId: string) => {
    const { data } = await RequestAuthToken({
        url: `${Url.receiptByInvoices}`,
        method: 'POST',
        data: {
            invoices,
            confirmationId,
        },
        responseType: 'blob',
    });

    return data;
};

export const saveComments = async (
    text_comment: string,
    awbs: {
        hawbId: string | undefined;
        awb: string | undefined;
    }[]
) => {
    const { data } = await RequestAuthToken({
        url: `${Url.saveComments}`,
        method: 'POST',
        data: {
            text_comment,
            awbs,
        },
    });

    return data;
};
