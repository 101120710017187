import { CargoCreditType } from '../../types/sprintpay';
import { RequestAuthToken, RequestCart } from '../config';
import * as Url from '../urls';

export const getPaymentMethods = async () => {
    const { data } = await RequestAuthToken({
        url: Url.getPaymentMethods,
        method: 'GET',
    });

    return data;
};

export const getCargoCreditBalance = async (): Promise<CargoCreditType> => {
    const { data } = await RequestCart({
        url: `${Url.getBalance}`,
        method: 'GET',
    });

    return data;
};
