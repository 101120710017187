import { PredictionGoogle } from '../components/common/autoComplete/autocomplete-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapPredictionsToDHLOptions = (predictions: PredictionGoogle[]): PredictionGoogle[] => {
    if (!(predictions.length > 0)) {
        return [];
    }

    return predictions.map((prediction) => ({
        ...prediction,
        value: prediction.description ?? '',
        id: prediction.place_id ?? '',
        label: prediction.description ?? '',
    }));
};
