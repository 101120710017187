import { InputField } from '@dhl-official/react-ui-library';
import React, { ChangeEvent } from 'react';
import MaskedInput from 'react-text-mask';
import { INPUTFIELD_VALIDATION_TYPES } from '../../../types';
import { InputMaskProps } from './input-types';

const InputDHLMask = (props: InputMaskProps) => {
    const { field, form, className = '', mask } = props;

    const { touched, errors } = form;
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: INPUTFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    return (
        <MaskedInput
            mask={mask}
            guide={true}
            onChange={(value: ChangeEvent<HTMLInputElement>) => {
                field.onChange(value);
                props.onChange && props.onChange(value);
            }}
            onBlur={field.onBlur}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={(ref: any, otherProsp: any) => (
                <InputField
                    {...props}
                    {...otherProsp}
                    ref={ref}
                    value={field.value}
                    validation={validations}
                    className={`input__dhl-mask ${className}`}
                />
            )}
        />
    );
};

export default InputDHLMask;
