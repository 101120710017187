import { Card, GridCell } from '@dhl-official/react-ui-library';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../../components';
import { selectCart, selectCustomer } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { customerActions } from '../../../store/actions';
import Description from './description';
import Title from './title';

export const SideBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const customer = useAppSelector(selectCustomer);
    const cart = useAppSelector(selectCart);

    const onPressBack = useCallback(() => {
        dispatch(customerActions.resetSearchNumber());

        if (customer.customerNumber) {
            navigate(`/customer/${customer.customerNumber}`);
        } else {
            navigate('/', { replace: true, state: { prevLocation: true } });
        }
    }, [cart, customer.customerNumber]);

    return (
        <GridCell className='shipment__side-bar' spanColumns={[12, 4, 3, 2]} startColumn={[1]} isOverflowHidden={false}>
            <Card className='shipment__side-bar-card'>
                <div className='shipment__side-bar-data'>
                    {customer?.search?.business_unit && (
                        <>
                            <Title>Business unit:</Title>
                            <Description className='mb-3'>{customer?.search?.business_unit}</Description>
                        </>
                    )}
                    {customer?.search?.IATA_code && (
                        <>
                            <Title>DHL Invoice Number:</Title>
                            <Description className='mb-3'>{customer?.search?.IATA_code}</Description>
                        </>
                    )}
                    {customer?.search?.batch_number && (
                        <>
                            <Title>DHL Batch Number:</Title>
                            <Description className='mb-3'>{customer?.search?.batch_number}</Description>
                        </>
                    )}
                    {customer?.search?.station && (
                        <>
                            <Title>Currency code:</Title>
                            <Description className='mb-3'>{customer?.search?.station}</Description>
                        </>
                    )}
                    {customer?.search?.account_name && (
                        <>
                            <Title>DHL Customer #:</Title>
                            <Description className='mb-3'>{customer?.search?.account_name}</Description>
                        </>
                    )}
                    {customer?.search?.account_name && (
                        <>
                            <Title>DHL Account #:</Title>
                            <Description className='mb-3'>{customer?.search?.account_name}</Description>
                        </>
                    )}
                </div>
                <BackButton
                    onClick={onPressBack}
                    text='Back to search'
                    type='button'
                    useBackAction={false}
                    className='shipment__side-bar-back-button mb-3'
                />
                <BackButton
                    onClick={onPressBack}
                    text='Back to search'
                    type='link'
                    useBackAction={false}
                    className='shipment__side-bar-back-button-link mb-3'
                />
            </Card>
        </GridCell>
    );
};
