import { Children, ReactNode, isValidElement, useContext, useEffect } from 'react';
import { BraintreeContext } from '../../context/braintree/braintree-context';
import { BraintreeFormProps } from './braintree-types';

const findChildrenDeep = (target: ReactNode) => {
    let output = [];

    Children.toArray(target).forEach((child) => {
        if (isValidElement(child)) {
            if (child.props.__isBraintreeField) {
                output = [...output, child];
            }
            if (child.props.children) {
                output = [...output, ...findChildrenDeep(child.props.children)];
            }
        }
    });

    return output;
};

export const BraintreeForm = ({ getBraintreeToken, children, styles = {} }: BraintreeFormProps) => {
    const { initializeHostedFields, teardown } = useContext(BraintreeContext);

    useEffect(() => {
        const fields = findChildrenDeep(children).reduce((acc, child) => {
            const { type, id, placeholder } = child.props;
            acc[type] = {
                container: '#' + id,
                placeholder,
            };
            return acc;
        }, {});

        getBraintreeToken().then((response) => {
            initializeHostedFields(response.clientToken, fields, styles);
        });

        return () => {
            teardown();
        };
    }, []);

    return <div className='braintree'>{children}</div>;
};
