import { Flex, Text } from '@dhl-official/react-ui-library';
import { formatNumberToDHL } from '../../../helpers/custom-format-number';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { TotalFeeProps } from './total-fee-types';

const TotalFee = ({ totalAmount, containerClassName = '' }: TotalFeeProps) => {
    return (
        <Flex className={`d-flex mt-2 ${containerClassName}`}>
            <Flex className='flex-grow-1 align-items-center'>
                <Text size={TEXT_SIZES['LG']}>Total</Text>
            </Flex>
            <Text className='align-self-end' size={TEXT_SIZES['MD']} weight={TEXT_WEIGHTS[700]}>
                {`$${formatNumberToDHL(totalAmount)}`}
            </Text>
        </Flex>
    );
};

export default TotalFee;
