import { AutoCompleteField } from '@dhl-official/react-ui-library';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { mapPredictionsToDHLOptions } from '../../../helpers';
import { AUTOCOMPLETEFIELD_VALIDATION_TYPES } from '../../../types';
import {
    AddressComplete,
    AddressComponentType,
    AddressTypes,
    AutoCompleteProps,
    PredictionDHL,
    PredictionGoogle,
} from './autocomplete-types';

const AutoCompleteDHL = (props: AutoCompleteProps) => {
    const { field, form, className = '', onOptionSelected, onChange } = props;
    const { touched, errors } = form;
    const formProps = useFormikContext();
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: AUTOCOMPLETEFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    const { placePredictions, getPlacePredictions, placesService } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY_MAPS,
        debounce: 250,
        language: 'en',
        options: {
            types: [],
            componentRestrictions: { country: ['ca', 'us'] },
            fields: ['address_components', 'geometry'],
        },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleBlur = (e: any) => {
        field?.onBlur(field?.name);
        formProps.setFieldTouched(field?.name, true);
    };

    const _renderPredictions = useCallback((prediction: PredictionDHL, index: number) => {
        return (
            <option key={index} value={prediction.value}>
                {prediction.value}
            </option>
        );
    }, []);

    const _onPredictionTapped = async (item: PredictionGoogle) => {
        handleBlur(null);

        try {
            placesService.getDetails(
                {
                    placeId: item.place_id,
                    language: 'en',
                    fields: ['address_components', 'geometry'],
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                },
                (result) => {
                    if (result) {
                        const { address_components } = result;
                        const addressInformation = formatAddress(address_components);

                        onOptionSelected && onOptionSelected(addressInformation);
                    }
                }
            );
        } catch (e) {
            // console.log(e);
        }
    };

    const formatAddress = (address_components: AddressComponentType[]): AddressComplete => {
        const obj: AddressComplete = { street: '', country: '', state: '', city: '', zipCode: '' };
        let street_number = '';
        let route = '';
        let neighborhood = '';

        address_components.forEach((comp) => {
            if (comp.types.includes(AddressTypes.zipCode)) {
                return (obj.zipCode = comp.long_name);
            }

            if (comp.types.includes(AddressTypes.country)) {
                return (obj.country = comp.short_name);
            }

            if (comp.types.includes(AddressTypes.state)) {
                return (obj.state = comp.short_name.replace(/\./g, ''));
            }

            if (comp.types.includes(AddressTypes.locality)) {
                return (obj.city = comp.long_name);
            } else if (comp.types.includes(AddressTypes.city) && !obj.city) {
                return (obj.city = comp.long_name);
            }

            if (comp.types.includes(AddressTypes.route)) {
                return (route = comp.long_name);
            }

            if (comp.types.includes(AddressTypes.street_number)) {
                return (street_number = `${comp.long_name} `);
            }

            if (comp.types.includes(AddressTypes.neighborhood)) {
                return (neighborhood = '');
            }
        });

        obj.street = route ? `${street_number}${route}${neighborhood}` : '';

        return obj;
    };

    return (
        <AutoCompleteField
            {...field}
            {...props}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                onChange && onChange(evt.target.value);
                getPlacePredictions({ input: evt.target.value });
            }}
            options={mapPredictionsToDHLOptions(placePredictions)}
            renderOption={_renderPredictions}
            onOptionSelected={_onPredictionTapped}
            onBlur={handleBlur}
            className={`${className}`}
            validations={validations}
        />
    );
};

export default AutoCompleteDHL;
