import { ForteException } from '@cargos/sprintpay-models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import * as Requests from '../../api/request/echeck-request';
import { EcheckType, eCheckType } from '../../types/sprintpay';
import { echecksActions, handlerErrors, paymentMethodsActions } from '../actions';
import { handlerForteError } from '../errors/forte-error-handler';
import { getPaymentMethods } from '../paymentsMethods/actions';
import { PaymentMethods } from '../paymentsMethods/types';

export const getEchecks = createAsyncThunk<void, void>('echecks/getEchecks', async (props, { dispatch }) => {
    try {
        dispatch(echecksActions.echecksLoading(true));

        const response = Requests.getEchecks()
            .pipe(
                finalize(() => {
                    response && response.unsubscribe();
                })
            )
            .subscribe(
                (echecks: PaymentMethods<EcheckType[]>) => {
                    dispatch(echecksActions.getEchecksSuccess(echecks));
                },
                (e) => {
                    dispatch(echecksActions.getEchecksError(e));
                }
            );
    } catch (e) {
        dispatch(echecksActions.getEchecksError(e));
    }
});

export const updateEchecks = createAsyncThunk<void, void>('echecks/updateEchecks', async (props, { dispatch }) => {
    try {
        dispatch(echecksActions.getEcheckUploading(true));

        dispatch(getEchecks());
    } catch (e) {
        dispatch(echecksActions.getEchecksError(e));
    }
});

export const deleteEcheck = createAsyncThunk<void, EcheckType>(
    'echecks/deleteEcheck',
    async (paymentToken, { dispatch }) => {
        try {
            dispatch(echecksActions.deletingEcheck(true));

            const response = Requests.deleteECheck(paymentToken)
                .pipe(
                    finalize(() => {
                        response && response.unsubscribe();
                    })
                )
                .subscribe(
                    (data) => {
                        if (data) {
                            dispatch(echecksActions.deleteEchecksSuccess(paymentToken));
                            dispatch(paymentMethodsActions.deleteEchecksSuccess(paymentToken));
                        }
                    },
                    async (error: unknown) => {
                        const errors = handlerErrors(error, dispatch);

                        dispatch(echecksActions.deleteEcheckError(errors));
                    }
                );
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(echecksActions.deleteEcheckError(errors));
        }
    }
);

export const saveEcheck = createAsyncThunk<void, eCheckType>('echecks/saveEcheck', async (echeck, { dispatch }) => {
    try {
        dispatch(echecksActions.echecksLoading(true));

        const response = Requests.saveEcheck(echeck)
            .pipe(
                catchError((error) => {
                    const e = handlerForteError(ForteException.fromJson(error));

                    return throwError(e);
                }),
                finalize(() => {
                    response && response.unsubscribe();
                })
            )
            .subscribe(
                (data) => {
                    if (data) {
                        dispatch(echecksActions.saveEcheckSuccess(true));
                        dispatch(getPaymentMethods(false));
                    }
                },
                async (error) => {
                    const errors = handlerErrors(error, dispatch);

                    dispatch(echecksActions.saveEcheckError(errors));
                }
            );
    } catch (error) {
        const errors = handlerErrors(error, dispatch);

        dispatch(echecksActions.saveEcheckError(errors));
    }
});
