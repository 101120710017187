import { Text } from '@dhl-official/react-ui-library';
import { useState } from 'react';
import { useInterval } from '../../../hooks';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';

export default function Countdown({
    startingMinutes = 1,
    startingSeconds = 0,
}: {
    startingMinutes?: number;
    startingSeconds?: number;
}) {
    const [mins, setMinutes] = useState(startingMinutes);
    const [secs, setSeconds] = useState(startingSeconds);

    useInterval(() => {
        if (secs > 0) {
            setSeconds(secs - 1);
        }
        if (secs === 0) {
            if (mins > 0) {
                setMinutes(mins - 1);
                setSeconds(59);
            }
        }
    }, 1000);

    return (
        <Text size={TEXT_SIZES['MD']} weight={TEXT_WEIGHTS[400]} className='ms-1 mb-4'>
            {' '}
            {mins}:{secs < 10 ? `0${secs}` : secs}
        </Text>
    );
}
