import { GridCell, InputField } from '@dhl-official/react-ui-library';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { PhoneInputProps } from './phone-input-types';

const PhoneInput = (props: PhoneInputProps, ref: ForwardedRef<PropsWithChildren<PhoneInputProps>>) => {
    return (
        <GridCell spanColumns={[12, 6, 6]} isOverflowHidden={false}>
            <InputField
                id={props.id}
                name={props.name}
                validation={props.validations}
                variant={props.variant}
                value={props.value}
                onChange={props.field.onChange}
                onBlur={props.field.onBlur}
                inputRef={ref}
                className={`input__dhl-mask w-100 ${props.className}`}
            />
        </GridCell>
    );
};

export default forwardRef(PhoneInput);
