import { DropdownField } from '@dhl-official/react-ui-library';
import React from 'react';
import { INPUTFIELD_VALIDATION_TYPES } from '../../../types';
import { SelectProps } from './select-types';

const SelectDHL = (props: SelectProps) => {
    const { field, form, className = '', children } = props;

    const { touched, errors } = form;
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: INPUTFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    return (
        <DropdownField {...field} {...props} validation={validations} className={`input__dhl ${className}`}>
            {children}
        </DropdownField>
    );
};

export default SelectDHL;
