import { GridContainer } from '@dhl-official/react-ui-library';
import { Description } from '../description';
import { CreditCardGuestTabProps } from './credit-card-guest-tab-types';
import { CartGuestForm } from './formGuest/form';

export const CreditCardGuestTab = ({ creditCard, onSubmit, getFormStatus, innerRef }: CreditCardGuestTabProps) => {
    return (
        <GridContainer
            columns={1}
            isFullWidth
            className='d-flex flex-column payment-methods__tab paypal-tab__container'
        >
            {creditCard?.active ? (
                <CartGuestForm onSubmit={onSubmit} getFormStatus={getFormStatus} innerRef={innerRef} />
            ) : (
                <Description>
                    No Credit Card available for this account. Please contact{' '}
                    <a href='mailto:collections@cargosprint.com'>collections@cargosprint.com.</a>
                </Description>
            )}
        </GridContainer>
    );
};
