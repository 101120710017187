import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';

const DescriptionModal = ({
    size = TEXT_SIZES['3XL'],
    weight = TEXT_WEIGHTS[200],
    className = '',
    children,
    ...restProps
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
    return (
        <Text isParagraph size={size} weight={weight} className={`my-4 ${className}`} {...restProps}>
            {children}
        </Text>
    );
};

export default DescriptionModal;
