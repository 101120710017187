import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CreditCardPaymentTokenType, CreditCardType } from '../../types/sprintpay';
import { ErrorsType } from '../errors/types';
import { PaymentMethods } from '../paymentsMethods/types';
import { CardsStore } from './types';

const initialState: CardsStore = {
    cards: [],
    loading: false,
    deleted: false,
    added: false,
    errors: null,
    deleting: false,
};

const creditCardSlice = createSlice({
    name: 'creditCard',
    initialState,
    reducers: {
        getCardsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
            state.added = false;
            state.errors = null;
        },
        deletingCard: (state, action: PayloadAction<boolean>) => {
            state.deleting = true;
            state.deleted = false;
            state.errors = null;
        },
        deleteCreditCardSuccess: (state, action: PayloadAction<CreditCardPaymentTokenType>) => {
            state.deleted = true;
            state.deleting = false;
            state.loading = false;
            state.errors = null;
        },
        deleteCreditCardError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.deleting = false;
            state.loading = false;
            state.errors = action.payload;
        },
        getCardsSuccess: (state, action: PayloadAction<PaymentMethods<CreditCardType[]>>) => {
            state.cards = action.payload.value;
            state.loading = false;
        },
        saveCreditCardSuccess: (state, action: PayloadAction<boolean>) => {
            state.added = action.payload;
            state.loading = false;
            state.errors = null;
        },
        saveCreditCardError: (state, action: PayloadAction<ErrorsType | null>) => {
            state.errors = action.payload;
            state.loading = false;
        },
        resetCreditCardHandler: () => {
            return { ...initialState };
        },
    },
});

export const { actions: creditCardActions } = creditCardSlice;
export const { reducer: creditCardReducer } = creditCardSlice;
