import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { authActions } from '../auth/reducers';
import { ErrorsType } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlerErrors = (error: any, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): ErrorsType | null => {
    try {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                if (error.response.status === 401) {
                    return logout(dispatch);
                }

                return reviewErrors(error.response?.data.errors, error.response.status);
            } else if (error.request) {
                return reviewErrors([], 400);
            }
        } else if (error?.errors) {
            return reviewErrors(error.errors, 400);
        }

        return reviewErrors(error.errors);
    } catch (error) {
        return reviewErrors([]);
    }
};

const reviewErrors = (errors: string[], code?: number): ErrorsType => ({
    code,
    errors: !!errors?.length ? errors : ['Oops! An unknown error ocurred. Please try again.'],
});

const logout = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    dispatch(authActions.forceLogout(true));

    return null;
};
