import { GridContainer } from '@dhl-official/react-ui-library';
import { selectCustomer } from '../../selectors';
import { useAppSelector } from '../../store';
import { SideBar } from './sideBar';
import { DHLTable } from './table';

export const ShipmentDetailsScreen = () => {
    const customer = useAppSelector(selectCustomer);

    return (
        <GridContainer columns={1} isFullWidth className='shipment__main'>
            <GridContainer className='shipment__container' columns={12} rowGap='normal' isFullWidth>
                {!customer.loading &&
                    !!customer.search &&
                    !customer.errors?.errors.length &&
                    !customer.search?.is_valid_credit_note && <SideBar />}
                <DHLTable data={customer.search} loading={customer.loading} error={customer.errors?.errors[0]} />
            </GridContainer>
        </GridContainer>
    );
};
