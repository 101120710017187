import { AuthTokensType, CurrentTime } from '../../store/auth/types';
import { RequestAuth, RequestAuthWithoutToken, RequestCard } from '../config';
import { addHeader } from '../interceptors/header-interceptor';
import * as Url from '../urls';

export const login = async (
    email: string,
    password: string,
    authorizationTokenId?: number
): Promise<AuthTokensType> => {
    if (authorizationTokenId) {
        addHeader(RequestAuthWithoutToken, 'guest-token', authorizationTokenId);
    }

    const { data } = await RequestAuthWithoutToken({
        url: `${Url.login}?source=${process.env.REACT_APP_SOURCE}`,
        method: 'POST',
        data: {
            username: email,
            password,
        },
    });

    return data as AuthTokensType;
};

export const loginAsGuest = async (): Promise<AuthTokensType> => {
    const { data } = await RequestAuthWithoutToken({
        url: `${Url.loginAsGuest}?source=${process.env.REACT_APP_SOURCE}`,
        method: 'GET',
    });

    return data as AuthTokensType;
};

export const refreshToken = async (token: string) => {
    const { data } = await RequestAuthWithoutToken({
        url: `${Url.refreshToken}?token=${token.replace(/["]+/g, '')}`,
        method: 'POST',
    });

    return data;
};

export const recoveryPassword = async (email: string) => {
    const { data } = await RequestAuthWithoutToken({
        url: Url.recoverPassword,
        method: 'POST',
        data: {
            email,
            source: 'sprintpay',
        },
    });

    return data;
};

export const currentTime = async () => {
    const { data } = await RequestAuthWithoutToken({
        url: Url.currentTime,
        method: 'GET',
    });

    return data as CurrentTime;
};

export const braintreeClientToken = async () => {
    const { data } = await RequestCard({
        url: Url.clientToken,
        method: 'GET',
    });

    return data;
};

export const validateEmail = async (contact: string) => {
    const {
        data,
    }: {
        data: {
            mfaUuid: string;
        };
    } = await RequestAuth({
        url: Url.mfaRequest,
        method: 'POST',
        data: {
            mfaType: 'EMAIL',
            module: 'POSTMAN',
            templateCode: 'DHL_AVIATION_MFA',
            contact,
        },
    });

    return data;
};

export const validateCustomerEmail = async (contact: string) => {
    const {
        data,
    }: {
        data: {
            content: string;
            success: boolean;
        };
    } = await RequestAuthWithoutToken({
        url: `${Url.validateCustomerEmail}?email=${contact}`,
        method: 'GET',
    });

    return data;
};

export const validatePasscode = async (mfaUuId: string, mfaCode: string) => {
    const {
        data,
    }: {
        data: {
            isVerified: boolean;
        };
    } = await RequestAuth({
        url: Url.mfaVerify,
        method: 'POST',
        data: {
            mfaUuId,
            mfaCode,
        },
    });

    return data;
};
