import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { DescriptionProps } from './description-types';

const Description = ({ children, className }: DescriptionProps) => (
    <Text size={TEXT_SIZES['MD']} weight={TEXT_WEIGHTS[700]} className={className}>
        {children}
    </Text>
);

export default Description;
