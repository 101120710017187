import { Text } from '@dhl-official/react-ui-library';
import { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { GlobalModal } from '..';
import { selectUIReducer } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { uiActions } from '../../store/actions';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../types';
import { COLORS } from '../../types/@dhl-official:react-ui-library/constants';
import { GlobalModalProps } from '../common/modal/types';
import ProgressProvider from './progress-provider';

interface ProgressLoaderModalProps extends GlobalModalProps {
    total: number;
}

export const ProgressLoaderModal = ({ isOpen, onCloseModal, description, total }: ProgressLoaderModalProps) => {
    const dispatch = useAppDispatch();
    const uiReducer = useAppSelector(selectUIReducer);
    const [valueEnd, setValueEnd] = useState(0);
    const [valueStart, setValueStart] = useState(0);
    const [blockModal, setBlockModal] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        if (uiReducer.transactions) {
            const percentage = parseFloat(((uiReducer.transactions * 100) / total).toFixed(0));

            const percentageCompleted = uiReducer.transactions === total - 3 ? 100 : percentage;

            setValueEnd((prevValue) =>
                prevValue === 100 ? 100 : percentageCompleted > 100 ? 100 : percentageCompleted
            );
        }
    }, [uiReducer.transactions, valueStart]);

    useEffect(() => {
        if (total < 6 && isOpen) {
            setBlockModal(true);
            setValueStart(100);
            setValueEnd(100);
        }
    }, [total, isOpen]);

    const _onCloseModal = useCallback(async () => {
        await onCloseModal();

        dispatch(uiActions.resetTransaction());
    }, [dispatch, uiActions.resetTransaction]);

    useEffect(() => {
        if (uiReducer.transactions === total && isOpen) {
            setIsCompleted(true);
        }
    }, [uiReducer.transactions, total]);

    return (
        <GlobalModal
            isOpen={isOpen && !blockModal}
            onCloseModal={_onCloseModal}
            shouldShowCloseButton={false}
            component={
                <div className='align-items-center d-flex flex-column'>
                    <div className='progress-loader mb-2'>
                        <ProgressProvider valueStart={valueStart} valueEnd={valueEnd}>
                            {(value: number) => (
                                <CircularProgressbar
                                    value={value}
                                    text={`${value}%`}
                                    strokeWidth={5}
                                    styles={buildStyles({
                                        pathColor: COLORS.HEXRED,
                                        textColor: COLORS.HEXRED,
                                        trailColor: 'rgba(0,0,0,0.1)',
                                    })}
                                />
                            )}
                        </ProgressProvider>
                    </div>
                    {!!description && (
                        <Text
                            isBlock
                            size={TEXT_SIZES['XL']}
                            weight={TEXT_WEIGHTS[700]}
                            className='align-self-center progress-loader__text'
                        >
                            {isCompleted ? 'Completed' : description}
                        </Text>
                    )}
                </div>
            }
        />
    );
};
