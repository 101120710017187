import { RadioField, Text } from '@dhl-official/react-ui-library';
import React, { useEffect, useState } from 'react';
import { TitleFormSection } from '../../..';
import { formatNumberToDHL } from '../../../../helpers/custom-format-number';
import { RADIOFIELD_SIZES } from '../../../../types';
import { PaymentMethodsType } from '../../../../types/sprintpay';
import { CargoCreditProps } from './credit-types';

export const CargoCredit = ({ onSelectCredit, cargoCredit, paymentMethodSelected }: CargoCreditProps) => {
    const [selectCredit, setCreditSelected] = useState(false);

    const buttonHandler = () => onSelectCredit(cargoCredit);

    useEffect(() => {
        if (paymentMethodSelected?.type === PaymentMethodsType.CARGOSPRINT_CREDIT) {
            setCreditSelected(true);
        }
    }, [paymentMethodSelected]);

    return (
        <>
            <TitleFormSection isParagraph={false} className='credit-card-tab__title'>
                Your credit available
            </TitleFormSection>
            {!!cargoCredit?.avaliableCredit ? (
                <RadioField
                    name='idCard'
                    id='idCard'
                    isChecked={selectCredit}
                    value={PaymentMethodsType.CARGOSPRINT_CREDIT}
                    isBlock
                    className='credit-card-tab__radio-credit-card'
                    size={RADIOFIELD_SIZES.MD}
                    onChange={buttonHandler}
                >
                    {`$ ${formatNumberToDHL(cargoCredit.avaliableCredit)} USD`}
                </RadioField>
            ) : (
                <Text>
                    You have no available SprintPay credit. Please contact{' '}
                    <a href='mailto:collections@cargosprint.com'>collections@cargosprint.com.</a>
                </Text>
            )}
        </>
    );
};
