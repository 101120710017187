import { Image } from '@dhl-official/react-ui-library';
import logo_dhl from './../../../assets/images/logo_dhl.png';

export const Header = () => {
    return (
        <header className='header__container'>
            <Image src={logo_dhl} alt='DHL - Excellence. Simply. Delivered' className='header__logo' />
        </header>
    );
};
