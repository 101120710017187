import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { INPUTFIELD_VALIDATION_TYPES } from '../../../types';
import CustomPhoneInput from './phone-input';
import { PhoneInputDHLProps } from './phone-input-dhl-types';
import PhoneCountrySelect from './phone-select-input';

export const PhoneInputDHL = (props: PhoneInputDHLProps) => {
    const { field, form, className = '', showFlagIcon = true } = props;

    const { touched, errors } = form;
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: INPUTFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    return (
        <PhoneInput
            id={props.id}
            variant={props.variant}
            field={field}
            className={`${showFlagIcon ? 'flag-enabled' : ''} ${className}`}
            validations={validations}
            value={field.value}
            onChange={field.onChange}
            focusInputOnCountrySelection={false}
            inputComponent={CustomPhoneInput}
            countrySelectComponent={(Inputprops) => (
                <PhoneCountrySelect {...Inputprops} {...props} showFlagIcon={true} />
            )}
            defaultCountry='US'
            international
            flagIcon={false}
        />
    );
};
