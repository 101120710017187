import { useCallback, useEffect, useState } from 'react';
import { mapInvoicesToPayments } from '../helpers';
import { selectCartReducer } from '../selectors';
import { useAppDispatch, useAppSelector } from '../store';
import { addPaymentsRequest, cartActions } from '../store/actions';
import { ShipmentDetailsType } from '../store/customer/types';

interface UseAddInvoicesToCartType {
    showError: boolean;
    error: string;
    setShowErrors: (showError: boolean) => void;
    resetError: () => void;
    addPaymentsRequestToCart: (shipmentDetailsType?: ShipmentDetailsType | null, notificationEmail?: string) => void;
    processCompleted: boolean;
    addingPayments: boolean;
}

export const useAddInvoicesToCart = (): UseAddInvoicesToCartType => {
    const dispatch = useAppDispatch();
    const [showError, setShowErrors] = useState(false);
    const [error, setError] = useState('Oops! An unknown error ocurred. Please try again.');
    const [addingPayments, setAddingPayments] = useState(false);
    const [processCompleted, setProcessCompleted] = useState(false);

    const cart = useAppSelector(selectCartReducer);

    useEffect(() => {
        if (cart.errors && !!cart.errors?.errors.length && !showError) {
            setError(cart.errors.errors[0]);

            setShowErrors(true);
        }
    }, [cart.errors, showError]);

    const addPaymentsRequestToCart = useCallback(
        async (shipmentDetails: ShipmentDetailsType | null = null, notificationEmail = '') => {
            try {
                setShowErrors(false);
                setAddingPayments(true);
                const countInv = shipmentDetails?.list_invoices.filter((item) => item.added && !item.is_fully_paid);

                const payments = mapInvoicesToPayments({
                    invoices: countInv || [],
                    vendor: shipmentDetails?.vendor || '',
                    notificationEmail,
                    account_name: shipmentDetails?.account_name,
                    glCode: shipmentDetails?.glCode,
                    station: shipmentDetails?.station,
                });

                await dispatch(addPaymentsRequest(payments));

                shipmentDetails && dispatch(cartActions.invoiceAdded(shipmentDetails));

                setAddingPayments(false);
                setProcessCompleted(true);
            } catch (e: unknown) {
                setError('Oops! An unknown error ocurred. Please try again.');

                setShowErrors(true);
            }
        },
        [dispatch]
    );

    const resetError = () => setError('');

    return {
        showError,
        setShowErrors,
        error,
        resetError,
        addPaymentsRequestToCart,
        processCompleted,
        addingPayments,
    };
};
