import { TextAreaField } from '@dhl-official/react-ui-library';
import React from 'react';
import { INPUTFIELD_VALIDATION_TYPES } from '../../../types';
import { TextAreaProps } from './text-area-types';

export const TextAreaDHL = (props: TextAreaProps) => {
    const { field, form, className = '' } = props;

    const { touched, errors } = form;
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: INPUTFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    return <TextAreaField {...field} {...props} validation={validations} className={`input__dhl ${className}`} />;
};
