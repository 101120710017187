import { ButtonGroup, Modal } from '@dhl-official/react-ui-library';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ButtonDHL, ErrorApp } from '../../../components';
import InputDHL from '../../../components/common/input';
import { selectCustomer } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { invoiceSubscribe } from '../../../store/customer/actions';
import { COLORS } from '../../../styles/abstracts/colors';
import {
    ALIGNMENTS,
    BUTTONGROUP_ORIENTATION,
    BUTTON_SIZES,
    BUTTON_TYPES,
    BUTTON_VARIANTS,
    INPUTFIELD_TYPES,
    TEXT_SIZES,
} from '../../../types';
import DescriptionModal from './description';
import { IconModal } from './icon';
import { FormSubscribeValues, IconTypes, SubscribeModalProps } from './types';
import { validateSubscribeForm } from './validate';

export const SubscribeModal = ({
    loading = false,
    isOpen,
    onCloseModal,
    lefttButtonVariant = BUTTON_VARIANTS.PRIMARY,
    leftButtonDisabled = false,
    leftButtonLoading = true,
    buttonGroupOrder = ALIGNMENTS.CENTER,
    iconType = IconTypes.IconCancelCircle,
    showIcon = true,
    shouldShowCloseButton = true,
    isStatic = true,
    error,
    initialValues,
}: SubscribeModalProps) => {
    const dispatch = useAppDispatch();
    const customer = useAppSelector(selectCustomer);

    const _closeModal = () => !loading && !customer.subscripting && onCloseModal && onCloseModal();

    const onSubmit = (values: FormSubscribeValues) => {
        dispatch(invoiceSubscribe({ invoiceNumber: values.number, emails: values.email }));
    };

    return (
        <Modal
            isOpen={isOpen}
            closeButtonAriaLabel='Closes the Modal'
            contentAriaLabel='Subscription'
            onClose={_closeModal}
            shouldShowCloseButton={shouldShowCloseButton}
            isStatic={isStatic}
            className='global-modal__container'
            overlayColor={COLORS['black-20']}
        >
            <div className='global-modal'>
                {showIcon && <IconModal iconType={iconType} />}
                <div className='global-modal__content'>
                    <DescriptionModal size={TEXT_SIZES['MD']}>
                        Dear Customer, the invoice/MAWB number you have queried has yet to be invoiced by DHL. You may
                        wish to contact DHLUSAACS-billing@dhl.com or subscribe for an email alert when your invoice is
                        available.
                    </DescriptionModal>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validate={(values) => validateSubscribeForm(values)}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ handleSubmit }: FormikProps<FormSubscribeValues>) => (
                            <Form
                                className='login__form'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Field
                                    id='number'
                                    name='number'
                                    isBlock
                                    variant={{
                                        label: 'Invoice/MAWB',
                                        placeholder: 'Invoice/MAWB',
                                        type: INPUTFIELD_TYPES.STATIC,
                                    }}
                                    component={InputDHL}
                                    disabled
                                />
                                <Field
                                    id='email'
                                    name='email'
                                    isBlock
                                    variant={{
                                        label: 'Email ID',
                                        placeholder: 'Email ID',
                                        type: INPUTFIELD_TYPES.STATIC,
                                    }}
                                    component={InputDHL}
                                />
                                {error && <ErrorApp>{error}</ErrorApp>}
                                <ButtonGroup
                                    size={BUTTON_SIZES.MD}
                                    orientation={BUTTONGROUP_ORIENTATION.HORIZONTAL}
                                    ariaLabel='Choose an option'
                                    isBlock
                                    className={`mt-4 global-modal__button-group
                            ${buttonGroupOrder === ALIGNMENTS.LEFT && 'justify-content-end'}
                            ${buttonGroupOrder === ALIGNMENTS.RIGHT && 'justify-content-start'}
                            ${buttonGroupOrder === ALIGNMENTS.CENTER && 'justify-content-center'}`}
                                >
                                    <ButtonDHL
                                        loading={leftButtonLoading && (loading || customer.subscripting)}
                                        variant={lefttButtonVariant}
                                        isDisabled={leftButtonDisabled}
                                        type={BUTTON_TYPES.SUBMIT}
                                        showLoadingIcon
                                    >
                                        Subscribe
                                    </ButtonDHL>
                                    <ButtonDHL variant={BUTTON_VARIANTS.OUTLINE} onClick={_closeModal}>
                                        Cancel
                                    </ButtonDHL>
                                </ButtonGroup>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};
