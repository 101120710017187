import { AwbToBilledToType, InvoicesType, ShipmentDetailsType } from '../store/customer/types';
import { DHLPaymentRequestType, PaymentRequestType, PaymentType } from '../types/sprintpay';
import { cleanNumber } from './clean-number';

interface MapInvoiceToPaymentType {
    invoice: InvoicesType;
    vendor?: string;
    notificationEmail?: string;
    account_name?: string;
    glCode?: string;
    station?: string;
    paymentType?: string;
}
interface MapInvoicesToPaymentsType extends Omit<MapInvoiceToPaymentType, 'invoice'> {
    invoices: InvoicesType[];
}

export const mapInvoicesToPayments = ({
    invoices,
    vendor,
    notificationEmail,
    account_name,
    glCode,
    station,
    paymentType,
}: MapInvoicesToPaymentsType): PaymentType[] => {
    if (!(invoices.length > 0)) {
        return [];
    }

    return invoices.map((invoice) => {
        const payment = buildPaymentRequest({
            invoice,
            vendor,
            notificationEmail,
            account_name,
            glCode,
            station,
            paymentType,
        });

        return payment;
    });
};

export const buildPaymentRequest = ({
    invoice,
    vendor,
    notificationEmail,
    account_name,
    glCode,
    station,
    paymentType,
}: MapInvoiceToPaymentType): PaymentType => {
    return {
        amount: parseFloat(cleanNumber(invoice.amount.toString())),
        awb: invoice.mawb,
        hawb: invoice.hawbId,
        paidTo: vendor,
        notificationEmail,
        customerReference: account_name,
        source: process.env.REACT_APP_SOURCE,
        paymentType: paymentType ?? 'Invoice-Charges',
        origin: invoice.org,
        destination: invoice.dst,
        externalData: {
            source: process.env.REACT_APP_SOURCE,
            vendor,
            glCode,
            date: invoice.date,
            station,
            createdDate: invoice.created_date,
        },
    };
};

export const mapDeletedInvoicesToPayments = (
    invoices: InvoicesType[] = [],
    billed_to_arr: AwbToBilledToType[] = [],
    notificationEmail = '',
    customerReference = ''
): PaymentType[] => {
    if (!(invoices.length > 0)) {
        return [];
    }

    const payments: PaymentType[] = invoices.map((invoice) => ({
        amount: parseFloat(cleanNumber(invoice.amount)),
        awb: invoice.mawb,
        hawb: invoice?.hawbId,
        paidTo: billed_to_arr.length > 0 ? billed_to_arr.filter((b) => b.mawb === invoice.mawb)[0].vendor : '',
        notificationEmail,
        customerReference,
    }));

    return payments;
};

export const mapInvoicesToPaymentsRequest = (
    invoices: InvoicesType[] = [],
    dhlPaymentRequests: DHLPaymentRequestType[] = [],
    notificationEmail = '',
    customerReference = ''
): PaymentRequestType[] => {
    if (!(invoices.length > 0)) {
        return [];
    }

    const getIdAndSource = (awb: string) => {
        let dhlPaymentId = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let dhlSource: any = null;

        dhlPaymentRequests.filter((p) => {
            p.fee_content.filter((f) => {
                if (f.awb === awb) {
                    dhlPaymentId = f.id;
                    dhlSource = f.source;
                }
                return true;
            });
            return true;
        });

        return { dhlPaymentId, dhlSource };
    };

    const payments: PaymentRequestType[] = invoices.map((invoice) => ({
        id: getIdAndSource(invoice.mawb).dhlPaymentId,
        source: getIdAndSource(invoice.mawb).dhlSource?.name,
        amount: parseFloat(cleanNumber(invoice.amount)),
        awb: invoice.mawb,
        hawb: invoice?.hawbId,
        notificationEmail,
        customerReference,
    }));

    return payments;
};

export const mapCreditNoteToPayments = (creditNote: ShipmentDetailsType): PaymentType | null => {
    if (!creditNote) {
        return null;
    }
    const payments: PaymentType = {
        amount: Number(creditNote.credit_note_amount) * -1,
        awb: creditNote.IATA_code,
        hawb: creditNote.IATA_code,
        paidTo: creditNote.vendor,
        source: process.env.REACT_APP_SOURCE,
        paymentType: 'Invoice-Charges',
        externalData: {
            source: process.env.REACT_APP_SOURCE,
            vendor: creditNote.vendor,
            glCode: creditNote.glCode,
            date: creditNote.created_date,
            station: creditNote.station,
            createdDate: creditNote.created_date,
        },
    };

    return payments;
};
