import { GridContainer } from '@dhl-official/react-ui-library';
import { Outlet } from 'react-router-dom';
import NavBarDHL from '../../components/common/nav-bar';

const LayoutWithNavbar = () => {
    return (
        <GridContainer columns={1} isFullWidth className='layout__main' rowGap={'0px'}>
            <NavBarDHL />
            <GridContainer columns={1} isFullWidth className='layout__body'>
                <Outlet />
            </GridContainer>
        </GridContainer>
    );
};

export default LayoutWithNavbar;
