import { FormikErrors } from 'formik';
import { emailPattern, onlyLettersPattern } from '../../../../helpers/regex';
import { FormCreditCardValues } from './form-types';

export const validateCreditCardForm = (values: FormCreditCardValues) => {
    const { firstName, lastName, phoneNumber, email, street, country, state, city, zipCode } = values;
    const errors: FormikErrors<FormCreditCardValues> = {};

    if (!firstName) {
        errors.firstName = 'First name is required';
    } else if (!onlyLettersPattern.test(firstName)) {
        errors.firstName = 'Please enter a valid name';
    }
    if (!lastName) {
        errors.lastName = 'Last name is required';
    } else if (!onlyLettersPattern.test(lastName)) {
        errors.lastName = 'Please enter a valid lastName';
    }
    if (!phoneNumber) {
        errors.phoneNumber = 'Phone number is required';
    }
    if (!email) {
        errors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
        errors.email = 'Please enter a valid email address';
    }
    if (!street) {
        errors.street = 'Street is required';
    }
    if (!country) {
        errors.country = 'Country is required';
    }
    if (!state) {
        errors.state = 'State is required';
    }
    if (!city) {
        errors.city = 'City is required';
    }
    if (!zipCode) {
        errors.zipCode = 'Zip code is required';
    }

    return errors;
};
