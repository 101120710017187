import { IconChevronBack } from '@dhl-official/react-ui-library';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDHL } from '../..';
import { BUTTON_TYPES } from '../../../types';
import { BackButtonTypes } from './types';

export const BackButton = ({
    showIcon = true,
    useBackAction = true,
    text = 'Back',
    type = 'link',
    className = '',
    onClick,
}: BackButtonTypes) => {
    const [hidde, setDisabled] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (navigate.length < 2) {
            setDisabled(true);
        }
    }, [navigate]);

    const onClickGoBack = useCallback(
        async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onClick && (await onClick(event));

            useBackAction && navigate(-1);
        },
        [navigate, onClick, useBackAction]
    );

    const getComponent = (type: string) => {
        switch (type) {
            case 'link':
                return (
                    <div onClick={onClickGoBack} className={`back-button eTGHAw ${className}`}>
                        {showIcon && (
                            <IconChevronBack name='back' title='back' width={10} height={10} className='me-2' />
                        )}
                        {text}
                    </div>
                );
            case 'button':
                return (
                    <ButtonDHL
                        onClick={onClickGoBack}
                        id='back'
                        name='back'
                        isBlock
                        type={BUTTON_TYPES.BUTTON}
                        className={className}
                    >
                        {text}
                    </ButtonDHL>
                );
        }
    };

    return <>{!hidde && getComponent(type)}</>;
};
