/**
 *
 *  SPRINTPAY AUTH REQUESTS
 *
 * */
// Login
export const login = 'login';

// Login
export const loginAsGuest = 'guest';

// Recover password
export const recoverPassword = 'recover_password';

// Refresh token
export const refreshToken = 'refresh_token';

// Get currentTime server
export const currentTime = 'current_time';

// Get braintree client token
export const clientToken = 'braintree/clientToken';

/**
 *
 *  CUSTOMER REQUESTS
 *
 * */
// Invoice number request
export const getDHLInvoice = 'get_dhl_invoice_data';

// AWB number request
export const getDHLAWB = 'get_dhl_invoice_data';

// Download invoice document request
export const downloadInvoiceDocument = 'get_dhl_invoices_pdf_file';

// Download invoice document request
export const invoiceSubscribe = 'invoice_subscribe';

/**
 *
 *  PAYMENTS REQUESTS
 *
 * */
// Add payment request
export const addPaymentRequest = 'add_payment_request';

// Get Cart Bill
export const getCartBill = 'get_cart_bill';

// Delete cart
export const deleteCart = 'delete_cart';

/**
 *
 *  PAYMENTS METHODS
 *
 * */
// Get payment methods
export const getPaymentMethods = 'get_payment_methods';

export const getBalance = 'getBalance';

// Save payment method (as authenticated customer)
export const savePaymentMethod = 'braintree/savePaymentMethod';

/**
 *
 *  DHL PAYMENTS REQUESTS
 *
 * */
// Update payment request
export const updateDHLPaymentRequest = 'edit_payment_request';

// Get cart
export const getDHLCart = 'get_cart';

// Get Cart Bill
export const getDHLCartBill = 'get_cart_bill';

// Delete Invoice From Cart
export const deleteDHLInvoiceFromCart = 'delete_one_invoice_from_cart';

// Delete One AWB Invoice From Cart
export const deleteDHLOneAwbFromCart = 'delete_one_awb_from_cart';

// Pay
export const payDHL = 'pay';

// Pay URL for guest users
export const payCart = 'pay_cart';

// Download receipt document request
export const receiptByInvoices = 'receipt_by_invoices';

// Save comments
export const saveComments = 'remove_awb';

/**
 *
 *  2FA REQUESTS
 *
 * */
// Validate login
export const mfaRequest = 'mfa/request';

export const mfaVerify = 'mfa/verify';

export const validateCustomerEmail = 'validate_customer_email';
