import { GlobalModal } from '..';
import { BUTTON_VARIANTS, TEXT_SIZES } from '../../types';
import { GlobalModalProps } from '../common/modal/types';

type InvalidCustomerModalProps = GlobalModalProps;

export const InvalidCustomerModal = ({ isOpen, onCloseModal, rightButtonText = 'OK' }: InvalidCustomerModalProps) => {
    return (
        <GlobalModal
            isOpen={isOpen}
            description='Dear Customer, we are having trouble locating your invoice/MAWB, please check again your customer number and invoice/MAWB number and resubmit. You may contact DHLUSAACS-billing@dhl.com'
            descriptionSize={TEXT_SIZES['LG']}
            rightButtonText={rightButtonText}
            rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
            onCloseModal={onCloseModal}
        />
    );
};
