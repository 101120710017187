export const cleanNumber = (number: string) => {
    if (number) {
        const newNumber = number.replace(/[^\d.]/g, '');

        if (newNumber.length > 0 && newNumber !== 'NaN') {
            return newNumber;
        }
    }

    return '0';
};
