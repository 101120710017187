import { PaymentMethodName } from '@cargos/sprintpay-models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import * as Requests from '../../api/request/cart-request';
import { ThunkConfig, store } from '../../store';
import {
    PayPalPaymentDataRequestType,
    PaymentMethodsType,
    PaymentRequestType,
    PaymentTokenDataRequest,
    PaymentType,
} from '../../types/sprintpay';
import { getCargoCreditBalance, handlerErrors, paymentMethodsActions } from '../actions';
import { ErrorsType } from '../errors/types';
import { addPaymentRequest } from './actions-functions';
import { cartActions } from './reducers';
import {
    GetCartBillReturnType,
    GetDHLCartReturnType,
    GuestPayThunkProps,
    PayAsGuestThunkProps,
    PayThunkProps,
    SaveCommentsThunkProps,
} from './types';

export const getDHLCart = createAsyncThunk<GetDHLCartReturnType, void, ThunkConfig<ErrorsType>>(
    'cart/getDHLCart',
    async (props, { dispatch, rejectWithValue }) => {
        try {
            let feeContents: PaymentRequestType[] = [];
            let response = await Requests.getDHLCart();

            response.map((data) => {
                feeContents = feeContents.concat(data.fee_content);
                return feeContents;
            });

            const sum = feeContents.reduce((sum: number, awb: PaymentRequestType) => sum + awb.amount, 0).toFixed(2);
            response = _.orderBy(response, ['awb'], ['asc']);

            return { dhlPaymentsRequest: response, sum: Number(sum) };
        } catch (error) {
            const errors = handlerErrors(error, dispatch);
            return rejectWithValue(errors);
        }
    }
);

export const getCartBill = createAsyncThunk<GetCartBillReturnType, PaymentMethodsType, ThunkConfig<ErrorsType>>(
    'cart/getCartBill',
    async (paymentMethodType, { dispatch, rejectWithValue }) => {
        try {
            const response = await Requests.getCartBill(paymentMethodType);

            const sum = response.cart
                .reduce(
                    (sum: number, fee) => sum + fee.fee_content.reduce((acc: number, awb) => acc + awb.amount, 0),
                    0
                )
                .toFixed(2);

            response.cart = response.cart.map((fee) => {
                return {
                    ...fee,
                    fee_content: _.orderBy(fee.fee_content, ['awb'], ['asc']),
                };
            });

            return { cartBill: response, sum: Number(sum) };
        } catch (error) {
            const errors = handlerErrors(error, dispatch);
            return rejectWithValue(errors);
        }
    }
);

export const getGuestPaymentToken = (paymentNonce: string): PaymentTokenDataRequest => {
    return {
        paymentMethod: {
            name: PaymentMethodsType.CREDIT_CARD,
        },
        paymentProcessor: {
            name: 'BRAINTREE',
        },
        token: paymentNonce,
    };
};

export const addPaymentsRequest = createAsyncThunk<void, PaymentType[]>(
    'cart/addPaymentsRequest',
    async (props, { dispatch }) => {
        try {
            dispatch(cartActions.addingPaymentRequest(true));

            const arrayPromises = props.map((element) => dispatch(addPaymentRequest(element)));

            await Promise.all(arrayPromises);

            dispatch(cartActions.addingPaymentRequest(false));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(cartActions.addingPaymentRequest(false));

            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const addPaymentNote = createAsyncThunk<void, PaymentType>(
    'cart/addPaymentNote',
    async (props, { dispatch }) => {
        try {
            dispatch(cartActions.addingPaymentNote(true));

            await dispatch(addPaymentRequest(props));

            dispatch(cartActions.addPaymentNoteSuccess());

            dispatch(cartActions.addingPaymentNote(false));
        } catch (error) {
            const errors = handlerErrors(error, dispatch);

            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const editPaymentRequest = createAsyncThunk<void, PaymentRequestType>(
    'cart/editPaymentRequest',
    async (props) => {
        try {
            await Requests.editPaymentRequest(props);
        } catch (error: unknown) {}
    }
);

export const editPaymentsRequest = createAsyncThunk<void, PaymentRequestType | PaymentRequestType[]>(
    'cart/editPaymentsRequest',
    async (props, { dispatch }) => {
        try {
            if (Array.isArray(props)) {
                const arrayPromises = props.map((element: PaymentRequestType) => dispatch(editPaymentRequest(element)));
                await Promise.all(arrayPromises);
            } else {
                await dispatch(editPaymentRequest(props));
            }
        } catch (error: unknown) {}
    }
);

export const payAsGuest = createAsyncThunk<void, PayAsGuestThunkProps>(
    'cart/payAsGuest',
    async (props, { dispatch }) => {
        const { clientCart, creditCardForm, paymentToken, customerReference, paymentNonce } = props;
        try {
            dispatch(cartActions.payingCart(true));

            const paymentMetaData = {
                firstName: creditCardForm.firstName,
                lastName: creditCardForm.lastName,
                fullName: creditCardForm.firstName + ' ' + creditCardForm.lastName,
                phoneNumber: creditCardForm.phoneNumber,
                customerReference: creditCardForm.reference,
                email: creditCardForm.email,
                city: creditCardForm.city,
                state: creditCardForm.state,
                country: creditCardForm.country,
                postalCode: creditCardForm.zipCode,
            };

            if (paymentToken.paymentMethod?.name !== PaymentMethodName.PAYPAL && creditCardForm) {
                const paymentMethod = PaymentMethodsType.CREDIT_CARD;
                const braintreePaymentDataRequest = {
                    firstName: creditCardForm.firstName,
                    lastName: creditCardForm.lastName,
                    fullName: creditCardForm.firstName + ' ' + creditCardForm.lastName,
                    phoneNumber: creditCardForm.phoneNumber,
                    customerReference: creditCardForm.reference,
                    paymentNonce,
                    email: creditCardForm.email,
                };
                const paymentToken = {
                    paymentMethod: {
                        name: PaymentMethodName.CREDIT_CARD,
                    },
                    paymentProcessor: {
                        name: 'BRAINTREE',
                    },
                    token: paymentNonce,
                };

                dispatch(
                    guestPay({
                        paymentToken,
                        clientCart,
                        paymentRequest: {
                            paymentMethod,
                            paymentNonce,
                            braintreePaymentDataRequest,
                        },
                        paymentMetaData,
                    })
                );
            } else if (paymentToken.paymentMethod?.name === PaymentMethodName.PAYPAL) {
                const payPalPaymentDataRequest = buildPaypalPaymentRequest();

                dispatch(
                    guestPay({
                        paymentToken,
                        clientCart,
                        paymentRequest: { payPalPaymentDataRequest },
                        paymentMetaData,
                    })
                );
            }
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const guestPay = createAsyncThunk<void, GuestPayThunkProps>('cart/guestPay', async (props, { dispatch }) => {
    const { paymentToken, clientCart, paymentRequest } = props;
    try {
        dispatch(cartActions.payingCart(true));

        const response = await Requests.guestPay(paymentToken, clientCart, paymentRequest);

        dispatch(cartActions.paymentSuccess(response));
        dispatch(paymentMethodsActions.paymentSuccess(response));
    } catch (error: unknown) {
        const errors = handlerErrors(error, dispatch);
        dispatch(cartActions.paymentError(errors));
    }
});

export const pay = createAsyncThunk<void, PayThunkProps>('cart/pay', async (props, { dispatch }) => {
    const { paymentToken, clientCart } = props;
    try {
        dispatch(cartActions.payingCart(true));
        let payPalPaymentDataRequest: PayPalPaymentDataRequestType;

        if (paymentToken.paymentMethod?.name === PaymentMethodName.PAYPAL) {
            payPalPaymentDataRequest = buildPaypalPaymentRequest();
        }

        const response = await Requests.pay(paymentToken, clientCart, {
            payPalPaymentDataRequest,
        });

        if (
            response.saleStatus === 'SUCCESS' &&
            paymentToken.paymentMethod?.name === PaymentMethodName.CARGOSPRINT_CREDIT
        ) {
            dispatch(getCargoCreditBalance());
        }
        dispatch(cartActions.paymentSuccess(response));
        dispatch(paymentMethodsActions.paymentSuccess(response));
    } catch (error: unknown) {
        const errors = handlerErrors(error, dispatch);
        dispatch(cartActions.paymentError(errors));
    }
});

export const buildPaypalPaymentRequest = (): PayPalPaymentDataRequestType => {
    const { total } = store.getState().cartReducer.cartBill;
    const { paypalPayload } = store.getState().braintreeReducer;

    return {
        accountType: 'checkout',
        amount: total,
        email: paypalPayload?.details.email,
        firstName: paypalPayload?.details.email,
        fullName: paypalPayload?.details?.shippingAddress?.recipientName,
        lastName: paypalPayload?.details.lastName,
        payerId: paypalPayload?.details.payerId,
        paymentNonce: paypalPayload?.nonce,
        phoneNumber: paypalPayload?.details.phone,
    };
};

export const deleteCart = createAsyncThunk<void, void>('cart/deleteCart', async (props, { dispatch }) => {
    try {
        dispatch(cartActions.deletingCart(true));

        await Requests.deleteCart();

        dispatch(cartActions.deleteCartSuccess());

        dispatch(cartActions.deletingCart(false));
    } catch (error: unknown) {}
});

export const deleteDHLInvoiceFromCart = createAsyncThunk<void, string>(
    'cart/deleteDHLInvoiceFromCart',
    async (invoiceNumber, { dispatch }) => {
        try {
            dispatch(cartActions.deletingDHLInvoiceFromCart(true));

            await Requests.deleteDHLInvoiceFromCart(invoiceNumber);

            dispatch(cartActions.deleteDHLInvoiceFromCartSuccess());

            dispatch(cartActions.deletingDHLInvoiceFromCart(false));
        } catch (error: unknown) {}
    }
);

export const deleteDHLOneAwbFromCart = createAsyncThunk<void, string>(
    'cart/deleteDHLOneAwbFromCart',
    async (mawb, { dispatch }) => {
        try {
            dispatch(cartActions.deletingDHLOneAwbFromCart(true));

            await Requests.deleteDHLOneAwbFromCart(mawb);

            dispatch(cartActions.deleteDHLOneAwbFromCartSuccess());

            dispatch(cartActions.deletingDHLOneAwbFromCart(false));
        } catch (error: unknown) {}
    }
);

export const saveComments = createAsyncThunk<void, SaveCommentsThunkProps>('cart/saveComments', async (props) => {
    const { comment, awbs } = props;
    try {
        await Requests.saveComments(comment, awbs);
    } catch (error: unknown) {}
});
