import { GridCell, GridContainer } from '@dhl-official/react-ui-library';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalModal } from '../../components';
import { KeysLocalStorageTypes } from '../../hooks';
import {
    selectAuthConfig,
    selectAuthErrors,
    selectCartReducer,
    selectCustomer,
    selectEmailValidation,
    selectPasscodeValidation,
} from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { customerActions, loginAsGuest, validateCustomerEmail, validatePasscode } from '../../store/actions';
import { authActions } from '../../store/auth/reducers';
import { BUTTON_VARIANTS, TEXT_SIZES } from '../../types';
import { SearchCustomerForm } from './form-email/form';
import { FormCustomerEmailValues } from './form-email/form-types';
import Header from './header';
import SideImage from './side-image';

const formInitialValues = { email: '', passcode: '' };

export const SearchCustomerScreen = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state }: any = useLocation();
    const [prevLocationLocal, setPrevLocationLocal] = useState(state?.prevLocation);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cart = useAppSelector(selectCartReducer);
    const authReducer = useAppSelector(selectAuthConfig);
    const emailValidation = useAppSelector(selectEmailValidation);
    const passcodeValidation = useAppSelector(selectPasscodeValidation);
    const customer = useAppSelector(selectCustomer);
    const [mfaUuId, setMfaUuId] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [showInvalidEmailModal, setShowInvalidEmailModal] = useState(false);
    const [initialValues] = useState<FormCustomerEmailValues>(formInitialValues);
    const errors = useAppSelector(selectAuthErrors);

    const onSubmitEmail = useCallback(
        ({ email }: FormCustomerEmailValues) => {
            setPrevLocationLocal(false);
            dispatch(validateCustomerEmail({ email }));
        },
        [dispatch]
    );

    /**
     * Redirect to next screen if is a valid email, if not, will display a modal
     */
    useEffect(() => {
        if (
            typeof emailValidation.isValidEmail == 'boolean' &&
            !emailValidation.validatingEmail &&
            !prevLocationLocal
        ) {
            if (emailValidation.isValidEmail && emailValidation.mfaUuId) {
                setMfaUuId(emailValidation.mfaUuId);
                setCustomerNumber(emailValidation.customerNumberApproved);
                dispatch(customerActions.setCustomerNumber(emailValidation.customerNumberApproved));
            } else if (
                !emailValidation.validatingEmail &&
                !emailValidation.isValidEmail &&
                authReducer.errors?.code === 404
            ) {
                setShowInvalidEmailModal(true);
            }
        }
    }, [emailValidation, authReducer.errors]);

    /**
     * Function to Submit the passcode and mfaUuId received with onSubmitEmail function
     */
    const onSubmitPasscode = useCallback(
        ({ passcode }: FormCustomerEmailValues) => {
            dispatch(validatePasscode({ mfaCode: passcode, mfaUuId }));
        },
        [dispatch, mfaUuId]
    );

    useEffect(() => {
        if (
            typeof passcodeValidation.isValidPasscode == 'boolean' &&
            !passcodeValidation.validatingPasscode &&
            !prevLocationLocal
        ) {
            if (passcodeValidation.isValidPasscode) {
                navigate(`/customer/${customerNumber}`);
            }
        }
    }, [passcodeValidation]);

    /**
     * Function to close invalid email modal
     */
    const onCloseInvaldEmailModal = useCallback(() => {
        setShowInvalidEmailModal(false);
    }, []);

    const onClickAnotherAccount = useCallback(() => {
        dispatch(authActions.resetEmailValidation());
    }, [dispatch]);

    /**
     * Login as guest user if there is no a token and is not logged
     */
    useEffect(() => {
        const getToken = async () => {
            const token = await sessionStorage.getItem(KeysLocalStorageTypes.TOKEN);

            if (!token) {
                dispatch(loginAsGuest());
            }
        };

        getToken();
    }, [dispatch]);

    useEffect(() => {
        dispatch(customerActions.resetSearchNumber());
        window.history.pushState(null, '');

        window.addEventListener('popstate', () => {
            window.history.pushState(null, '');
        });
    }, []);

    /**
     * This useEffect don't allow the user to search using a different customer number
     * while there is a payment request active in the cart
     */
    useEffect(() => {
        if (cart.dhlPaymentRequests.length > 0 && customer.customerNumber) {
            navigate(`/customer/${customer.customerNumber}`, { state: { prevLocation: false } });
        }
    }, [navigate, cart.dhlPaymentRequests, customer.customerNumber]);

    return (
        <GridContainer className='search-customer__main' columns={7} rowGap='normal' isFullWidth>
            <GridCell className='search-customer__side-image' spanColumns={[3, 3]}>
                <SideImage />
            </GridCell>
            <GridCell className='search-customer__container-form' spanColumns={[7, 4]}>
                <Header />
                <SearchCustomerForm
                    initialValues={initialValues}
                    onSubmitEmail={onSubmitEmail}
                    onSubmitPasscode={onSubmitPasscode}
                    loading={emailValidation.validatingEmail || passcodeValidation.validatingPasscode}
                    error={errors?.errors && errors?.code !== 404 ? errors.errors[0] : null}
                    isValidEmail={emailValidation.isValidEmail}
                    onClickAnotherAccount={onClickAnotherAccount}
                />
            </GridCell>
            <GlobalModal
                isOpen={showInvalidEmailModal}
                description='A DHL account was not found associated to this email address, please contact dhlusaacs-billing@dhl.com'
                descriptionSize={TEXT_SIZES.LG}
                rightButtonText='Ok'
                rightButtonVariant={BUTTON_VARIANTS.PRIMARY}
                onCloseModal={onCloseInvaldEmailModal}
            />
        </GridContainer>
    );
};
