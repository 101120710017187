import { Text } from '@dhl-official/react-ui-library';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { TitleSectionSummaryProps } from './title-section-types';

const TitleSectionSummary = ({
    size = TEXT_SIZES['MD'],
    weight = TEXT_WEIGHTS[700],
    className = '',
    children,
    ...restProps
}: TitleSectionSummaryProps) => {
    return (
        <Text isParagraph size={size} weight={weight} className={`mb-0 ${className}`} {...restProps}>
            {children}
        </Text>
    );
};

export default TitleSectionSummary;
