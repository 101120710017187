import { FormikErrors } from 'formik';
import { emailPattern } from '../../../helpers';
import { FormCustomerEmailValues } from './form-types';

export const validateEmailCustomer = (values: FormCustomerEmailValues, validatePasscode: boolean) => {
    const { email, passcode } = values;
    const passcodeLength = 6;
    const errors: FormikErrors<FormCustomerEmailValues> = {};

    if (!email) {
        errors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
        errors.email = 'Please enter a valid email address';
    }

    if (validatePasscode) {
        if (!passcode) {
            errors.passcode = 'Passcode is required';
        } else if (passcode.length !== passcodeLength) {
            errors.passcode = 'Please enter a valid passcode';
        }
    }

    return errors;
};
