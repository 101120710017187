import { DropdownField, GridCell } from '@dhl-official/react-ui-library';
import { OptionsSelect, PhoneCountrySelectProps } from './phone-select-types';

const PhoneCountrySelect = (props: PhoneCountrySelectProps) => {
    return (
        <GridCell spanColumns={[12, 6, 6]} isOverflowHidden={false} className='phone-input__container'>
            {props.showFlagIcon && (
                <div className='phone-input__flag-container'>
                    {props.iconComponent({
                        country: props.value || undefined,
                        label: props.value || 'International',
                    })}
                </div>
            )}
            <DropdownField
                id={`${props.id}-select`}
                name={props.field.name}
                value={props.value}
                label={props.label}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(value: any) =>
                    props.onChange(value.target.value !== 'undefined' ? value.target.value : 'US')
                }
                onClose={props.onBlur}
                onOpen={props.onFocus}
                className={`input__dhl-mask w-100 ${props.className}`}
            >
                {props.options.map((option: OptionsSelect, index: number) => (
                    <option key={index} value={option?.value}>
                        {option.label}
                    </option>
                ))}
            </DropdownField>
        </GridCell>
    );
};

export default PhoneCountrySelect;
