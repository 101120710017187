import { GridContainer } from '@dhl-official/react-ui-library';
import React from 'react';
import { PaymentMethodsType, SignetCreditType } from '../../../types/sprintpay';
import { Description } from '../description';
import { SignetCredit } from './credit';
import { SignetTabProps } from './signet-tab-types';

export const SignetTab = ({ setPaymentMethodSelected, signetCredit, paymentMethodSelected }: SignetTabProps) => {
    const onSelectSignetCredit = (credit?: SignetCreditType) =>
        setPaymentMethodSelected(credit, PaymentMethodsType.SIGNET);

    return (
        <GridContainer
            columns={1}
            isFullWidth
            className='d-flex flex-column payment-methods__tab credit-card-tab__container'
        >
            {signetCredit?.active ? (
                <SignetCredit
                    signetCredit={signetCredit?.value}
                    onSelectCredit={onSelectSignetCredit}
                    paymentMethodSelected={paymentMethodSelected}
                />
            ) : (
                <Description>No Signet credit available for this account yet</Description>
            )}
        </GridContainer>
    );
};
