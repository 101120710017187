import countries from './countries.json';

export const getCountryRegex = (country?: string): RegExp => {
    try {
        const countryInfo = countries.filter((c) => c.code === country);
        const countryRegex: RegExp = countryInfo[0] ? new RegExp(countryInfo[0].zipCode) : new RegExp('');

        return countryRegex;
    } catch (e) {
        return new RegExp('');
    }
};
