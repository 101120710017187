import { FormikErrors } from 'formik';
import React from 'react';
import { accountNumberPattern, onlyLettersPattern, routingNumberPattern } from '../../../../helpers';
import { FormEcheckValues } from './form-types';

export const validateEcheckForm = (values: FormEcheckValues) => {
    const { type, accountHolderName, routingNumber, checkingAccountNumber, confirmRoutingNumber } = values;
    const errors: FormikErrors<FormEcheckValues> = {};

    if (!type) {
        errors.type = 'Bank account type is required';
    }
    if (!accountHolderName) {
        errors.accountHolderName = 'Account holder name is required';
    } else if (!onlyLettersPattern.test(accountHolderName)) {
        errors.accountHolderName = 'Please enter a valid account holder name';
    }
    if (!checkingAccountNumber) {
        errors.checkingAccountNumber = 'Checking account number is required';
    } else if (!accountNumberPattern.test(checkingAccountNumber)) {
        errors.checkingAccountNumber = 'Please enter a valid checking account number';
    }
    if (!routingNumber) {
        errors.routingNumber = 'Routing number is required';
    } else if (!routingNumberPattern.test(routingNumber)) {
        errors.routingNumber = 'Please enter a valid routing number';
    }
    if (!confirmRoutingNumber) {
        errors.confirmRoutingNumber = 'Confirm routing number is required';
    } else if (routingNumber !== confirmRoutingNumber) {
        errors.confirmRoutingNumber = "Oops! The routing number doesn't match, review your information";
    }

    return errors;
};
