import { FormikErrors } from 'formik';
import { emailPattern } from '../../../helpers/regex';
import { FormSubscribeValues } from './types';

export const validateSubscribeForm = (values: FormSubscribeValues) => {
    const { number, email } = values;
    const errors: FormikErrors<FormSubscribeValues> = {};

    if (!number) {
        errors.number = 'This field is required';
    }

    if (!email) {
        errors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
        errors.email = 'Please enter a valid email address';
    }

    return errors;
};
