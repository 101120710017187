import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { TitleProps } from './title-types';

const Title = ({ children }: TitleProps) => (
    <Text size={TEXT_SIZES['MD']} weight={TEXT_WEIGHTS[400]}>
        {children}
    </Text>
);

export default Title;
