import {
    deleteEcheck,
    getEChecks,
    saveECheck,
} from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { eCheckType } from '../../types/sprintpay';

export const getEchecks = () => getEChecks();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteECheck = (paymentToken: any) => deleteEcheck(paymentToken);

export const saveEcheck = (echeck: eCheckType) => saveECheck(echeck);
