import { useEffect, useState } from 'react';
import { selectCartReducer } from '../selectors';
import { useAppSelector } from '../store';
import { DHLPaymentRequestType } from '../types/sprintpay';

interface UseValidateCustomerNumber {
    isInvalidCustomer: null | boolean;
    isValidating: boolean;
    setCustomerNumber: (customerNum: string) => void;
}

export const useValidateCustomerNumber = (customerNum: string | null): UseValidateCustomerNumber => {
    const cart = useAppSelector(selectCartReducer);
    const [customerNumber, setCustomerNumber] = useState(customerNum);
    const [isInvalidCustomer, setisInvalidCustomer] = useState<null | boolean>(null);
    const [isValidating, setisValidating] = useState(true);
    const [initialSearch, setinitialSearch] = useState(true);

    useEffect(() => {
        setisValidating(true);
        if (initialSearch) {
            setinitialSearch(false);
        } else {
            if (!!cart.dhlPaymentRequests.length) {
                const customerNumberLength = customerNumber?.length;

                const isInvalid = cart.dhlPaymentRequests.find((payment: DHLPaymentRequestType) => {
                    const invoiceCustomerNumber = payment.invoice_number.substring(0, customerNumberLength);

                    return invoiceCustomerNumber?.toUpperCase() !== customerNumber?.toUpperCase();
                });

                setisInvalidCustomer(!!isInvalid);
                setisValidating(false);
            } else {
                setisInvalidCustomer(null);
                setisValidating(false);
            }
        }
    }, [cart.dhlPaymentRequests]);

    return { isInvalidCustomer, setCustomerNumber, isValidating };
};
