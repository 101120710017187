import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../types';

const Header = () => (
    <Text size={TEXT_SIZES['3XL']} weight={TEXT_WEIGHTS[700]} className=''>
        Select how you would like to search
    </Text>
);

export default Header;
