import { RootState } from '../store';

export const selectAuthToken = ({ authReducer: { token } }: RootState) => token;

export const selectAuthTokens = ({ authReducer: { tokens } }: RootState) => tokens;

export const selectAuthConfig = ({ authReducer }: RootState) => authReducer;

export const selectEmailValidation = ({
    authReducer: { isValidEmail, validatingEmail, mfaUuId, customerNumberApproved },
}: RootState) => ({
    isValidEmail,
    validatingEmail,
    customerNumberApproved,
    mfaUuId,
});

export const selectPasscodeValidation = ({ authReducer: { isValidPasscode, validatingPasscode } }: RootState) => ({
    isValidPasscode,
    validatingPasscode,
});

export const selectAuthErrors = ({ authReducer }: RootState) => authReducer.errors;
