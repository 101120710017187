import { BraintreeError } from 'braintree-web';

export const braintreeErrorHandler = (error: BraintreeError): string => {
    switch (error.code) {
        // client errors
        case 'CLIENT_OPTION_REQUIRED': // An option required in the request method was not provided. Usually `options.method` or `options.endpoint`.
        case 'CLIENT_OPTION_INVALID': // The request option provided is invalid.
        case 'CLIENT_INVALID_AUTHORIZATION': // Occurs when client token cannot be parsed.
        case 'CLIENT_GATEWAY_NETWORK': // The Braintree gateway could not be contacted.
        case 'CLIENT_REQUEST_TIMEOUT': // The request took too long to complete and timed out.
        case 'CLIENT_REQUEST_ERROR': // The response from a request had status 400 or greater.
        case 'CLIENT_GRAPHQL_REQUEST_ERROR': // The response from a request to GraphQL contained an error.
        case 'CLIENT_RATE_LIMITED': // The response from a request had a status of 429, indicating rate limiting.
        case 'CLIENT_AUTHORIZATION_INSUFFICIENT': // The user associated with the client token or tokenization key does not have permissions to make the request.
        case 'CLIENT_AUTHORIZATION_INVALID': // The provided authorization could not be found. Either the client token has expired and a new client token must be generated or the tokenization key used is set to be inactive or has been deleted.
            return 'Oops! Braintree connection error ocurred. Please try again later.';

        // hosted fields errors
        case 'INSTANTIATION_OPTION_REQUIRED': // Occurs when no fields provided in configuration object.
        case 'HOSTED_FIELDS_TIMEOUT': // Occurs when Hosted Fields does not finish setting up within 60 seconds.
        case 'HOSTED_FIELDS_INVALID_FIELD_KEY': // Occurs when Hosted Fields is instantiated with an invalid Field option.
        case 'HOSTED_FIELDS_INVALID_FIELD_SELECTOR': // Occurs when Hosted Fields given a field selector that is not valid.
        case 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME': // Occurs when Hosted Fields given a field selector that already contains an iframe.
        case 'HOSTED_FIELDS_FIELD_PROPERTY_INVALID': // Occurs when a field configuration option is not valid.
        case 'HOSTED_FIELDS_FIELD_INVALID': // Occurs when attempting to modify a field that is not a valid Hosted Fields option.
        case 'HOSTED_FIELDS_FIELD_NOT_PRESENT': // Occurs when attempting to modify a field that is not configured with Hosted Fields.
            return 'Oops! Initializing form error ocurred. Please try again later.';

        // tokenization errors
        case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR': // Occurs when the Braintree gateway cannot be contacted.
        case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE': // Occurs when attempting to vault a card, but the client token being used is configured to fail if the card already exists in the vault.
        case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED': // Occurs when cvv verification is turned on in the Braintree control panel.
        case 'HOSTED_FIELDS_FAILED_TOKENIZATION': // Occurs when the credit card details were sent to Braintree, but failed to tokenize.
        case 'HOSTED_FIELDS_FIELDS_EMPTY': // Occurs when all the Hosted Fields inputs are empty.
        case 'HOSTED_FIELDS_FIELDS_INVALID': // Occurs when one ore more fields are invalid.
            return 'Oops! You provided wrong credit card data. Please try again.';

        default:
            return 'Oops! An unknown error ocurred. Please try again.';
    }
};
