import { RadioField } from '@dhl-official/react-ui-library';
import React from 'react';
import { INPUTFIELD_VALIDATION_TYPES } from '../../../types';
import { RadioProps } from './radio-types';

export const RadioDHL = (props: RadioProps) => {
    const { field, form, children, className = '' } = props;

    const { touched, errors } = form;
    const validations =
        (touched[field.name] &&
            errors[field.name] && { message: errors[field.name], type: INPUTFIELD_VALIDATION_TYPES.INVALID }) ||
        undefined;

    return (
        <RadioField {...field} {...props} validation={validations} className={`input__dhl ${className}`}>
            {children}
        </RadioField>
    );
};
