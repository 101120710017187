import { FormikErrors } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { emailPattern, getCountryRegex, onlyLettersPattern } from '../../../../helpers';
import { FormCreditCardGuest } from './form-types';

export const validateCartGuest = (values: FormCreditCardGuest, getFormStatus?: (isValid: boolean) => void) => {
    const { firstName, lastName, phoneNumber, email, street, country, state, city, zipCode } = values;
    const errors: FormikErrors<FormCreditCardGuest> = {};
    const countryRegex = getCountryRegex(country);

    if (!firstName) {
        errors.firstName = 'First name is required';
    } else if (!onlyLettersPattern.test(firstName)) {
        errors.firstName = 'Please enter a valid name';
    }
    if (!lastName) {
        errors.lastName = 'Last name is required';
    } else if (!onlyLettersPattern.test(lastName)) {
        errors.lastName = 'Please enter a valid last name';
    }

    if (!phoneNumber) {
        errors.phoneNumber = 'Phone number is required';
    } else if (!isValidPhoneNumber(phoneNumber)) {
        errors.phoneNumber = 'Please enter a valid phone';
    }

    if (!email) {
        errors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
        errors.email = 'Please enter a valid email address';
    }

    if (!street) {
        errors.street = 'Street is required';
    }
    if (!country) {
        errors.country = 'Country is required';
    }
    if (!state) {
        errors.state = 'State is required';
    }
    if (!city) {
        errors.city = 'City is required';
    }
    if (!zipCode) {
        errors.zipCode = 'Zip code is required';
    } else if (!countryRegex.test(zipCode)) {
        errors.zipCode = 'Please enter a zip code valid';
    }

    getFormStatus && getFormStatus(Object.keys(errors).length === 0);

    return errors;
};
