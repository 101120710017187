import { BraintreeError, HostedFieldFieldOptions, HostedFields, client, hostedFields } from 'braintree-web';
import { HostedFieldsEvent, HostedFieldsHostedFieldsFieldData } from 'braintree-web/hosted-fields';
import { createContext, useState } from 'react';
import { BraintreeContextTypes, BraintreeFieldsConditions, BraintreeProviderProps } from './braintree-context-types';
import { braintreeErrorHandler } from './braintree-error-handler';

export const BraintreeContext = createContext<BraintreeContextTypes>({
    initializeHostedFields: null,
    isLoadingBraintree: false,
    getPaymentNonce: null,
    isBraintreeFormValid: false,
    teardown: null,
    fieldsConditions: {},
    checkBraintreeFormValidity: null,
    braintreeError: null,
    acknowledgeBraintreeError: null,
});

export const BraintreeProvider = ({ children }: BraintreeProviderProps) => {
    const [braintreeInstance, setBraintreeInstance] = useState<HostedFields | null>(null);
    const [isLoadingBraintree, setIsLoadingBraintree] = useState<boolean>(false);
    const [isBraintreeFormValid, setIsBraintreeFormValid] = useState<boolean>(false);
    const [fieldsConditions, setFieldsConditions] = useState<BraintreeFieldsConditions>({});
    const [braintreeError, setBraintreeError] = useState<string | null>(null);

    const handleError = (err: BraintreeError) => {
        const error = braintreeErrorHandler(err);
        setBraintreeError(error);
        setIsLoadingBraintree(false);
    };

    const initializeHostedFields = (clientToken: string, fields: HostedFieldFieldOptions, styles: any) => {
        const config = {
            authorization: clientToken,
        };

        const initializeBraintree = () => {
            setIsLoadingBraintree(true);
            client
                .create(config)
                .then((clientInstance) => {
                    const errorsObj = Object.keys(fields).reduce((acc, fieldKey) => {
                        acc[fieldKey] = {};
                        return acc;
                    }, {});
                    setFieldsConditions(() => ({
                        ...errorsObj,
                    }));

                    const options = {
                        client: clientInstance,
                        fields,
                        styles,
                    };
                    hostedFields
                        .create(options)
                        .then((fieldsInstance: HostedFields) => {
                            setBraintreeInstance(fieldsInstance);

                            fieldsInstance.on('validityChange', (event: HostedFieldsEvent) => {
                                const isValid = Object.values(event.fields).every(
                                    (field: HostedFieldsHostedFieldsFieldData) => field.isValid
                                );
                                setIsBraintreeFormValid(isValid);
                            });

                            fieldsInstance.on('focus', (event: HostedFieldsEvent) => {
                                setFieldsConditions((fieldsErrors) => ({
                                    ...fieldsErrors,
                                    [event.emittedBy]: { isFocused: true },
                                }));
                            });

                            fieldsInstance.on('blur', (event: HostedFieldsEvent) => {
                                const field = event.fields[event.emittedBy];
                                updateFieldsConditions([event.emittedBy, field]);
                            });

                            setIsLoadingBraintree(false);
                        })
                        .catch(handleError);
                })
                .catch(handleError);
        };

        initializeBraintree();
    };

    const getPaymentNonce = (options = {}) => {
        return braintreeInstance
            .tokenize(options)
            .then((response) => {
                return response.nonce;
            })
            .catch(handleError);
    };

    const teardown = () => {
        if (braintreeInstance) {
            braintreeInstance.teardown();
            setBraintreeInstance(null);
        }
        setFieldsConditions(() => ({}));
        setBraintreeError(null);
    };

    const checkBraintreeFormValidity = () => {
        if (braintreeInstance) {
            const fields = braintreeInstance.getState().fields;
            Object.entries(fields).forEach(updateFieldsConditions);
        }
    };

    const updateFieldsConditions = ([key, field]: [string, HostedFieldsHostedFieldsFieldData]) => {
        if (field.isEmpty && !field.isValid) {
            setFieldsConditions((fieldsErrors) => ({
                ...fieldsErrors,
                [key]: {
                    isRequired: true,
                    isWrongValue: false,
                    isFocused: false,
                },
            }));
        } else if (!field.isValid) {
            setFieldsConditions((fieldsErrors) => ({
                ...fieldsErrors,
                [key]: {
                    isRequired: false,
                    isWrongValue: true,
                    isFocused: false,
                },
            }));
        } else if (field.isValid) {
            setFieldsConditions((fieldsErrors) => ({
                ...fieldsErrors,
                [key]: {},
            }));
        }
    };

    const acknowledgeBraintreeError = () => {
        setBraintreeError(null);
    };

    return (
        <BraintreeContext.Provider
            value={{
                initializeHostedFields,
                isLoadingBraintree,
                getPaymentNonce,
                isBraintreeFormValid,
                teardown,
                fieldsConditions,
                checkBraintreeFormValidity,
                braintreeError,
                acknowledgeBraintreeError,
            }}
        >
            {children}
        </BraintreeContext.Provider>
    );
};
