import { useState } from 'react';
import * as Requests from '../../api/request/cart-request';
import { ButtonDHL, ErrorApp } from '../../components';
import { selectCartReducer } from '../../selectors';
import { useAppSelector } from '../../store';
import { BUTTON_SIZES, BUTTON_TYPES, BUTTON_VARIANTS } from '../../types';
import { CompletedPaymentType } from '../../types/sprintpay';

const PaymentPdf = () => {
    const cart = useAppSelector(selectCartReducer);
    const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
    const [downloadingPDFError, setDownloadingPDFError] = useState(null);

    const onDownloadPDF = async (): Promise<void> => {
        if (cart.paymentSuccessData) {
            let link = null;
            try {
                setIsDownloadingPDF(true);
                setDownloadingPDFError(null);
                const invoices = cart.paymentSuccessData?.completedPayments.map((elem: CompletedPaymentType) => {
                    return elem.invoice.id;
                });
                const response = await Requests.downloadReceiptByInvoices(
                    invoices,
                    cart.paymentSuccessData.transactionId
                );
                const url = window.URL.createObjectURL(new Blob([response]));
                link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'receipt.pdf');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                setDownloadingPDFError('Oops! Failed to find PDF document.');
            } finally {
                setIsDownloadingPDF(false);
                if (link) {
                    document.body.removeChild(link);
                }
            }
        }
    };

    return (
        <>
            <ButtonDHL
                size={BUTTON_SIZES.MD}
                type={BUTTON_TYPES.BUTTON}
                variant={BUTTON_VARIANTS.TEXT}
                onClick={onDownloadPDF}
                isBlock
                loading={isDownloadingPDF}
                isDisabled={isDownloadingPDF}
            >
                Download receipt
            </ButtonDHL>
            <div className='download-button download-button__error'>
                {downloadingPDFError && <ErrorApp>{downloadingPDFError}</ErrorApp>}
            </div>
        </>
    );
};

export default PaymentPdf;
