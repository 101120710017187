import { Headline } from '@dhl-official/react-ui-library';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { selectCartReducer } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { customerActions } from '../../store/actions';
import { authActions } from '../../store/auth/reducers';
import {
    HEADLINE_ALIGNMENTS,
    HEADLINE_COLORS,
    HEADLINE_FONT_STRETCHES,
    HEADLINE_LEVELS,
    HEADLINE_TAGS,
} from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = ({ prevLocationLocal }: { prevLocationLocal: boolean }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cart = useAppSelector(selectCartReducer);
    const [cartToPay, setcartToPay] = useState(false);

    const onClickGoBack = useCallback(() => {
        if (cartToPay) {
            navigate('/cart');
        } else {
            dispatch(customerActions.resetSearchNumber());
            dispatch(authActions.resetEmailValidation());

            navigate('/', { replace: true });
        }
    }, [dispatch, cartToPay, navigate]);

    useEffect(() => {
        if (cart.dhlPaymentRequests.length > 0) {
            setcartToPay(true);
        }
    }, [cart.dhlPaymentRequests]);

    return (
        <div onClick={onClickGoBack} className='search__back-button'>
            <Headline
                fontStretch={HEADLINE_FONT_STRETCHES.NORMAL}
                color={HEADLINE_COLORS.RED}
                designLevel={HEADLINE_LEVELS[6]}
                tag={HEADLINE_TAGS.H6}
                textAlign={HEADLINE_ALIGNMENTS.CENTER}
            >
                {!cartToPay ? 'Back to email validation' : 'Go to Cart'}
            </Headline>
        </div>
    );
};

export default Footer;
