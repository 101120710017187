import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { TitleFormSectionProps } from './types';

export const TitleFormSection = ({
    size = TEXT_SIZES['LG'],
    weight = TEXT_WEIGHTS[700],
    className,
    children,
    isParagraph = true,
    ...restProps
}: TitleFormSectionProps) => {
    return (
        <Text isParagraph={isParagraph} size={size} weight={weight} className={className} {...restProps}>
            {children}
        </Text>
    );
};
