import { ALIGNMENTS, BUTTONGROUP_ORIENTATION, BUTTON_SIZES, BUTTON_VARIANTS, TEXT_SIZES } from '../../../types';
import React from 'react';

export interface GlobalModalProps {
    loading?: boolean;
    isOpen: boolean;
    onCloseModal: () => void;
    contentAriaLabel?: string;
    title?: string;
    description?: string;
    descriptionSize?: TEXT_SIZES;
    notes?: string;
    onPressRightButton?: () => void;
    rightButtonText?: string;
    rightButtonVariant?: BUTTON_VARIANTS;
    rightButtonDisabled?: boolean;
    rightButtonLoading?: boolean;
    onPressLeftButton?: () => void;
    leftButtonText?: string;
    lefttButtonVariant?: BUTTON_VARIANTS;
    leftButtonDisabled?: boolean;
    leftButtonLoading?: boolean;
    buttonGroupSize?: BUTTON_SIZES;
    buttonGroupOrientation?: BUTTONGROUP_ORIENTATION;
    buttonGroupOrder?: ALIGNMENTS;
    iconType?: IconTypes;
    showIcon?: boolean;
    shouldShowCloseButton?: boolean;
    isStatic?: boolean;
    component?: React.ReactNode;
}

export interface IconModalProps {
    className?: string;
    iconType?: IconTypes;
}

export enum IconTypes {
    IconCancelCircle = 'IconCancelCircle',
    IconCheckmarkCircle = 'IconCheckmarkCircle',
    IconException = 'IconException',
}
