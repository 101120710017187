import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Requests from '../../api/request/cart-request';
import { PaymentRequestType, PaymentType } from '../../types/sprintpay';
import { handlerErrors, uiActions } from '../actions';
import { cartActions } from './reducers';

export const addPaymentRequest = createAsyncThunk<void, PaymentType>(
    'cart/addPaymentRequest',
    async (createPaymentRequest, { dispatch }) => {
        try {
            const response = await Requests.addPaymentRequest(createPaymentRequest);

            dispatch(uiActions.increaseTransaction());

            return response;
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const updateDHLPaymentRequest = createAsyncThunk<void, PaymentRequestType>(
    'cart/updateDHLPaymentRequest',
    async (updatePaymentRequest, { dispatch }) => {
        try {
            const response = await Requests.updateDHLPaymentRequest(updatePaymentRequest).then(() => {
                dispatch(uiActions.increaseTransaction());
            });

            return response;
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);
            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const deleteDHLOneAwbFromCartRequest = createAsyncThunk<void, string>(
    'cart/deleteDHLOneAwbFromCartRequest',
    async (mawb, { dispatch }) => {
        try {
            const response = await Requests.deleteDHLOneAwbFromCart(mawb).then(() => {
                dispatch(uiActions.increaseTransaction());
            });

            return response;
        } catch (error: unknown) {}
    }
);
