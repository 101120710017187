import { createAsyncThunk } from '@reduxjs/toolkit';
import { FilterPaymentsType } from '../../pages/shipmetDetailsEdit/table/table-types';
import { PaymentRequestType, PaymentType } from '../../types/sprintpay';
import { handlerErrors } from '../actions';
import { addPaymentRequest, deleteDHLOneAwbFromCartRequest, updateDHLPaymentRequest } from './actions-functions';
import { cartActions } from './reducers';

export const addPaymentsRequestEdit = createAsyncThunk<void, PaymentType[]>(
    'cart/addPaymentsRequestEdit',
    async (props, { dispatch }) => {
        try {
            dispatch(cartActions.addingPaymentRequest(true));

            const arrayPromises = props.map((element) => dispatch(addPaymentRequest(element)));

            await Promise.all(arrayPromises);

            dispatch(cartActions.addingPaymentRequest(false));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(cartActions.addingPaymentRequest(false));

            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const updateDHLPaymentsRequest = createAsyncThunk<void, PaymentRequestType[]>(
    'cart/updateDHLPaymentsRequest',
    async (props, { dispatch }) => {
        try {
            dispatch(cartActions.updatingPaymentRequest(true));

            const arrayPromises = props.map((element) => dispatch(updateDHLPaymentRequest(element)));

            await Promise.all(arrayPromises);

            dispatch(cartActions.updatingPaymentRequest(false));
        } catch (error: unknown) {
            const errors = handlerErrors(error, dispatch);

            dispatch(cartActions.updatingPaymentRequest(false));

            dispatch(cartActions.paymentError(errors));
        }
    }
);

export const deleteDHLMultipleAwbsFromCartRequest = createAsyncThunk<void, string[]>(
    'cart/deleteDHLMultipleAwbsFromCartRequest',
    async (props, { dispatch }) => {
        try {
            dispatch(cartActions.deletingDHLOneAwbFromCart(true));

            const arrayPromises = props.map((element) => dispatch(deleteDHLOneAwbFromCartRequest(element)));

            await Promise.all(arrayPromises);

            dispatch(cartActions.deleteDHLOneAwbFromCartSuccess());

            dispatch(cartActions.deletingDHLOneAwbFromCart(false));
        } catch (error: unknown) {
            dispatch(cartActions.deletingDHLOneAwbFromCart(false));
        }
    }
);

export const editCart = createAsyncThunk<void, FilterPaymentsType>('cart/editCart', async (props, { dispatch }) => {
    try {
        dispatch(cartActions.editingCart(true));
        const { paymentsToUpdate, paymentsToDelete, paymentsToAdd } = props;

        if (!!paymentsToUpdate.length) {
            await dispatch(updateDHLPaymentsRequest(paymentsToUpdate));
        }
        if (!!paymentsToDelete.length) {
            await dispatch(deleteDHLMultipleAwbsFromCartRequest(paymentsToDelete.map((item) => item.awb || '')));
        }
        if (!!paymentsToAdd.length) {
            await dispatch(addPaymentsRequestEdit(paymentsToAdd));
        }

        dispatch(cartActions.cartEdited(true));
        dispatch(cartActions.editingCart(false));
    } catch (error: unknown) {
        // const errors = await handlerErrors(error, dispatch);

        dispatch(cartActions.cartEdited(false));
    }
});
