import { Text } from '@dhl-official/react-ui-library';
import React from 'react';
import { TEXT_SIZES, TEXT_WEIGHTS } from '../../../types';
import { DescriptionProps } from './types';

export const Description = ({
    size = TEXT_SIZES['SM'],
    weight = TEXT_WEIGHTS[200],
    className,
    children,
    isParagraph = true,
    ...restProps
}: DescriptionProps) => {
    return (
        <Text
            isParagraph={isParagraph}
            size={size}
            weight={weight}
            className={`description__title ${className}`}
            {...restProps}
        >
            {children}
        </Text>
    );
};
