import { Center, Flex, Loader, Tab, Tabs } from '@dhl-official/react-ui-library';
import { useCallback, useEffect, useState } from 'react';
import { selectPaymentMethods } from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getPaymentMethods, paymentMethodsActions } from '../../../store/actions';
import { PaymentMethods } from '../../../store/paymentsMethods/types';
import { TAB_VARIANTS } from '../../../types';
import {
    CargoCreditType,
    CreditCardType,
    EcheckType,
    PaymentMethodsType,
    PaypalType,
    SignetCreditType,
} from '../../../types/sprintpay';
import { CargoCreditTab } from '../cargosprintAccount/bill-tab';
import { CreditCardTab } from '../creditCard/credit-card-tab';
import { EcheckTab } from '../echecks.tsx/echeck-tab';
import { PaypalTab } from '../paypal/paypal-tab';
import { SignetTab } from '../signet/signet-tab';
import { TabProps } from './tabs-types';

export const PaymentMethodsTabs = ({ getPaymentMethodSelected }: TabProps) => {
    const dispatch = useAppDispatch();
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState<
        PaymentMethods<CreditCardType[] | EcheckType[] | CargoCreditType | SignetCreditType | PaypalType>[]
    >([]);
    const [elementsMethods, setelementsMethods] = useState([]);
    const [tabsMethods, setTabsMethods] = useState<
        {
            tab: number;
            type: PaymentMethodsType;
        }[]
    >([]);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState<{
        paymentMethod: CreditCardType | EcheckType | CargoCreditType | SignetCreditType | null;
        type?: PaymentMethodsType | null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        id?: any;
    }>({
        paymentMethod: paymentMethods.paymentMethodSelected,
        type: paymentMethods.paymentMethodType,
        id: paymentMethods.paymentMethodId,
    });

    const _getPaymentMethodSelected = useCallback(
        (
            paymentMethod: CreditCardType | EcheckType | CargoCreditType | SignetCreditType | PaypalType,
            type: PaymentMethodsType
        ) => {
            const id =
                type === PaymentMethodsType.CREDIT_CARD || type === PaymentMethodsType.ECHECK
                    ? paymentMethod.paymentToken.token
                    : paymentMethod.paymentToken;

            dispatch(
                paymentMethodsActions.savePaymentMethodSelected({
                    paymentMethod,
                    paymentMethodType: type,
                    paymentMethodId: id,
                })
            );

            getPaymentMethodSelected && getPaymentMethodSelected(paymentMethod, type);
        },
        [dispatch, getPaymentMethodSelected]
    );

    useEffect(() => {
        setLoading(true);
        dispatch(getPaymentMethods(false));
    }, [dispatch]);

    useEffect(() => {
        setPaymentMethodSelected({
            paymentMethod: paymentMethods.paymentMethodSelected,
            type: paymentMethods.paymentMethodType,
            id: paymentMethods.paymentMethodId,
        });
    }, [paymentMethods.paymentMethodSelected, paymentMethods.paymentMethodType, paymentMethods.paymentMethodId]);

    useEffect(() => {
        const methodSelected = tabsMethods.find((elem) => elem.type === paymentMethods.paymentMethodType);

        if (methodSelected) {
            setTab(methodSelected.tab);
        } else {
            setTab(paymentMethods.tab);
        }
    }, [paymentMethodSelected]);

    useEffect(() => {
        // TODO: Change this useEffect, instead of use push push push change the array using inmutability
        const m = [];

        if (paymentMethods.creditCards) {
            m.push(paymentMethods.creditCards);
        }
        if (paymentMethods.cargoCredit) {
            m.push(paymentMethods.cargoCredit);
        }
        if (paymentMethods.signetCredit) {
            m.push(paymentMethods.signetCredit);
        }
        if (paymentMethods.echecks) {
            m.push(paymentMethods.echecks);
        }
        if (paymentMethods.paypal) {
            m.push(paymentMethods.paypal);
        }

        setMethods(m);
    }, [
        paymentMethods.cargoCredit,
        paymentMethods.creditCards,
        paymentMethods.echecks,
        paymentMethods.signetCredit,
        paymentMethods.paypal,
    ]);

    useEffect(() => {
        let index = -1;
        setTabsMethods([]);

        if (paymentMethods.creditCards && paymentMethods.creditCards.active) {
            index = index + 1;
            const c = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: c,
                    type: PaymentMethodsType.CREDIT_CARD,
                },
            ]);
        }
        if (paymentMethods.cargoCredit && paymentMethods.cargoCredit.active) {
            index = index + 1;
            const a = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: a,
                    type: PaymentMethodsType.CARGOSPRINT_CREDIT,
                },
            ]);
        }
        if (paymentMethods.signetCredit && paymentMethods.signetCredit.active) {
            index = index + 1;
            const b = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: b,
                    type: PaymentMethodsType.SIGNET,
                },
            ]);
        }
        if (paymentMethods.echecks && paymentMethods.echecks.active) {
            index = index + 1;
            const d = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: d,
                    type: PaymentMethodsType.ECHECK,
                },
            ]);
        }
        if (paymentMethods.paypal && paymentMethods.paypal.active) {
            index = index + 1;
            const p = index;

            setTabsMethods((prev) => [
                ...prev,
                {
                    tab: p,
                    type: PaymentMethodsType.PAYPAL,
                },
            ]);
        }
    }, [paymentMethods.paymentMethodId]);

    useEffect(() => {
        if (methods) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const elements: any = [];

            methods.forEach((element) => {
                if (element.active && element.type === PaymentMethodsType.CREDIT_CARD) {
                    elements.push(
                        <Tab label='Credit card' key={PaymentMethodsType.CREDIT_CARD}>
                            <CreditCardTab
                                loading={paymentMethods.loading}
                                creditCards={paymentMethods.creditCards}
                                setPaymentMethodSelected={_getPaymentMethodSelected}
                                paymentMethodSelected={paymentMethodSelected}
                            />
                        </Tab>
                    );
                } else if (element.active && element.type === PaymentMethodsType.CARGOSPRINT_CREDIT) {
                    elements.push(
                        <Tab label='Bill my SprintPay Account' key={PaymentMethodsType.CARGOSPRINT_CREDIT}>
                            <CargoCreditTab
                                loading={paymentMethods.loading}
                                cargoCredit={paymentMethods.cargoCredit}
                                setPaymentMethodSelected={_getPaymentMethodSelected}
                                paymentMethodSelected={paymentMethodSelected}
                            />
                        </Tab>
                    );
                } else if (element.active && element.type === PaymentMethodsType.ECHECK) {
                    elements.push(
                        <Tab label='E-check' key={PaymentMethodsType.ECHECK}>
                            <EcheckTab
                                loading={paymentMethods.loading}
                                echecks={paymentMethods.echecks}
                                setPaymentMethodSelected={_getPaymentMethodSelected}
                                paymentMethodSelected={paymentMethodSelected}
                            />
                        </Tab>
                    );
                } else if (element.active && element.type === PaymentMethodsType.SIGNET) {
                    elements.push(
                        <Tab label='Signet' key={PaymentMethodsType.SIGNET}>
                            <SignetTab
                                loading={paymentMethods.loading}
                                signetCredit={paymentMethods.signetCredit}
                                setPaymentMethodSelected={_getPaymentMethodSelected}
                                paymentMethodSelected={paymentMethodSelected}
                            />
                        </Tab>
                    );
                } else if (element.active && element.type === PaymentMethodsType.PAYPAL) {
                    elements.push(
                        <Tab label='Paypal' key={PaymentMethodsType.PAYPAL}>
                            <PaypalTab
                                loading={paymentMethods.loading}
                                paypalCredit={paymentMethods.paypal}
                                setPaymentMethodSelected={_getPaymentMethodSelected}
                                paymentMethodSelected={paymentMethodSelected}
                            />
                        </Tab>
                    );
                }
            });

            setLoading(false);
            setelementsMethods(elements);
        }
    }, [methods, paymentMethodSelected]);

    return (
        <>
            {!!elementsMethods.length && !loading ? (
                <Tabs variant={TAB_VARIANTS.UNDERLINE} initialActiveIndex={tab}>
                    {elementsMethods}
                </Tabs>
            ) : (
                <Flex>
                    <Center>
                        <Loader />
                    </Center>
                </Flex>
            )}
        </>
    );
};
