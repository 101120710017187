import { ALIGNMENTS, BUTTON_VARIANTS } from '../../types';
import React from 'react';

export interface RemoveModalProps {
    isOpen: boolean;
    initialValues: FormRemoveValues;
    loading?: boolean;
    onCloseModal: () => void;
    onPressLeftButton?: (values: FormRemoveValues) => void;
    lefttButtonVariant?: BUTTON_VARIANTS;
    leftButtonDisabled?: boolean;
    leftButtonLoading?: boolean;
    buttonGroupOrder?: ALIGNMENTS;
    iconType?: IconTypes;
    showIcon?: boolean;
    shouldShowCloseButton?: boolean;
    isStatic?: boolean;
    error?: string;
}

export type FormRemoveValues = {
    comments: string;
};

export interface IconModalProps {
    className?: string;
    iconType?: IconTypes;
}

export enum IconTypes {
    IconCancelCircle = 'IconCancelCircle',
    IconCheckmarkCircle = 'IconCheckmarkCircle',
    IconException = 'IconException',
}
