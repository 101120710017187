import { SPRINTPAY_PAYMENT_CONFIG } from '@cargos/sprintpay_frontend_core_api/lib/utils/init';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router';
import { getRemainingTokenTime, getSprintPayMethodsConfig } from '../../helpers';
import { KeysLocalStorageTypes, useInterval, useLocalStorage } from '../../hooks';
import { selectAuthConfig, selectCartReducer } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { globalAlertActions, refreshToken } from '../../store/actions';
import { SessionType } from '../../store/auth/types';

import { useLocation } from 'react-router-dom';
import { authActions } from '../../store/auth/reducers';
import { CustomersType } from '../../types/sprintpay';
import { AuthContextProps, AuthContextTypes, AuthStateTypes } from './auth-context-types';

const AuthContext = createContext<AuthContextTypes>({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    authState: null!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setAuthState: null!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    logout: null!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isAuthenticated: null!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getUserType: null!,
});

const { Provider } = AuthContext;

const AuthProvider = ({ children }: AuthContextProps) => {
    const dispatch = useAppDispatch();
    const authReducer = useAppSelector(selectAuthConfig);
    const cartReducer = useAppSelector(selectCartReducer);
    const [setTokenValue] = useLocalStorage(KeysLocalStorageTypes.TOKEN, '');
    const [setRefTokenValue] = useLocalStorage(KeysLocalStorageTypes.REF_TOKEN, '');
    const [setUserInfoValue] = useLocalStorage(KeysLocalStorageTypes.USER_INFO, {});
    const [setUserTypeValue] = useLocalStorage(KeysLocalStorageTypes.USER_TYPE, '');
    const [setExpiresAtValue] = useLocalStorage(KeysLocalStorageTypes.EXPIRES_AT, null);
    const [setServerTimeValue] = useLocalStorage(KeysLocalStorageTypes.SERVER_TIME, null);
    const [idle, setIdle] = useState(false);
    const [secretToken, setSecretToken] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const [authState, setAuthState] = useState<AuthStateTypes>({
        token: null,
        refToken: null,
        userInfo: null,
        userType: CustomersType.GUEST,
    });

    const handleOnIdle = useCallback(() => setIdle(true), []);

    const handleOnAction = useCallback(() => setIdle(false), []);

    useIdleTimer({
        timeout: 1000 * 60 * 5,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        crossTab: {
            emitOnAllTabs: true,
        },
    });

    /**
     * This function will refresh the token in case is 1 minute remained
     */
    useEffect(() => {
        if (
            !!authState.token &&
            idle &&
            authReducer.userType &&
            !cartReducer.addingPayment &&
            !cartReducer.paying &&
            !cartReducer.updatingPaymentRequest &&
            !cartReducer.deletingCart &&
            location.pathname !== '/'
        ) {
            dispatch(globalAlertActions.showGlobalModal());
        }
    }, [
        dispatch,
        authState.token,
        idle,
        authReducer.refreshToken,
        authReducer.userType,
        secretToken,
        cartReducer.addingPayment,
        cartReducer.paying,
        cartReducer.updatingPaymentRequest,
        cartReducer.deletingCart,
    ]);

    const setAuthInfo = useCallback(
        ({ token, refToken, userInfo, expiresAt, serverTime, userType }: AuthStateTypes) => {
            setTokenValue(token || '');
            setRefTokenValue(refToken || '');
            setUserInfoValue(userInfo || '');
            setUserTypeValue(userType || '');
            setExpiresAtValue(expiresAt || null);
            setServerTimeValue(serverTime || null);

            const config = getSprintPayMethodsConfig();

            SPRINTPAY_PAYMENT_CONFIG({
                token: config.token,
                environment: config.environment,
            });

            setAuthState({
                token,
                refToken,
                userInfo,
                userType,
            });
        },
        [setTokenValue, setRefTokenValue, setUserInfoValue, setExpiresAtValue, setServerTimeValue, setUserTypeValue]
    );

    useEffect(() => {
        if (authReducer.logged && authReducer.token) {
            setAuthInfo({
                token: authReducer.token,
                refToken: authReducer.refreshToken || '',
                userInfo: authReducer.userInfo || null,
                userType: authReducer.userType || '',
                expiresAt: authReducer.expiresAt,
                serverTime: authReducer.currentT,
            });
        }
    }, [
        setAuthInfo,
        authReducer.logged,
        authReducer.token,
        authReducer.refreshToken,
        authReducer.userInfo,
        authReducer.expiresAt,
        authReducer.currentT,
        authReducer.userType,
    ]);

    /**
     * This function will refresh the token in case is 1 minute remained
     */
    useInterval(
        () => {
            const remainingTime = getRemainingTokenTime();
            const refToken = sessionStorage.getItem(KeysLocalStorageTypes.REF_TOKEN);

            if (remainingTime <= 1 && refToken && !idle) {
                dispatch(refreshToken(refToken));
            }
        },
        1000 * 60 * 1,
        true
    );

    const logout = useCallback(() => {
        window.sessionStorage.clear();

        setAuthState({
            token: null,
            refToken: null,
            userInfo: null,
            userType: CustomersType.GUEST,
        });

        dispatch(authActions.sessionLogout());
    }, [dispatch]);

    /**
     * This function will force logout when a requets fails with 401
     */
    useEffect(() => {
        const forceLogoutAndRedirect = async () => {
            if (authReducer.forceLogout) {
                dispatch(authActions.forceLogout(false));

                logout();

                navigate('./');
            }
        };

        forceLogoutAndRedirect();
    }, [dispatch, authReducer.forceLogout, navigate, logout]);

    useEffect(() => {
        const token = sessionStorage.getItem(KeysLocalStorageTypes.TOKEN);
        const refToken = sessionStorage.getItem(KeysLocalStorageTypes.REF_TOKEN);
        const userInfo = sessionStorage.getItem(KeysLocalStorageTypes.USER_INFO);
        const userType = sessionStorage.getItem(KeysLocalStorageTypes.USER_TYPE);
        const expiresAt = sessionStorage.getItem(KeysLocalStorageTypes.EXPIRES_AT);
        const serverTime = sessionStorage.getItem(KeysLocalStorageTypes.SERVER_TIME);

        if (token) {
            const session: SessionType = {
                authTokens: {
                    authorizationToken: JSON.parse(token || ''),
                    refreshToken: JSON.parse(refToken || ''),
                },
                expiresAt: JSON.parse(expiresAt || ''),
                userInfo: JSON.parse(userInfo || ''),
                userType: JSON.parse(userType || ''),
                currentT: JSON.parse(serverTime || ''),
            };

            dispatch(authActions.sessionSuccess(session));
        }
    }, [dispatch]);

    const isAuthenticated = () => {
        if (!authState.token) {
            const token = sessionStorage.getItem(KeysLocalStorageTypes.TOKEN);

            if (!token) {
                return false;
            }
        }

        return true;
    };

    const getUserType = () => authState.userType;

    return (
        <Provider
            value={{
                authState,
                setAuthState: (authState: AuthStateTypes) => setAuthInfo(authState),
                logout,
                isAuthenticated,
                getUserType,
            }}
        >
            {children}
        </Provider>
    );
};

export { AuthContext, AuthProvider };
