export interface UserProfile {
    id: number;
    firstName: string;
    internalName: string;
    email: string;
    phone: string;
    street: string;
    city: string;
    country: string;
    state: string;
    zipCode: string;
    isGuest: boolean;
    externalAccountNumber: string;
    isPaymentsNotInvoicedEnabled: boolean;
    accessLevel: number;
    rate: number;
    creditLimit: number;
    invoiceCheckLimit?: number;
    availableCredit: number;
    customerProfile: CustomerProfile;
    source: number;
    balanceDue: number;
    notificationEmail?: string;
    name: string;
    address: string;
    acceptTerms?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dateTermsAcceptance?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    effectiveDate?: any;
    expeditors?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expeditorsGLCodes?: any[];
    lastName?: string;
    companyName?: string;
    signetEnabled?: boolean;
    cargoSprintCreditEnabled?: boolean;
    welcomeMessageEnabled?: boolean;
    isMegafileEnabled?: boolean;
    isUserManagementEnabled?: boolean;
    sendFacilityReceipt?: boolean;
    userType?: string;
    schenker?: boolean;
    maxAmountAllowed: number;
}

export enum CustomersType {
    SALESFORCE_AUTHENTICATED = 'SALESFORCE_AUTHENTICATED',
    GUEST = 'GUEST',
}

export interface CustomerProfile {
    name: string;
    components: Component[];
    enforceUniquePaymentMethod: boolean;
    paymentMethodForNewPayments: string;
    customerProfileRestriction: CustomerProfileRestriction;
}

export interface Component {
    id: number;
    name: string;
    parent?: ComponentParent;
}

export interface ComponentParent {
    id: number;
    name: string;
    parent?: ParentParent;
}

export interface ParentParent {
    id: number;
    name: string;
}

export interface CustomerProfileRestriction {
    id: number;
    name: string;
    description: string;
    creditCardLimit: number;
}
