import { GridContainer } from '@dhl-official/react-ui-library';
import { PaymentMethodsType, PaypalType } from '../../../types/sprintpay';
import { Description } from '../description';
import { PaypalTabProps } from './paypal-tab-types';
import { Paypal } from './paypalWrapper';

export const PaypalTab = ({ setPaymentMethodSelected, paypalCredit, paymentMethodSelected }: PaypalTabProps) => {
    const onSelectPaypal = (paypal?: PaypalType) => setPaymentMethodSelected(paypal, PaymentMethodsType.PAYPAL);

    return (
        <GridContainer
            columns={1}
            isFullWidth
            className='d-flex flex-column payment-methods__tab paypal-tab__container'
        >
            {paypalCredit?.active ? (
                <Paypal
                    paypal={paypalCredit?.value}
                    onSelectPaypal={onSelectPaypal}
                    paymentMethodSelected={paymentMethodSelected}
                />
            ) : (
                <Description>No Paypal available for this account yet</Description>
            )}
        </GridContainer>
    );
};
