import { PaymentMethodProperties } from '@cargos/sprintpay-models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Requests from '../../api/request/payment-methods-request';
import {
    CargoCreditType,
    CreditCardType,
    EcheckType,
    PaymentMethodsType,
    PaypalType,
    SignetCreditType,
} from '../../types/sprintpay';
import { creditCardActions, echecksActions, paymentMethodsActions } from '../actions';
import { PaymentMethods } from './types';

export const getPaymentMethods = createAsyncThunk<void, boolean>(
    'paymentMethods/getPaymentMethods',
    async (silenceSearch = false, { dispatch }) => {
        try {
            !silenceSearch && dispatch(paymentMethodsActions.getloading(true));

            const response = await Requests.getPaymentMethods();
            const paymentMethods = splitPaymentMethods(response);

            for (const paymentMethod in paymentMethods) {
                if (paymentMethods[paymentMethod].active) {
                    const { func, ...method } = paymentMethods[paymentMethod];
                    if (func) {
                        dispatch(func(method));
                    }
                }
            }

            !silenceSearch && dispatch(paymentMethodsActions.getloading(false));
        } catch (error: unknown) {
            dispatch(paymentMethodsActions.getloading(false));
        }
    }
);

export const creditCardsHandler = createAsyncThunk<void, PaymentMethods<CreditCardType[]>>(
    'paymentMethods/creditCardsHandler',
    async (creditCards, { dispatch }) => {
        try {
            dispatch(paymentMethodsActions.setCreditCards(creditCards));

            dispatch(creditCardActions.getCardsSuccess(creditCards));
        } catch (error) {
            // error
        }
    }
);

export const echecksHandler = createAsyncThunk<void, PaymentMethods<EcheckType[]>>(
    'paymentMethods/echecksHandler',
    async (echecks, { dispatch }) => {
        try {
            dispatch(paymentMethodsActions.setEchecks(echecks));
            dispatch(echecksActions.getEchecksSuccess(echecks));
        } catch (error) {
            // error
        }
    }
);

export const getCargoCreditBalance = createAsyncThunk<void, void>(
    'paymentMethods/getCargoCreditBalance',
    async (props, { dispatch }) => {
        try {
            const response = await Requests.getCargoCreditBalance();

            dispatch(paymentMethodsActions.updateCargoCredit(response));
        } catch (error: unknown) {}
    }
);

const splitPaymentMethods = (
    paymentMethods: PaymentMethodProperties[]
): Record<
    PaymentMethodsType,
    PaymentMethods<CreditCardType[] | EcheckType[] | CargoCreditType | SignetCreditType | PaypalType>
> => {
    const result = paymentMethods.reduce(
        (obj, item) => {
            if (item.name === 'credit-card') {
                obj[PaymentMethodsType.CREDIT_CARD] = {
                    ...obj[PaymentMethodsType.CREDIT_CARD],
                    active: true,
                    value: item.items || [],
                    func: creditCardsHandler,
                };
            } else if (item.name === 'echeck') {
                obj[PaymentMethodsType.ECHECK] = {
                    ...obj[PaymentMethodsType.ECHECK],
                    active: true,
                    value: item.items || [],
                    func: echecksHandler,
                };
            } else if (item.name === 'cargo-credit') {
                obj[PaymentMethodsType.CARGOSPRINT_CREDIT] = {
                    ...obj[PaymentMethodsType.CARGOSPRINT_CREDIT],
                    active: true,
                    value:
                        {
                            avaliableCredit: 0,
                            ...item.balance,
                            paymentToken: item.paymentToken,
                        } || null,
                    func: paymentMethodsActions.setCargoCredit,
                };
            } else if (item.name === 'signet') {
                obj[PaymentMethodsType.SIGNET] = {
                    ...obj[PaymentMethodsType.SIGNET],
                    active: true,
                    value:
                        {
                            avaliableCredit: 0,
                            ...item.balance,
                            paymentToken: item.paymentToken,
                        } || null,
                    func: paymentMethodsActions.setSignetCredit,
                };
            } else if (item.name === 'paypal') {
                obj[PaymentMethodsType.PAYPAL] = {
                    ...obj[PaymentMethodsType.PAYPAL],
                    active: true,
                    value:
                        {
                            paymentToken: item.paymentToken,
                        } || null,
                    func: paymentMethodsActions.setPaypal,
                };
            }

            return obj;
        },
        {
            [PaymentMethodsType.CREDIT_CARD]: {
                type: PaymentMethodsType.CREDIT_CARD,
                active: false,
                value: [],
                func: null,
                order: 1,
            },
            [PaymentMethodsType.ECHECK]: {
                type: PaymentMethodsType.ECHECK,
                active: false,
                value: [],
                func: null,
                order: 4,
            },
            [PaymentMethodsType.CARGOSPRINT_CREDIT]: {
                type: PaymentMethodsType.CARGOSPRINT_CREDIT,
                active: false,
                value: null,
                func: null,
                order: 2,
            },
            [PaymentMethodsType.SIGNET]: {
                type: PaymentMethodsType.SIGNET,
                active: false,
                value: null,
                func: null,
                order: 3,
            },
            [PaymentMethodsType.PAYPAL]: {
                type: PaymentMethodsType.PAYPAL,
                active: false,
                value: null,
                func: null,
                order: 5,
            },
        }
    );

    return result;
};
