import { useEffect, useState } from 'react';
import { selectCartReducer } from '../selectors';
import { useAppSelector } from '../store';
import { DHLPaymentRequestType, PaymentRequestType } from '../types/sprintpay';

export interface UseCartPreTotalAmountType {
    cartPreTotalAmount: number;
}

export const useCartPreTotalAmount = (): UseCartPreTotalAmountType => {
    const [cartPreTotalAmount, setPreTotal] = useState(0);
    const cart = useAppSelector(selectCartReducer);

    useEffect(() => {
        let totalInvoices: PaymentRequestType[] = [];

        cart.dhlPaymentRequests.forEach((list: DHLPaymentRequestType) => {
            totalInvoices = totalInvoices.concat(list.fee_content);
        });

        const sumTotalInvoices = totalInvoices.reduce((sum: number, awb: PaymentRequestType) => {
            if (awb.added && !awb.is_fully_paid) {
                return sum + Number(awb.amount);
            }

            return sum;
        }, 0);

        setPreTotal(sumTotalInvoices);
    }, [cart.dhlPaymentRequests]);

    return { cartPreTotalAmount };
};
